export const endpoints = {
    login: 'Authentication',
    quotations: 'quotations',
    users: {
        data: 'users',
        suspend: 'users/suspend',
        deactive: 'users/deactivate',
        activate: 'users/activate',
    },
    roles: 'roles',
    branches: 'sites',
    containerTypes: 'container_types',
    currencies: 'currencies',
    transportMode: 'transport_modes',
    shippingRates: 'shipping_rates',
    serviceRates: 'service_rates',
    units: 'units',
    quantityUnits: 'quantity_units',
    unlocodes: 'unlocodes',
    chargeCodes: 'charge_codes',
    countries: 'countries',
    states: 'states',
    cities: 'cities',
    bookings: 'bookings',
    organisations: 'organisations',
    contacts: 'contacts',
    job_categories: 'job_categories',
    bookingStatuses: 'bookings/statuses',
    organisationCategories: 'organisation_categories',
    organisationTypes: 'organisation_types',
    contactTypes: 'contact_types',
    shipments: 'shipments',
    paymentTypes: 'payment_types',
    houseBillStatus: 'house_bill_statuses',
    consoleType: 'console_types',
    consolidation: 'consolidations',
    consoleCategory: 'console_categories',
    shipmentStatus: 'shipments/statuses',
    packTypes: 'pack_types',
    hsCode: 'hs_codes',
    serviceRate: 'service_rates',
    order: 'orders',
    item: 'items',
    productCategories: 'product_categories',
    deliveryModes: 'delivery_modes',
    noteCategories: 'note_categories',
    documentType: 'document_types',
    orderStatus: 'order_statuses',
}

const SettingsButton = ({
    icon,
    label,
    onClick,
    subLabel,
}: {
    icon: string
    label: string
    subLabel: string
    onClick: () => void
}) => {
    return (
        <div
            className="rounded themes-bg-white themes-bg-hover-gray-v1 flex gap-4 w-full border p-4 cursor-pointer"
            onClick={() => {
                onClick()
            }}
        >
            <div className="text-size-XL items-center">
                <i className={icon}></i>
            </div>
            <div className="flex flex-col gap-[0.2rem]">
                <p className="text-size-M">{label.toUpperCase()}</p>
                <p className="text-size-S">{subLabel}</p>
            </div>
        </div>
    )
}
export default SettingsButton

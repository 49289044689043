import * as Yup from 'yup'
import { requiredMessage } from './validation-message.static'

// Define the interface
export interface IFHSCode {
    code: string
    name: string
    hs_code: string
}

// Validation schema
export const useHSCodeValidation = () => {
    const schema = Yup.object<IFHSCode>().shape({
        name: Yup.string().required(requiredMessage('Name')),
        hs_code: Yup.string().required(requiredMessage('HS Code')),
    })

    // Initial values
    const initialValue: IFHSCode = {
        code: '',
        name: '',
        hs_code: '',
    }

    return {
        initialValue,
        schema,
    }
}

import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { formatDate } from '@services/common.service'
import { IShipment } from 'repository/interface/shipment.interface'

export const shipmentStatusHeader: ITabItem[] = [
    {
        label: 'Open Shipments',
        key: 'status',
        value: 'OPEN',
        className: 'brand',
        totalData: 0,
    },
    {
        label: 'Confirmed',
        key: 'status',
        value: 'CONFIRMED',
        className: 'gray',
        totalData: 0,
    },
    {
        label: 'Delivered',
        key: 'status',
        value: 'DELIVERED',
        className: 'green',
        totalData: 0,
    },
    {
        label: 'Closed',
        key: 'status',
        value: 'CLOSED',
        className: 'gray',
        totalData: 0,
    },
    {
        label: 'Canceled',
        key: 'status',
        value: 'CANCELED',
        className: 'red',
        totalData: 0,
    },
]

export const consolidationShipmentStatusHeader: ITabItem[] = [
    {
        label: 'All Consolidation',
        key: 'status',
        value: 'CONSOLIDATION',
        className: 'brand',
        totalData: 0,
    },
    {
        label: 'Individual',
        key: 'status',
        value: 'INDIVIDUAL',
        className: 'gray',
        totalData: 0,
    },
]

export const seaShipmentSummaryHeader: ITableColumn<IShipment>[] = [
    {
        label: 'Mode',
        accessor: 'transport_mode',
        customBuild: (data) => {
            if (data === 'SEA') {
                return (
                    <div className="mr-2">
                        <i className="ri-ship-2-fill mr-2"></i> SEA
                    </div>
                )
            }
            return <>{data}</>
        },
    },
    {
        label: 'Shipment ID',
        accessor: 'shipment_id',
    },
    {
        label: 'Consignee',
        accessor: 'consignee.name',
    },
    {
        label: 'Destination',
        accessor: 'destination_location.name',
    },
    {
        label: 'Carrier',
        accessor: 'carrier.name',
        customBuild: (data, row) => {
            console.log(row)
            return data ?? '-'
        },
    },
    {
        label: 'ETD',
        accessor: 'etd',
        customBuild: (data) => {
            return formatDate(data)
        },
    },
    {
        label: 'ETA',
        accessor: 'eta',
        customBuild: (data) => {
            return formatDate(data)
        },
    },
    {
        label: 'Warehouse',
        accessor: 'warehouse',
        customBuild: (data) => {
            return data ?? '-'
        },
    },
    {
        label: 'Container Type',
        accessor: 'container_type',
        customBuild: (data) => {
            return data ?? '-'
        },
    },
    {
        label: 'Consolidation No',
        accessor: 'consolidation.console_id',
        customBuild: (data) => {
            return data ?? '-'
        },
    },
    {
        label: 'Created By',
        accessor: 'created_by.name',
    },
    {
        label: 'Last Updated',
        accessor: 'updated',
        customBuild: (data) => {
            return formatDate(data)
        },
    },
]

export const airShipmentSummaryHeader: ITableColumn<IShipment>[] = [
    {
        label: 'Mode',
        accessor: 'transport_mode',
        customBuild: (data) => {
            if (data === 'AIR') {
                return (
                    <div className="mr-2">
                        <i className="ri-plane-fill mr-2"></i> AIR
                    </div>
                )
            }
            return <></>
        },
    },
    {
        label: 'Shipment ID',
        accessor: 'shipment_id',
    },
    {
        label: 'Consignee',
        accessor: 'consignee.name',
    },
    {
        label: 'Destination',
        accessor: 'destination_location.name',
    },
    {
        label: 'EMKL',
        accessor: 'emkl',
        customBuild: (data) => {
            return data ?? '-'
        },
    },
    {
        label: 'ETD',
        accessor: 'etd',
        customBuild: (data) => {
            return formatDate(data)
        },
    },
    {
        label: 'Warehouse',
        accessor: 'warehouse',
        customBuild: (data) => {
            return data ?? '-'
        },
    },
    {
        label: 'AWB',
        accessor: 'awb',
        customBuild: (data) => {
            return data ?? '-'
        },
    },
    {
        label: 'Pack List No',
        accessor: 'pack_list_no',
        customBuild: (data) => {
            return data ?? '-'
        },
    },
    {
        label: 'Chargable Weight (kg)',
        accessor: 'weight',
        customBuild: (data) => {
            return data ?? '-'
        },
    },
    {
        label: 'Created By',
        accessor: 'created_by.name',
    },
    {
        label: 'Last Updated',
        accessor: 'updated',
        customBuild: (data) => {
            return formatDate(data)
        },
    },
]

export const consolidationShipmentSummaryHeader: ITableColumn<IShipment>[] = [
    {
        label: 'Consolidation',
        accessor: 'consolidation',
    },
    {
        label: 'Category',
        accessor: 'category',
    },
    {
        label: 'Destination',
        accessor: 'destination_location.name',
    },
    {
        label: 'Carrier',
        accessor: 'carier',
        customBuild: (data) => {
            return data ?? '-'
        },
    },
    {
        label: 'ETD',
        accessor: 'etd',
        customBuild: (data) => {
            return formatDate(data)
        },
    },
    {
        label: 'ETA',
        accessor: 'eta',
        customBuild: (data) => {
            return formatDate(data)
        },
    },
    {
        label: 'Container Type',
        accessor: 'container_type',
        customBuild: (data) => {
            return data ?? '-'
        },
    },
    {
        label: 'Total M2',
        accessor: 'volume',
        customBuild: (data) => {
            return data ?? '-'
        },
    },
    {
        label: 'Total File',
        accessor: 'total_file',
        customBuild: (data) => {
            return data ?? '-'
        },
    },
    {
        label: 'Warehouse',
        accessor: 'warehouse',
        customBuild: (data) => {
            return data ?? '-'
        },
    },
    {
        label: 'docs',
        accessor: 'docs',
        customBuild: (data) => {
            return data ?? '-'
        },
    },
    {
        label: 'Created By',
        accessor: 'created_by.name',
    },
    {
        label: 'Last Updated',
        accessor: 'updated',
        customBuild: (data) => {
            return formatDate(data)
        },
    },
]

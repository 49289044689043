import { ISTColumn } from '@components/simple-table/simple-table.interface'
import moment from 'moment'
import { IEDoc } from 'repository/interface/edocs.interface'

export const header: ISTColumn<IEDoc>[] = [
    {
        accessor: 'created',
        label: 'Date Added',
        minWidth: 100,
        customBuild: (dt, a, b, c, rowData) => {
            const dateRaw = rowData?.created ?? ''
            const date = moment(dateRaw).format('DD/MM/YYYY')
            return <>{`${date}`}</>
        },
    },
    {
        accessor: 'document_type',
        label: 'Document Type',
        minWidth: 100,
    },
    {
        accessor: 'description',
        label: 'Description',
        minWidth: 100,
    },
    {
        accessor: 'filename',
        label: 'File Name',
        minWidth: 200,
        maxWidth: 400,
    },
]

import useDropdown from 'common/dropdown/dropdown.service'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import {
    notesSelector,
    setFetchNotes,
    setNotesData,
    shipmentDetailSelector,
} from '../detail-shipment.slice'
import { useFormik } from 'formik'
import { useModal } from '@components/modal/modal.service'
import {
    IFormNotes,
    IFormNotesPayload,
    useNotesValidation,
} from 'form-validation/notes.validation'
import {
    deleteNotes,
    fetchNotes,
    submitNotes,
    updateNote,
} from 'repository/notes.repository'
import { INotes } from 'repository/interface/notes.interface'
import moment from 'moment'

export const useNotes = () => {
    const dropdownService = useDropdown()
    const dispatch = useAppDispatch()

    // States
    const [isLoading, setIsLoading] = useState(false)
    const [isNew, setIsNew] = useState(true)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)

    // Selector
    const notes = useSelector(notesSelector)
    const shipmentDetailData = useSelector(shipmentDetailSelector)

    // Variable
    const { data, isFetchNew } = notes
    const modalService = useModal()

    // Formik
    const useNotesFormik = useNotesValidation()
    const formikNotes = useFormik<IFormNotes>({
        initialValues: useNotesFormik.generateInitialValue(),
        validationSchema: useNotesFormik.schema,
        validateOnChange: true,
        onSubmit: (values) => {
            submit(values)
        },
    })

    const getNotes = async () => {
        if (!shipmentDetailData?.code) return
        if (!isFetchNew) return
        setIsLoading(true)
        const response = await fetchNotes(shipmentDetailData.code)
        if (!response?.data?.length) {
            dispatch(setNotesData([]))
        } else {
            dispatch(setNotesData(response.data))
        }

        setIsLoading(false)
        dispatch(setFetchNotes(false))
    }

    const resetForm = () => {
        formikNotes.setValues(useNotesFormik.generateInitialValue())
    }

    const deleteItem = async () => {
        const code = shipmentDetailData.code ?? ''
        const noteCode = formikNotes.getFieldProps('code').value
        setDeleteLoading(true)
        await deleteNotes(code, noteCode)
        setDeleteLoading(false)
        resetForm()
        modalService.closeModalHandling()
        dispatch(setFetchNotes(true))
        getNotes()
    }

    const submit = async (value: IFormNotes) => {
        const code = shipmentDetailData.code ?? ''
        const noteCode = value.code ?? ''

        const payload: IFormNotesPayload = {
            category: value.category,
            text: value.text,
        }

        setSubmitLoading(true)
        let response: true | undefined
        if (isNew) {
            response = await submitNotes(code, payload)
        } else {
            response = await updateNote(code, noteCode, payload)
        }
        if (!response) {
            setSubmitLoading(false)
            return
        }

        resetForm()
        modalService.closeModalHandling()
        setSubmitLoading(false)
        getNotes()
    }

    const selectRow = (value: INotes) => {
        const newValue: IFormNotes = {
            category: value.category ?? '',
            text: value.text ?? '',
            code: value.code,
            created_by: value.created_by?.name ?? '-',
            creation_date: moment(value.created ?? undefined).format(
                'DD/MM/YYYY',
            ),
            last_updated: moment(value.updated ?? undefined).format(
                'DD/MM/YYYY',
            ),
            source: value.source ?? '-',
        }
        formikNotes.setValues(newValue)
        modalService.openModalHandling()
    }

    useEffect(() => {
        getNotes()
    }, [isFetchNew, shipmentDetailData])

    useEffect(() => {
        dropdownService.getNoteCategory()
    }, [])

    return {
        dropdownService,
        modalService,
        isLoading,
        data,
        formikNotes,
        isNew,
        submitLoading,
        deleteLoading,
        setIsNew,
        resetForm,
        submit,
        selectRow,
        deleteItem,
    }
}

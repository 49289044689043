var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from '@components/modal/modal.component';
var ModalSidebarComponent = function (_a) {
    var modalService = _a.modalService, content = _a.content, title = _a.title;
    return (_jsx(Modal, __assign({ isModalOpen: modalService.isModalOpen, className: "md:w-1/3 mobile:min-w-0 min-w-[500px] !p-0 md:absolute md:!right-0 sticky !m-4 top-[70px]" }, { children: _jsx("div", { children: _jsxs("div", __assign({ className: "flex flex-1 flex-col" }, { children: [_jsxs("div", __assign({ className: "flex font-bold text-size-L justify-between p-4 items-center border-b" }, { children: [title, _jsx("div", __assign({ className: "flex justify-center items-center pl-4" }, { children: _jsx("i", { className: "ri-close-fill cursor-pointer ", onClick: function () {
                                        modalService.closeModalHandling();
                                    } }) }))] })), content] })) }) })));
};
export default ModalSidebarComponent;

import { ITabItem } from '@components/tab/tab.interface'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { IMeta2 } from '@components/pagination/pagination.interface'
import { IFetchParams, IResponseData } from 'common/common.interface'
import { IContact } from 'repository/interface/contact.interface'
import { tabItemStatic } from './contact.static'

interface IContactSlice {
    data: IContact[]
    meta: IMeta2
    fetchParams: IFetchParams
    exportFetchParams: IFetchParams
    tabItems: ITabItem[]
    tabItemSelected: ITabItem
    isLoading: boolean
}
export const initialState: IContactSlice = {
    data: [],
    tabItemSelected: tabItemStatic[0],
    tabItems: tabItemStatic,
    meta: {
        start: 1,
        size: 50,
        page: 1,
        data_count: 50,
        total_data_count: 50,
        total_page: 1,
    },
    fetchParams: {
        recountingData: true,
        status: 'ACTIVE',
    },
    exportFetchParams: {},
    isLoading: false,
}

const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoading: data }
        },
        setFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, fetchParams: data }
        },
        setExportFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, setExportFetchParam: data }
        },
        setData(state, action: PayloadAction<IResponseData<IContact[]>>) {
            const data = action.payload
            return { ...state, data: data.data, meta: data.meta }
        },
        setTabItems(state, action: PayloadAction<ITabItem[]>) {
            const data = action.payload
            return { ...state, tabItems: data }
        },
        setSelectedTabItem(state, action: PayloadAction<ITabItem>) {
            const data = action.payload
            const fetchParam = state.fetchParams
            return {
                ...state,
                fetchParams: {
                    ...fetchParam,
                    recountingData: false,
                    status: data.value,
                },
                tabItemSelected: data,
            }
        },
    },
})

// these all the variables used for selector
export const contactFetchParamSelector = (state: RootState) =>
    state.contact.fetchParams || {}
export const contactExportFetchParamSelector = (state: RootState) =>
    state.contact.exportFetchParams || {}
export const contactDataSelector = (state: RootState) =>
    state.contact.data || []
export const contactMetaSelector = (state: RootState) =>
    state.contact.meta || {}
export const contactTabItemsSelector = (state: RootState) =>
    state.contact.tabItems
export const contactSelectedTabItemSelector = (state: RootState) =>
    state.contact.tabItemSelected
export const contactIsLoading = (state: RootState) => state.booking.isLoading

// all actions
export const {
    setData,
    setSelectedTabItem,
    setTabItems,
    setFetchParam,
    setIsLoading,
} = contactSlice.actions

export default contactSlice.reducer

import * as Yup from 'yup'

// form validation YUP for formik
export const orgDetailsValidation = Yup.object({
    code: Yup.string().required('Code required'),
})
export const addressListValidation = Yup.object({
    addressName: Yup.string().required('Address Name required'),
})
export const contactListValidation = Yup.object({
    contactName: Yup.string().required('Contact Name required'),
})
export const accountValidation = Yup.object({
    accountName: Yup.string().required('Account Name required'),
})

// form login initial data
export const organisationDetailInitialValue: any = {
    detail: {
        status: 'active',
        organisationType: [],
        code: 'Auto Generate',
        fullName: '',
        category: '',
        personIndividu: '',
        email: '',
        emailAccounting: '',
        phoneNumber: '',
        mobileNumber: '',
        fax: '',
        website: '',
        language: '',
        remark: '',
        governmentTaxType: '',
        taxNumber: '',
    },
}

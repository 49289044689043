import useDropdown from 'common/dropdown/dropdown.service'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import { useFormik } from 'formik'
import { useModal } from '@components/modal/modal.service'
import {
    dataSelector,
    fetchParamSelector,
    metaSelector,
    setData,
    setFetchParam,
} from './product-category.slice'
import { IFetchParams } from 'common/common.interface'
import {
    IFProductCategory,
    useProductCategoryValidation,
} from 'form-validation/product-category.validation'
import {
    deleteProductCategory,
    getProductCategory,
    submitProductCategory,
    updateProductCategory,
} from 'repository/product-category.repository'
import { IProductCategory } from 'repository/interface/product-category.interface'

export const useProductCategory = () => {
    const dropdownService = useDropdown()
    const dispatch = useAppDispatch()
    const modalService = useModal()

    // States
    const [isLoading, setIsLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [isNew, setIsNew] = useState(true)

    // Selector
    const data = useSelector(dataSelector)
    const meta = useSelector(metaSelector)
    const fetchParams = useSelector(fetchParamSelector)

    // Formik
    const validation = useProductCategoryValidation()
    const formik = useFormik<IFProductCategory>({
        initialValues: validation.initialValue,
        validationSchema: validation.schema,
        validateOnChange: true,
        onSubmit: (values) => {
            submit(values)
        },
    })

    const getData = async (params: IFetchParams) => {
        setIsLoading(true)
        const response = await getProductCategory(params)
        if (!response) {
            return
        } else {
            dispatch(setData(response))
        }

        setIsLoading(false)
    }

    const resetForm = () => {
        formik.setValues(validation.initialValue)
    }

    const getPayloadUnitValue = (value: string | undefined) => {
        if (value === undefined) return undefined
        if (value === '0') return undefined
        return { code: value }
    }

    const submit = async (value: IFProductCategory) => {
        let response: true | undefined
        if (isNew) {
            response = await submitProductCategory(value)
        } else {
            response = await updateProductCategory(value)
        }
        if (!response) {
            setSubmitLoading(false)
            return
        }

        resetForm()
        modalService.closeModalHandling()
        setSubmitLoading(false)
        getData(fetchParams)
    }

    const selectRow = (value: IProductCategory) => {
        const newValue: IFProductCategory = {
            code: value.code,
            name: value.name,
        }
        formik.setValues(newValue)
        setIsNew(false)
        modalService.openModalHandling()
    }

    const deleteItem = async () => {
        const code = formik.getFieldProps('code').value
        setDeleteLoading(true)
        await deleteProductCategory(code)
        setDeleteLoading(false)
        resetForm()
        modalService.closeModalHandling()
        getData(fetchParams)
    }

    const handleSearch = (search: string) => {
        dispatch(
            setFetchParam({
                page: 1,
                search: search,
            }),
        )
    }

    useEffect(() => {
        getData(fetchParams)
    }, [fetchParams])

    useEffect(() => {
        dropdownService.getVolumeUnit()
    }, [])

    return {
        dropdownService,
        modalService,
        isLoading,
        data,
        formik,
        isNew,
        submitLoading,
        deleteLoading,
        meta,
        fetchParams,
        getPayloadUnitValue,
        deleteItem,
        setIsNew,
        resetForm,
        submit,
        selectRow,
        handleSearch,
    }
}

import Button from '@components/button/button.component'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import useDropdown from 'common/dropdown/dropdown.service'
import { IFServiceRates } from 'form-validation/service-rate.validation'
import { FormikProps, FormikProvider } from 'formik'
import { IChargeCode } from 'repository/interface/charge-code.interface'

const ServiceRateForm = ({
    formik,
    submitLoading,
    deleteLoading,
    isNew,
    deleteHandling,
    addChargeCodeHandling,
    removeChargeCodeHandling,
}: {
    formik: FormikProps<IFServiceRates>
    submitLoading: boolean
    deleteLoading: boolean
    isNew: boolean
    deleteHandling: () => void
    addChargeCodeHandling: () => void
    removeChargeCodeHandling: (index: number) => void
}) => {
    const { values } = formik
    const { charge_codes } = values
    const dropdownService = useDropdown()
    const chargeCodeData = dropdownService.chargeCodesData

    return (
        <div className="flex flex-col h-full">
            <div className={`flex-col overflow-auto h-[calc(100vh-240px)] p-4`}>
                <FormikProvider value={formik}>
                    <FormInput
                        parentDivClassName="w-full"
                        name="name"
                        label="Name"
                        useUppercaseLabel={true}
                        placeholder="Input Name"
                    />

                    <div className="border-b py-2 mb-3 flex items-center justify-between">
                        <p>Charge Codes</p>
                        <Button
                            className="!text-size-S !w-[30px] !h-[30px]"
                            variant="brand"
                            icon="ri-add-line"
                            onClick={() => {
                                addChargeCodeHandling()
                            }}
                        />
                    </div>

                    {charge_codes.map((_, index) => (
                        <div key={index} className="flex items-top gap-3 mb-2">
                            <FormDropdown
                                parentDivClassName="w-full !mb-0"
                                name={`charge_codes[${index}].code`}
                                options={chargeCodeData}
                                label="Charge Code"
                                useUppercaseLabel={true}
                                additionalOnClick={(data) => {
                                    if (!formik) return
                                    const _data =
                                        data as IDropdownItem<IChargeCode>
                                    const value = _data.additionalData?.price
                                    formik?.setFieldValue(
                                        `charge_codes[${index}].price`,
                                        value,
                                    )
                                }}
                            />
                            <FormInput
                                parentDivClassName="w-full"
                                name={`charge_codes[${index}].price`}
                                label="Price"
                                useUppercaseLabel={true}
                                placeholder="price"
                                disabled
                            />
                            <div className="flex-1">
                                <Button
                                    className={`!text-size-S !w-[32px] !h-[30px] mt-[5px]  `}
                                    isDisabled={index < 1}
                                    onClick={() => {
                                        if (index > 0)
                                            removeChargeCodeHandling(index)
                                    }}
                                    variant="red"
                                    icon="ri-subtract-line"
                                />
                            </div>
                        </div>
                    ))}
                </FormikProvider>
            </div>
            <div className="p-4 flex justify-between border-t">
                <Button
                    label="DELETE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-delete-bin-line"
                    variant="red-inverse"
                    onClick={() => {
                        deleteHandling()
                    }}
                    isLoading={deleteLoading}
                    isDisabled={isNew}
                />
                <Button
                    label="SAVE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-save-line"
                    variant="brand"
                    onClick={() => {
                        formik.submitForm()
                    }}
                    isLoading={submitLoading}
                />
            </div>
        </div>
    )
}

export default ServiceRateForm

import * as YUP from 'yup'
import { requiredMessage } from './validation-message.static'

export interface IFUser {
    // User Profile
    fullName: string
    job_title: string
    job_category: string
    // User Account
    email: string
    user_name: string
    // User Role
    role: string
    site: string
    // User Address
    country?: string
    province?: string
    state?: string
    city?: string
    addressDetail?: string
    // User Contact
    mobile?: {
        country_code?: string
        number?: string
    }
    emergency_contact_name?: string
    emergency_contact_phone?: {
        country_code?: string
        number?: string
    }
    phone?: string
    status?: string
}

export interface IFUserPayload extends IFUser {
    role: any
}

export const userFormValidation: YUP.Schema<IFUser> = YUP.object().shape({
    // User Profile
    fullName: YUP.string().required(requiredMessage('Full Name')),
    job_title: YUP.string().required(requiredMessage('Job Title')),
    job_category: YUP.string().required(requiredMessage('Job Category')),
    // User Role
    role: YUP.string().required(requiredMessage('Role')),
    site: YUP.string().required(requiredMessage('Site')),

    // User Address
    country: YUP.string(),
    province: YUP.string(),
    state: YUP.string(),
    city: YUP.string(),
    addressDetail: YUP.string(),
    status: YUP.string(),

    // User Contact
    mobile: YUP.object().shape({
        country_code: YUP.string().matches(
            /^\+\d+$/,
            'Country code must start with a "+" followed by digits',
        ),
        number: YUP.string().matches(
            /^\d+$/,
            'Phone number must contain only digits',
        ),
    }),
    phone: YUP.string(),
    emergency_contact_name: YUP.string(),
    emergency_contact_phone: YUP.object().shape({
        country_code: YUP.string().matches(
            /^\+\d+$/,
            'Country code must start with a "+" followed by digits',
        ),
        number: YUP.string().matches(
            /^\d+$/,
            'Phone number must contain only digits',
        ),
    }),

    // User Account
    user_name: YUP.string().required(requiredMessage('User ID')).default(''),
    email: YUP.string()
        .email('Invalid format (eg. loglines@gmail.com)')
        .max(50, '')
        .required(requiredMessage('Email'))
        .default(''),
})

// form user initial data
export const userFormInitial: IFUser = {
    fullName: '',
    job_title: '',
    job_category: '',
    email: '',
    user_name: '',
    site: '',
    role: '',
    country: '',
    state: '',
    city: '',
}

// Dummy Edit User
export const userFormUpdate: IFUser = {
    fullName: 'John Doe',
    job_title: 'Developer',
    job_category: 'IT',
    email: 'john.doe@example.com',
    user_name: 'johnD',
    site: 'Jakarta Office',
    role: 'user',
    mobile: {
        country_code: '+62',
        number: '8123456789',
    },
}

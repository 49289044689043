import * as Yup from 'yup'
import { requiredMessage } from './validation-message.static'
import { IOrganisation } from './form-contact.validation'

export interface IFormShipmentDetail {
    status: string
    transport_mode: string
    container_mode: string
    container_number: string
    volume_measure: number
    volume_unit: string
    service_level: string
    shippers_ref: string
    payment_type: string
    house_bill: string
    house_bill_status: string
    seal_number?: string
    feeder?: string
    marks_and_numbers?: string
    origin_location: string
    origin_country: string
    origin_state: string
    destination_location: string
    destination_country: string
    destination_state: string
    etd: string
    atd: string
    eta: string
    ata: string
    shipper: string
    shipper_address: string
    shipper_address_1: string
    consignee: string
    consignee_address: string
    consignee_address_1: string
    pick_up_included?: boolean
    pick_up_estimated?: string
    pick_up_actual?: string
    pick_up_warehouse_transit?: string
    delivery_included?: boolean
    delivery_estimated?: string
    delivery_actual_deliver?: string
    consolidation?: boolean
    consolidation_id?: string
    consolidation_console_type?: string
    consolidation_console_category?: string
    charges_inco_term?: string
    charges_additional_terms?: string
    charges_apply?: string
    contacts_info_agent?: IOrganisation
    contacts_info_notify?: IOrganisation[]
    goods_product_category?: string
    goods_value?: number
    goods_value_currency?: string
    goods_insurance_value?: number
    goods_insurance_value_currency?: string
    goods_gross_weight?: number
    goods_chargable_weight?: number
    goods_vol_as?: string
    goods_description?: string
    emkl?: string
    carrier_company?: string
    carrier_number?: string
    carrier_date?: string
}

export interface IFormShipmentDetailPayload {
    status: string
    container_mode: string
    shipper: { code: string }
    shipper_address: { code: string }
    include_pick_up: boolean
    consignee: { code: string }
    consignee_address: { code: string }
    with_delivery: boolean
    etd: string
    eta: string
    origin_location: { code: string }
    destination_location: { code: string }
    agent: { code: string }
    payment_type: string
    incoterm: string
    service_level: string
    house_bill_status: string
    marks_and_numbers: string
    atd: string
    ata: string
    est_pick_up_date: string
    act_pick_up_date: string
    est_delivery_date: string
    act_delivery_date: string
    additional_term: string
    charges_apply: string
    container_number: string
    shipper_ref: string
    house_bill: string
    seal_number: string
    flight_number?: string
    hawb_number?: string
    emkl: string
    carrier: { code: string }
    carrier_number: string
    carrier_date: string
    consolidation: { code: string }
}

export const useShipmentDetailValidation = () => {
    const schema = Yup.object<IFormShipmentDetail>().shape({
        transport_mode: Yup.string().required(
            requiredMessage('Transport Mode'),
        ),
        container_mode: Yup.string().required(
            requiredMessage('Container Mode'),
        ),
        container_number: Yup.string().required(
            requiredMessage('Container Number'),
        ),
        volume_measure: Yup.number()
            .typeError(requiredMessage('Volume Measure'))
            .required(requiredMessage('Volume Measure')),
        volume_unit: Yup.string().required(requiredMessage('Volume Unit')),
        service_level: Yup.string().required(requiredMessage('Service Level')),
        shippers_ref: Yup.string().required(
            requiredMessage("Shipper's Reference"),
        ),
        payment_type: Yup.string().required(requiredMessage('Payment Type')),
        house_bill: Yup.string().required(requiredMessage('House Bill')),
        house_bill_status: Yup.string().required(
            requiredMessage('House Bill Status'),
        ),
        seal_number: Yup.string().nullable(),
        feeder: Yup.string().nullable(),
        marks_and_numbers: Yup.string().nullable(),
        origin_location: Yup.string().required(
            requiredMessage('Origin Location'),
        ),
        origin_country: Yup.string().required(
            requiredMessage('Origin Country'),
        ),
        origin_state: Yup.string().required(requiredMessage('Origin State')),
        destination_location: Yup.string().required(
            requiredMessage('Destination Location'),
        ),
        destination_country: Yup.string().required(
            requiredMessage('Destination Country'),
        ),
        destination_state: Yup.string().required(
            requiredMessage('Destination State'),
        ),
        etd: Yup.string().required(requiredMessage('ETD')),
        atd: Yup.string().nullable(),
        eta: Yup.string().required(requiredMessage('ETA')),
        ata: Yup.string().nullable(),
        shipper: Yup.string().required(requiredMessage('Shipper')),
        shipper_address: Yup.string().required(
            requiredMessage('Shipper Address'),
        ),
        shipper_address_1: Yup.string().required(
            requiredMessage('Shipper Address 1'),
        ),
        consignee: Yup.string().required(requiredMessage('Consignee')),
        consignee_address: Yup.string().required(
            requiredMessage('Consignee Address'),
        ),
        consignee_address_1: Yup.string().required(
            requiredMessage('Consignee Address 1'),
        ),
        pick_up_included: Yup.boolean().nullable(),
        pick_up_estimated: Yup.string().nullable(),
        pick_up_actual: Yup.string().nullable(),
        pick_up_warehouse_transit: Yup.string().nullable(),
        delivery_included: Yup.boolean().nullable(),
        delivery_estimated: Yup.string().nullable(),
        delivery_actual_deliver: Yup.string().nullable(),
        consolidation: Yup.boolean().nullable(),
        consolidation_id: Yup.string().nullable(),
        consolidation_console_type: Yup.string().nullable(),
        consolidation_console_category: Yup.string().nullable(),
        charges_inco_term: Yup.string().nullable(),
        charges_additional_terms: Yup.string().nullable(),
        charges_apply: Yup.string().nullable(),
        goods_product_category: Yup.string().nullable(),
        goods_value: Yup.number().nullable(),
        goods_value_currency: Yup.string().nullable(),
        goods_insurance_value: Yup.number().nullable(),
        goods_insurance_value_currency: Yup.string().nullable(),
        goods_gross_weight: Yup.number().nullable(),
        goods_chargable_weight: Yup.number().nullable(),
        goods_vol_as: Yup.string().nullable(),
        goods_description: Yup.string().nullable(),
        contacts_info_agent: Yup.object()
            .shape({
                code: Yup.string().nullable(),
                email: Yup.string(),
                phone: Yup.string(),
            })
            .nullable()
            .nullable(),
        contacts_info_notify: Yup.array()
            .of(
                Yup.object().shape({
                    code: Yup.string().required(
                        'Please select contact to notify',
                    ),
                    email: Yup.string(),
                    phone: Yup.string(),
                }),
            )
            .nullable(),
        carrier_company: Yup.string().nullable(),
        carrier_date: Yup.string().nullable(),
        carrier_number: Yup.string().nullable(),
        status: Yup.string().nullable(),
    })

    const initialValue: IFormShipmentDetail = {
        transport_mode: '',
        container_mode: '',
        container_number: '',
        volume_measure: 0,
        volume_unit: '',
        service_level: '',
        shippers_ref: '',
        payment_type: '',
        house_bill: '',
        house_bill_status: '',
        seal_number: '',
        feeder: '',
        marks_and_numbers: '',
        origin_location: '',
        origin_country: '',
        etd: '',
        atd: '',
        eta: '',
        ata: '',
        shipper: '',
        shipper_address: '',
        shipper_address_1: '',
        pick_up_included: false,
        pick_up_estimated: '',
        pick_up_actual: '',
        pick_up_warehouse_transit: '',
        delivery_included: false,
        delivery_estimated: '',
        delivery_actual_deliver: '',
        consolidation: false,
        consolidation_id: '',
        consolidation_console_type: '',
        consolidation_console_category: '',
        charges_inco_term: '',
        charges_additional_terms: '',
        charges_apply: '',
        contacts_info_agent: {
            code: '',
            name: '',
        },
        contacts_info_notify: [],
        goods_product_category: '',
        goods_value: 0,
        goods_value_currency: '',
        goods_insurance_value: 0,
        goods_insurance_value_currency: '',
        goods_gross_weight: 0,
        goods_chargable_weight: 0,
        goods_vol_as: '',
        goods_description: '',
        origin_state: '',
        destination_location: '',
        destination_country: '',
        destination_state: '',
        consignee: '',
        consignee_address: '',
        consignee_address_1: '',
        carrier_company: '',
        carrier_date: '',
        carrier_number: '',
        emkl: '',
        status: '',
    }

    return {
        initialValue,
        schema,
    }
}

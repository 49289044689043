import Button from '@components/button/button.component'
import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInputFile from '@components/form-input-file/form-input-file.component'
import FormInput from '@components/form-input/form-input.component'
import FormMeasurementInput from '@components/form-measurement-input/form-measurement-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import useDropdown from 'common/dropdown/dropdown.service'
import { IFItemLists } from 'form-validation/item-list.validation'
import { FormikProps, FormikProvider } from 'formik'
import { discountTypes } from './item-list.static'
import { useEffect } from 'react'

const ItemListForm = ({
    formik,
    submitLoading,
    deleteLoading,
    isNew,
    deleteHandling,
}: {
    formik: FormikProps<IFItemLists>
    submitLoading: boolean
    deleteLoading: boolean
    isNew: boolean
    deleteHandling: () => void
}) => {
    const dropdownService = useDropdown()

    useEffect(() => {
        const { amount, discount } = formik.values

        if (amount?.qty && discount) {
            let calculatedTotalAmount = 0
            // Handle discount based on percentage
            if (discount?.unit === 'PERCENTAGE') {
                const discountRate = discount?.qty
                    ? Number(discount.qty) / 100
                    : 0
                const calculatedDiscount = Number(amount.qty) * discountRate
                calculatedTotalAmount = Number(amount.qty) - calculatedDiscount
            }
            // Handle discount based on fixed amount
            else if (discount?.unit === 'AMOUNT') {
                const fixedDiscount = discount?.qty ? Number(discount.qty) : 0
                calculatedTotalAmount = Number(amount.qty) - fixedDiscount
            }
            const total_amount_form = {
                ...formik.values.total_amount,
                qty: calculatedTotalAmount,
            }
            formik.setFieldValue('total_amount', total_amount_form)
        }
    }, [formik.values.discount, formik.values.amount])
    return (
        <div className="flex flex-col h-full">
            <div className={`flex-col overflow-auto h-[calc(100vh-240px)] p-4`}>
                <FormikProvider value={formik}>
                    <div className="flex gap-2">
                        <FormInput
                            parentDivClassName="w-full"
                            name="order_id"
                            label="Order id"
                            useUppercaseLabel={true}
                            placeholder="(Auto Generate)"
                            disabled={true}
                        />
                        <FormDropdown
                            parentDivClassname="w-full"
                            name="order_status"
                            options={dropdownService.orderStatusData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="Select Status"
                            disabled={!!isNew}
                        />
                    </div>
                    <div className="flex gap-2">
                        <FormDropdown
                            parentDivClassname="w-full"
                            name="supplier"
                            options={dropdownService.organisationSupplierData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="Supplier"
                        />
                        <FormInput
                            parentDivClassName="w-full"
                            name="name"
                            label="Order Name"
                        />
                    </div>
                    <div className="flex gap-2 ">
                        <FormMeasurementInput
                            disabled
                            name={'amount'}
                            label="Amount"
                            useUppercaseLabel={true}
                            options={dropdownService.currencyData}
                            parentDivClassName="mb-4 w-full"
                        />
                        <FormMeasurementInput
                            name={'discount'}
                            label="Global Discount (optional)"
                            useUppercaseLabel={true}
                            options={discountTypes}
                            parentDivClassName="mb-4 w-full"
                        />
                    </div>
                    <FormMeasurementInput
                        name={'total_amount'}
                        label="Total Amount"
                        disabled
                        useUppercaseLabel={true}
                        options={dropdownService.currencyData}
                        parentDivClassName="mb-4 w-full"
                    />
                    <FormDatePicker
                        parentDivClassName="w-full mb-3"
                        name="date"
                        label="Order Date (optional)"
                        isRange={false}
                    />
                    <FormTextarea
                        name="note"
                        parentDivClπassName="mb-3"
                        label="Note (optional"
                    />

                    <div className="border-b mb-3"></div>
                    <div className="text-size-M font-bold">Attach eDocs</div>
                    <div className="text-size-S themes-text-gray-v4 mb-3">
                        Add related media to this order
                    </div>
                    {isNew ? (
                        <FormInputFile
                            name={'file'}
                            label="Upload a File"
                            onChange={(e) => {
                                // selectFileHandling(e)
                            }}
                        />
                    ) : (
                        <></>
                    )}
                    <FormDropdown
                        parentDivClassname="w-full"
                        name="document_type"
                        options={dropdownService.documentTypeData}
                        parentDivClassName="w-full"
                        useUppercaseLabel={true}
                        label="document type"
                        placeholder="Select Document Type"
                    />
                    <FormTextarea
                        name={'description'}
                        label="Description"
                        className="mb-3"
                    />
                </FormikProvider>
            </div>
            <div className="p-4 flex justify-between border-t">
                <Button
                    label="DELETE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-delete-bin-line"
                    variant="red-inverse"
                    onClick={() => {
                        deleteHandling()
                    }}
                    isLoading={deleteLoading}
                    isDisabled={isNew}
                />
                <Button
                    label="SAVE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-save-line"
                    variant="brand"
                    onClick={() => {
                        formik.submitForm()
                    }}
                    isLoading={submitLoading}
                />
            </div>
        </div>
    )
}

export default ItemListForm

import TabMaster from '@components/tab-master/tab-master.component'
import Table from '@components/table/table.component'
import useContact from './contact.service'
import { contactSummaryHeader } from './contact.static'
import { IContact } from 'repository/interface/contact.interface'
import Button from '@components/button/button.component'
import ContactFormModal from './components/contact-modal/contact-modal-component'
import ContactForm from './form-contact/form-contact.component'
import ContactExportModal from './components/filter/contact-export.component'
import ContactFilterOverlay from './components/filter/contact-filter-overlay.component'
const Contact = () => {
    const {
        tabItems,
        contactMeta,
        contactData,
        selectedTabItem,
        loading,
        fetchParam,
        filterOverlay,
        modalService,
        formik,
        isSubmitLoading,
        isEdit,
        isCreate,
        exportModalService,
        exportLoading,
        exportLoadData,
        setExportLoading,
        removeContact,
        setTabFilter,
        handleSearch,
        handlePagination,
        setValue,
        setCreate,
        setIsEdit,
        resetHandling,
        handleFilter,
    } = useContact()

    const rowOptionComponent = (
        rowData: IContact,
        closeHandling: () => void,
    ) => (
        <div className="w-full">
            <Button
                onClick={() => {
                    setCreate(false)
                    setIsEdit(true)
                    setValue(rowData)
                    modalService.openModalHandling()
                }}
                className="!w-full border-none text-left"
                icon="ri-edit-line"
                label="Edit Contact"
            />
            <Button
                onClick={() => {
                    removeContact(rowData)
                }}
                className="!w-full border-none text-left"
                variant={'red-inverse'}
                icon="ri-delete-bin-line"
                label="Remove Contact"
            />
            {/* <Button
                onClick={() => {
                    updateContactStatus(rowData)
                }}
                className="!w-full border-none text-left"
                variant={'red-inverse'}
                icon="ri-user-unfollow-line"
                label="Mark as Non-Active"
            /> */}
        </div>
    )

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <TabMaster
                    items={tabItems || []}
                    tabFilter={selectedTabItem}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search Quotation No. '}
                    containerSearchClassName={'flex-1'}
                    initialSearch={fetchParam.search}
                    onSearchSubmit={(values) => handleSearch(values)}
                    iconButton1={{
                        onClick: () => filterOverlay.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',

                        filterOverlayComponent: (
                            <ContactFilterOverlay
                                filterOverlayService={filterOverlay}
                                onFilterSubmit={(data) => {
                                    console.log(data)
                                    handleFilter(data)
                                }}
                            />
                        ),
                    }}
                    actionButton1={{
                        onClick: () => {
                            formik.resetForm()
                            modalService.openModalHandling()
                            setCreate(true)
                            setIsEdit(true)
                        },
                        label: 'CREATE CONTACT',
                        className: '!text-size-S',
                    }}
                />
                <Table<IContact>
                    headers={contactSummaryHeader}
                    data={contactData}
                    loading={loading}
                    nextHandling={(page) => {
                        handlePagination(page)
                    }}
                    previousHandling={(page) => {
                        handlePagination(page)
                    }}
                    meta2={contactMeta}
                    moduleTitle={'Contact List'}
                    onRowClick={(data: IContact) => {
                        modalService.openModalHandling()
                        setCreate(false)
                        setIsEdit(false)
                        setValue(data)
                    }}
                    enableExport={true}
                    exportModalService={exportModalService}
                    rowOptionComponent={rowOptionComponent}
                />
            </div>
            <ContactFormModal
                modalService={modalService}
                isCreate={isCreate}
                formContacts={
                    <ContactForm
                        formik={formik}
                        isEdit={isEdit}
                        isCreate={isCreate}
                        modalService={modalService}
                        submitLoading={isSubmitLoading}
                        resetHandling={() => resetHandling()}
                    />
                }
            />

            <ContactExportModal
                modalService={exportModalService}
                onSubmit={async (data) => {
                    setExportLoading(true)
                    await exportLoadData(data)
                    setExportLoading(false)
                    exportModalService.closeModalHandling()
                }}
                isLoading={exportLoading}
            />
        </div>
    )
}

export default Contact

import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { formatDate } from '@services/common.service'
import { IUnlocodes } from 'repository/interface/unlocodes.interface'

export const header: ITableColumn<IUnlocodes>[] = [
    { accessor: 'name', label: 'Name', width: 10 },
    { accessor: 'locode', label: 'Locode', width: 10 },
    { accessor: 'iata', label: 'IATA', width: 10 },
    {
        accessor: 'country_code',
        label: 'Country Code',
        width: 10,
        customBuild: (value, rowData) => {
            const countryCode = rowData?.country_code ?? ''
            const country = rowData?.country ?? ''
            return `${countryCode} - ${country}`
        },
    },
    {
        accessor: 'updated',
        label: 'Updated',
        customBuild: (data) => {
            return formatDate(data, 'DD/MM/YYYY HH:mm')
        },
    },
]

export const tabItem: ITabItem[] = [
    { label: 'All Unlocode', value: 'all', key: 'status' },
]

import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchBranches,
    fetchChargeCode,
    fetchCities,
    fetchContainerModes,
    fetchContainerTypes,
    fetchCountries,
    fetchCurrency,
    fetchOrganisations,
    fetchJobCategories,
    fetchRoles,
    fetchServiceRates,
    fetchShippingRates,
    fetchStates,
    fetchTransportMode,
    fetchUnlocos,
    fetchVolumeUnit,
    fetchWeightUnit,
    fetchBookingStatuses,
    fetchOrganisationCategories,
    fetchContactTypes,
    fetchPaymentTypes,
    fetchHouseBillStatus,
    fetchConsoleType,
    fetchConsoleCategory,
    fetchConsolidation,
    fetchShipmentStatus,
    fetchPackTypes,
    fetchHsCode,
    fetchProductCategories,
    fetchlengthUnit,
    fetchDeliveryMode,
    fetchNoteCategory,
    fetchDocumentType,
    fetchOrderStatus,
    fetchCustomsUnit,
    fetchOrganisationTypes,
    fetchQuantityUnit,
} from 'repository/dropdown.repository'
import { ICurrency } from 'repository/interface/currency.interface'
import {
    dropdownAllContainerModeSelector,
    dropdownBranchesSelector,
    dropdownChargeCodesSelector,
    dropdownCitiesSelector,
    dropdownContainerModeSelector,
    dropdownContainerTypeSelector,
    dropdownCountriesSelector,
    dropdownCurrencySelector,
    dropdownJobCategorySelector,
    dropdownRolesSelector,
    dropdownServiceLengthSelector,
    dropdownServiceQuantityUnitsSelector,
    dropdownServiceRatesSelector,
    dropdownServiceVolumeSelector,
    dropdownServiceWeightSelector,
    dropdownShippingRatesSelector,
    dropdownStatesSelector,
    dropdownTransportModeSelector,
    dropdownUnlocodesSelector,
    dropdownOrganisationsSelector,
    setAllContainerModes,
    setBranches,
    setChargeCodes,
    setCities,
    setContainerModes,
    setContainerType,
    setCountries,
    setCurrency,
    setOrganisations,
    setJobCategory,
    setRoles,
    setServiceRates,
    setShippingRates,
    setStates,
    setTransportMode,
    setUnlocodes,
    setVolumeUnits,
    setWeightUnits,
    setShipperOrganisations,
    dropdownShipperOrganisationsSelector,
    dropdownConsigneeOrganisationsSelector,
    setConsigneeOrganisations,
    setAgentOrganisations,
    dropdownBookingStatusesSelector,
    setBookingStatuses,
    dropdownOrganisationCategoriesSelector,
    setOrganisationCategories,
    dropdownOrganisationTypesSelector,
    setOrganisationTypes,
    dropdownContactTypesSelector,
    setContactTypes,
    dropdownCarrierOrganisationsSelector,
    dropdownSupplierOrganisationsSelector,
    dropdownWarehouseOrganisationsSelector,
    setWarehouseOrganisations,
    setCarrierOrganisations,
    setSupplierOrganisations,
    dropdownPaymentTypeSelector,
    setPaymentTypes,
    dropdownHouseBillStatusSelector,
    setHouseBillStatus,
    dropdownConsolidation,
    dropdownConsoleCategory,
    dropdownConsoleType,
    setConsolidation,
    setConsoleCategory,
    setConsoleType,
    dropdownAgentOrganisationsSelector,
    dropdownShipmentStatus,
    setShipmentStatus,
    setPackTypes,
    dropdownPackTypes,
    dropdownHsCodes,
    dropdownProductCategories,
    setProductCategories,
    setHSCodes,
    setLengthUnits,
    dropdownDeliveryModeSelector,
    setDeliveryMode,
    dropdownNoteCategorySelector,
    setNoteCategory,
    dropdownDocumentTypeSelector,
    setDocumentType,
    dropdownOrderStatusSelector,
    setOrderStatus,
    dropdownServiceCustomUnitsSelector,
    setCustomsUnit,
    setQuantityUnits,
} from './dropdown.slice'
import { IServiceRate } from 'repository/interface/service-rate.interface'
import { IShippingRate } from 'repository/interface/shipping-rate.interface'
import { ITransportMode } from 'repository/interface/transport-mode.interface'
import { IUnit } from 'repository/interface/unit.interface'
import { IUnlocodes } from 'repository/interface/unlocodes.interface'
import { IFetchParams } from 'common/common.interface'
import { IChargeCode } from 'repository/interface/charge-code.interface'
import {
    IOrganisation,
    IOrganisationCategories,
    IOrganisationTypes,
} from 'repository/interface/organisation.interface'
import { ISite } from 'repository/interface/site.interface'
import {
    ICities,
    ICountries,
    IStates,
} from 'repository/interface/country.interface'
import { IBookingStatus } from 'repository/interface/booking.interface'
import { IJobCategories } from 'repository/interface/user.interface'
import { IContactTypes } from 'repository/interface/contact-types.interface'
import { IPaymentType } from 'repository/interface/payment.interface'
import {
    ICommonDropdown,
    ICommonDropdown2,
} from 'repository/interface/common.interface'
import {
    IConsoleCategory,
    IConsolidation,
} from 'repository/interface/consolidation.interface'
import { IHSCode } from 'repository/interface/hs.-code.interface'

const useDropdown = () => {
    // Selector & dispatch
    const dispatch = useDispatch()
    const currencyData = useSelector(dropdownCurrencySelector)
    const branchesData = useSelector(dropdownBranchesSelector)
    const rolesData = useSelector(dropdownRolesSelector)
    const containerTypeData = useSelector(dropdownContainerTypeSelector)
    const transportModesData = useSelector(dropdownTransportModeSelector)
    const shippingRatesData = useSelector(dropdownShippingRatesSelector)
    const serviceRatesData = useSelector(dropdownServiceRatesSelector)
    const weightUnitsData = useSelector(dropdownServiceWeightSelector)
    const volumeUnitsData = useSelector(dropdownServiceVolumeSelector)
    const lengthUnitsData = useSelector(dropdownServiceLengthSelector)
    const customsUnitsData = useSelector(dropdownServiceCustomUnitsSelector)
    const quantityUnitData = useSelector(dropdownServiceQuantityUnitsSelector)
    const containerModeData = useSelector(dropdownContainerModeSelector)
    const allContainerModeData = useSelector(dropdownAllContainerModeSelector)
    const unlocodesData = useSelector(dropdownUnlocodesSelector)
    const chargeCodesData = useSelector(dropdownChargeCodesSelector)
    const organisationData = useSelector(dropdownOrganisationsSelector)
    const packTypesData = useSelector(dropdownPackTypes)
    const organisationCarrierData = useSelector(
        dropdownCarrierOrganisationsSelector,
    )
    const organisationSupplierData = useSelector(
        dropdownSupplierOrganisationsSelector,
    )
    const organisationWarehouuseData = useSelector(
        dropdownWarehouseOrganisationsSelector,
    )
    const organisationCategoriesData = useSelector(
        dropdownOrganisationCategoriesSelector,
    )
    const organisationTypesData = useSelector(dropdownOrganisationTypesSelector)
    const shipperOrganisationData = useSelector(
        dropdownShipperOrganisationsSelector,
    )
    const consigneeOrganisationData = useSelector(
        dropdownConsigneeOrganisationsSelector,
    )
    const agentOrganisationData = useSelector(
        dropdownAgentOrganisationsSelector,
    )

    const countriesData = useSelector(dropdownCountriesSelector)
    const statesData = useSelector(dropdownStatesSelector)
    const citiesData = useSelector(dropdownCitiesSelector)
    const jobCategoriesData = useSelector(dropdownJobCategorySelector)
    const bookingStatusesData = useSelector(dropdownBookingStatusesSelector)
    const contactTypesData = useSelector(dropdownContactTypesSelector)
    const paymentTypesData = useSelector(dropdownPaymentTypeSelector)
    const houseBillStatusData = useSelector(dropdownHouseBillStatusSelector)
    const consoleTypeData = useSelector(dropdownConsoleType)
    const consoleCategoryData = useSelector(dropdownConsoleCategory)
    const consolidationData = useSelector(dropdownConsolidation)
    const shipmentStatusData = useSelector(dropdownShipmentStatus)
    const hsCodesData = useSelector(dropdownHsCodes)
    const productCategoriesData = useSelector(dropdownProductCategories)
    const deliveryModeData = useSelector(dropdownDeliveryModeSelector)
    const noteCategoryData = useSelector(dropdownNoteCategorySelector)
    const documentTypeData = useSelector(dropdownDocumentTypeSelector)
    const orderStatusData = useSelector(dropdownOrderStatusSelector)

    const getCurrency = async () => {
        if (currencyData.length > 0) return
        const response = await fetchCurrency()
        if (!response) return
        const dropdown: IDropdownItem<ICurrency>[] = response.data.map((d) => {
            return {
                label: d.short_code + '\t|\t' + d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setCurrency(dropdown))
    }
    const getBranches = async () => {
        if (branchesData.length > 0) return
        const response = await fetchBranches()
        if (!response) return
        const dropdown: IDropdownItem<ISite>[] = response.data.map((d) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setBranches(dropdown))
    }
    const getCountries = async () => {
        if (countriesData.length > 0) return
        const response = await fetchCountries()
        if (!response) return
        const dropdown: IDropdownItem<ICountries>[] = response.data.map(
            (d: any) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setCountries(dropdown))
    }
    const getStates = async (country: string) => {
        const response = await fetchStates(country)
        if (!response) return
        const dropdown: IDropdownItem<IStates>[] = response.data.map(
            (d: any) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setStates(dropdown))
    }
    const getCities = async (state?: string) => {
        const response = await fetchCities(state)
        if (!response) return
        const dropdown: IDropdownItem<ICities>[] = response.data.map(
            (d: ICities) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setCities(dropdown))
    }
    const getRoles = async () => {
        if (rolesData.length > 0) return
        const response = await fetchRoles()
        if (!response) return
        const dropdown: IDropdownItem<any>[] = response.data.map((d) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setRoles(dropdown))
    }
    const getContainerType = async () => {
        if (containerTypeData.length > 0) return
        const response = await fetchContainerTypes()
        if (!response) return
        const dropdown: IDropdownItem<any>[] = response.data.map((d) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setContainerType(dropdown))
    }
    const getTransportModes = async () => {
        if (transportModesData.length > 0) return
        const response = await fetchTransportMode()
        if (!response) return
        const dropdown: IDropdownItem<ITransportMode>[] = response.data.map(
            (d) => {
                return {
                    label: d.code + ' - ' + d.label,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setTransportMode(dropdown))
    }
    const getShippingRates = async (payload: IFetchParams) => {
        const response = await fetchShippingRates(payload)
        if (!response) return
        const dropdown: IDropdownItem<IShippingRate>[] = response.data.map(
            (d) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        console.log(dropdown)
        dispatch(setShippingRates(dropdown))
    }

    const getServiceRates = async () => {
        const response = await fetchServiceRates()
        if (!response) return
        const dropdown: IDropdownItem<IServiceRate>[] = response.data.map(
            (d) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setServiceRates(dropdown))
    }

    const getChargeCode = async () => {
        if (chargeCodesData.length > 0) return
        const response = await fetchChargeCode()
        if (!response) return
        const dropdown: IDropdownItem<IChargeCode>[] = response.data.map(
            (d) => {
                return {
                    label: `${d.charge_code} - ${d.name}`,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setChargeCodes(dropdown))
    }

    const getWeightUnit = async () => {
        if (weightUnitsData.length > 0) return
        const response = await fetchWeightUnit()
        if (!response) return
        const dropdown: IDropdownItem<IUnit>[] = response.data.map((d) => {
            return {
                label: `${d.symbol} - ${d.label}`,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setWeightUnits(dropdown))
    }

    const getVolumeUnit = async () => {
        if (volumeUnitsData.length > 0) return
        const response = await fetchVolumeUnit()
        if (!response) return
        const dropdown: IDropdownItem<IUnit>[] = response.data.map((d) => {
            return {
                label: `${d.symbol} - ${d.label}`,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setVolumeUnits(dropdown))
    }

    const getLengthUnit = async () => {
        if (lengthUnitsData.length > 0) return
        const response = await fetchlengthUnit()
        if (!response) return
        const dropdown: IDropdownItem<IUnit>[] = response.data.map((d) => {
            return {
                label: `${d.symbol} - ${d.label}`,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setLengthUnits(dropdown))
    }

    const getQuantityUnit = async () => {
        if (quantityUnitData.length > 0) return
        const response = await fetchQuantityUnit()
        if (!response) return
        const dropdown: IDropdownItem<IUnit>[] = response.data.map((d) => {
            return {
                label: `${d.label}`,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setQuantityUnits(dropdown))
    }

    const getCustomsUnit = async () => {
        if (customsUnitsData.length > 0) return
        const response = await fetchCustomsUnit()
        if (!response) return
        const dropdown: IDropdownItem<IUnit>[] = response.data.map((d) => {
            return {
                label: `${d.symbol} - ${d.label}`,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setCustomsUnit(dropdown))
    }

    const getContainerModes = async (transportMode: string) => {
        const response = await fetchContainerModes(transportMode)
        if (!response) return
        const dropdown: IDropdownItem<ITransportMode>[] = response.data.map(
            (d) => {
                return {
                    label: d.code + ' - ' + d.label,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setContainerModes(dropdown))
    }

    const getAllContainerModes = async () => {
        const response1 = await fetchContainerModes('SEA')
        const response2 = await fetchContainerModes('AIR')
        if (!response1) return
        if (!response2) return
        const dropdown1: IDropdownItem<ITransportMode>[] = response1.data.map(
            (d) => {
                return {
                    label: d.code + ' - ' + d.label,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        const dropdown2: IDropdownItem<ITransportMode>[] = response2.data.map(
            (d) => {
                return {
                    label: d.code + ' - ' + d.label,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setAllContainerModes([...dropdown1, ...dropdown2]))
    }

    const getUnlocos = async () => {
        if (unlocodesData.length > 0) return
        const response = await fetchUnlocos()
        if (!response) return
        const dropdown: IDropdownItem<IUnlocodes>[] = response.data.map((d) => {
            return {
                label: d.locode,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setUnlocodes(dropdown))
    }

    const getOrganisations = async () => {
        if (organisationData.length > 0) return
        const response = await fetchOrganisations('')
        console.log(response)
        if (!response) return
        const dropdown: IDropdownItem<IOrganisation>[] = response.data.map(
            (d) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setOrganisations(dropdown))
    }

    const getOrganisationCategories = async () => {
        if (organisationData.length > 0) return
        const response = await fetchOrganisationCategories()
        if (!response) return
        const dropdown: IDropdownItem<IOrganisationCategories>[] =
            response.data.map((d) => {
                return {
                    label: d.label,
                    value: d.code,
                }
            })
        dispatch(setOrganisationCategories(dropdown))
    }

    const getOrganisationTypes = async () => {
        if (organisationData.length > 0) return
        const response = await fetchOrganisationTypes()
        if (!response) return
        const dropdown: IDropdownItem<IOrganisationTypes>[] = response.data.map(
            (d) => {
                return {
                    label: d.label,
                    value: d.code,
                }
            },
        )
        dispatch(setOrganisationTypes(dropdown))
    }

    const getOrganisationWarehouse = async () => {
        if (organisationWarehouuseData.length > 0) return
        const response = await fetchOrganisations('warehouses')
        if (!response) return
        const dropdown: IDropdownItem<IOrganisation>[] = response.data.map(
            (d) => {
                return {
                    label: d.name,
                    value: d.code,
                }
            },
        )
        dispatch(setWarehouseOrganisations(dropdown))
    }
    const getOrganisationCarrier = async () => {
        if (organisationCarrierData.length > 0) return
        const response = await fetchOrganisations('carriers')
        if (!response) return
        const dropdown: IDropdownItem<IOrganisation>[] = response.data.map(
            (d) => {
                return {
                    label: d.name,
                    value: d.code,
                }
            },
        )
        dispatch(setCarrierOrganisations(dropdown))
    }

    const getOrganisationSupplier = async () => {
        if (organisationSupplierData.length > 0) return
        const response = await fetchOrganisations('suppliers')
        if (!response) return
        const dropdown: IDropdownItem<IOrganisation>[] = response.data.map(
            (d) => {
                return {
                    label: d.name,
                    value: d.code,
                }
            },
        )
        dispatch(setSupplierOrganisations(dropdown))
    }

    const getJobCategories = async () => {
        if (jobCategoriesData.length > 0) return
        const response = await fetchJobCategories()
        if (!response) return
        const dropdown: IDropdownItem<IJobCategories>[] = (
            response as any
        ).data.map((d: { name: any; code: any }) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setJobCategory(dropdown))
    }

    const getShipperOrganisations = async () => {
        if (shipperOrganisationData.length > 0) return
        const response = await fetchOrganisations('shippers')
        if (!response) return
        const dropdown: IDropdownItem<IOrganisation>[] = (
            response as any
        ).data.map((d: any) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setShipperOrganisations(dropdown))
    }

    const getAgentOrganisations = async () => {
        if (agentOrganisationData.length > 0) return
        const response = await fetchOrganisations('agents')
        if (!response) return
        const dropdown: IDropdownItem<IOrganisation>[] = (
            response as any
        ).data.map((d: any) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setAgentOrganisations(dropdown))
    }

    const getConsigneeOrganisations = async () => {
        if (consigneeOrganisationData.length > 0) return
        const response = await fetchOrganisations('consignees')
        if (!response) return
        const dropdown: IDropdownItem<IOrganisation>[] = (
            response as any
        ).data.map((d: any) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setConsigneeOrganisations(dropdown))
        dispatch(setJobCategory(dropdown))
    }

    const getBookingStatuses = async () => {
        if (bookingStatusesData.length > 0) return
        const response = await fetchBookingStatuses()
        if (!response) return
        const dropdown: IDropdownItem<IBookingStatus>[] = response.data.map(
            (d) => {
                return {
                    label: d.label,
                    value: d.code,
                }
            },
        )
        dispatch(setBookingStatuses(dropdown))
    }

    const getContactTypes = async () => {
        if (contactTypesData.length > 0) return
        const response = await fetchContactTypes()
        if (!response) return
        const dropdown: IDropdownItem<IContactTypes>[] = response.data.map(
            (d) => {
                return {
                    label: d.label,
                    value: d.code,
                }
            },
        )
        dispatch(setContactTypes(dropdown))
    }

    const getPaymentTypes = async () => {
        if (contactTypesData.length > 0) return
        const response = await fetchPaymentTypes()
        if (!response) return
        const dropdown: IDropdownItem<IPaymentType>[] = response.data.map(
            (d) => {
                return {
                    label: d.label ?? '',
                    value: d.code ?? '',
                }
            },
        )
        dispatch(setPaymentTypes(dropdown))
    }

    const getHouseBillStatus = async () => {
        if (houseBillStatusData.length > 0) return
        const response = await fetchHouseBillStatus()
        if (!response) return
        const dropdown: IDropdownItem<ICommonDropdown>[] = response.data.map(
            (d) => {
                return {
                    label: d.label ?? '',
                    value: d.code ?? '',
                }
            },
        )
        dispatch(setHouseBillStatus(dropdown))
    }

    const getConsoleType = async () => {
        if (consoleTypeData.length > 0) return
        const response = await fetchConsoleType()
        if (!response) return
        const dropdown: IDropdownItem<ICommonDropdown>[] = response.data.map(
            (d) => {
                return {
                    label: d.label ?? '',
                    value: d.code ?? '',
                }
            },
        )
        dispatch(setConsoleType(dropdown))
    }

    const getConsoleCategory = async () => {
        if (consoleCategoryData.length > 0) return
        const response = await fetchConsoleCategory()
        if (!response) return
        const dropdown: IDropdownItem<IConsoleCategory>[] = response.data.map(
            (d) => {
                return {
                    label: d.name ?? '',
                    value: d.code ?? '',
                }
            },
        )
        dispatch(setConsoleCategory(dropdown))
    }

    const getConsolidation = async () => {
        if (consolidationData.length > 0) return
        const response = await fetchConsolidation()
        if (!response) return
        const dropdown: IDropdownItem<IConsolidation>[] = response.data.map(
            (d) => {
                return {
                    label: d.console_id ?? '',
                    value: d.code ?? '',
                }
            },
        )
        dispatch(setConsolidation(dropdown))
    }

    const getShipmentStatus = async () => {
        if (shipmentStatusData.length > 0) return
        const response = await fetchShipmentStatus()
        if (!response) return
        const dropdown: IDropdownItem<ICommonDropdown>[] = response.data.map(
            (d) => {
                return {
                    label: d.label ?? '',
                    value: d.code ?? '',
                }
            },
        )
        dispatch(setShipmentStatus(dropdown))
    }

    const getPackTypes = async () => {
        if (packTypesData.length > 0) return
        const response = await fetchPackTypes()
        if (!response) return
        const dropdown: IDropdownItem<ICommonDropdown>[] = response.data.map(
            (d) => {
                return {
                    label: d.label ?? '',
                    value: d.code ?? '',
                }
            },
        )
        dispatch(setPackTypes(dropdown))
    }

    const getProductCategories = async () => {
        if (productCategoriesData.length > 0) return
        const response = await fetchProductCategories()
        if (!response) return
        const dropdown: IDropdownItem<ICommonDropdown2>[] = response.data.map(
            (d) => {
                return {
                    label: d.name ?? '',
                    value: d.code ?? '',
                }
            },
        )
        dispatch(setProductCategories(dropdown))
    }

    const getHSCodes = async () => {
        if (hsCodesData.length > 0) return
        const response = await fetchHsCode()
        if (!response) return
        const dropdown: IDropdownItem<IHSCode>[] = response.data.map((d) => {
            return {
                label: d.name ?? '',
                value: d.code ?? '',
            }
        })
        dispatch(setHSCodes(dropdown))
    }

    const getDeliveryMode = async () => {
        if (deliveryModeData.length > 0) return
        const response = await fetchDeliveryMode()
        if (!response) return
        const dropdown: IDropdownItem<ICommonDropdown>[] = response.data.map(
            (d) => {
                return {
                    label: d.label ?? '',
                    value: d.code ?? '',
                }
            },
        )
        dispatch(setDeliveryMode(dropdown))
    }

    const getNoteCategory = async () => {
        if (noteCategoryData.length > 0) return
        const response = await fetchNoteCategory()
        if (!response) return
        const dropdown: IDropdownItem<ICommonDropdown>[] = response.data.map(
            (d) => {
                return {
                    label: d.label ?? '',
                    value: d.code ?? '',
                }
            },
        )
        dispatch(setNoteCategory(dropdown))
    }

    const getDocumentType = async () => {
        if (documentTypeData.length > 0) return
        const response = await fetchDocumentType()
        if (!response) return
        const dropdown: IDropdownItem<ICommonDropdown>[] = response.data.map(
            (d) => {
                return {
                    label: d.label ?? '',
                    value: d.code ?? '',
                }
            },
        )
        dispatch(setDocumentType(dropdown))
    }

    const getOrderStatus = async () => {
        if (orderStatusData.length > 0) return
        const response = await fetchOrderStatus()
        if (!response) return
        const dropdown: IDropdownItem<ICommonDropdown>[] = response.data.map(
            (d) => {
                return {
                    label: d.label ?? '',
                    value: d.code ?? '',
                }
            },
        )
        dispatch(setOrderStatus(dropdown))
    }

    return {
        getCurrency,
        getRoles,
        getContainerType,
        getTransportModes,
        getShippingRates,
        getServiceRates,
        getWeightUnit,
        getVolumeUnit,
        getLengthUnit,
        getQuantityUnit,
        getContainerModes,
        getUnlocos,
        getChargeCode,
        getBranches,
        getAllContainerModes,
        getShipperOrganisations,
        getOrganisations,
        getConsigneeOrganisations,
        getAgentOrganisations,
        getCountries,
        getStates,
        getCities,
        getJobCategories,
        getBookingStatuses,
        getOrganisationCategories,
        getOrganisationTypes,
        getContactTypes,
        getOrganisationWarehouse,
        getOrganisationCarrier,
        getOrganisationSupplier,
        getPaymentTypes,
        getHouseBillStatus,
        getConsoleType,
        getConsoleCategory,
        getConsolidation,
        getShipmentStatus,
        getPackTypes,
        getProductCategories,
        getHSCodes,
        getDeliveryMode,
        getNoteCategory,
        getDocumentType,
        getOrderStatus,
        getCustomsUnit,
        customsUnitsData,
        orderStatusData,
        documentTypeData,
        noteCategoryData,
        deliveryModeData,
        hsCodesData,
        productCategoriesData,
        packTypesData,
        consigneeOrganisationData,
        rolesData,
        containerModeData,
        currencyData,
        shippingRatesData,
        serviceRatesData,
        containerTypeData,
        weightUnitsData,
        quantityUnitData,
        volumeUnitsData,
        lengthUnitsData,
        transportModesData,
        unlocodesData,
        chargeCodesData,
        branchesData,
        allContainerModeData,
        organisationData,
        shipperOrganisationData,
        agentOrganisationData,
        countriesData,
        citiesData,
        organisationTypesData,
        statesData,
        jobCategoriesData,
        bookingStatusesData,
        organisationCategoriesData,
        contactTypesData,
        organisationCarrierData,
        organisationSupplierData,
        organisationWarehouuseData,
        paymentTypesData,
        houseBillStatusData,
        consoleTypeData,
        consoleCategoryData,
        consolidationData,
        shipmentStatusData,
    }
}

export default useDropdown

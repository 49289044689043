import * as Yup from 'yup'
import { requiredMessage } from './validation-message.static'
import { IContact, IPhones } from 'repository/interface/contact.interface'
import { IAddress } from 'repository/interface/address.interface'
import moment from 'moment'

export interface IFormContact {
    code?: string
    name: string
    nick_name: string
    title: string
    job_title: string
    job_category: string
    phones: IPhones[]
    emails: string[]
    birth_date: string
    working_address: string
    organisations: string[]
    created: string
    updated: string
    entity_status: string
    status: string
}

export interface IFormContactPayload {
    code?: string
    name: string
    nick_name: string
    title: string
    job_title: string
    job_category: { code: string }
    phones: IPhones[]
    emails: string[]
    birth_date: string
    working_address: string
    organisations: IOrganisation[]
    status: string
}

export interface IOrganisation {
    code: string
    name?: string
    addresses?: IAddress[]
    contacts?: IContact[]
    is_active?: boolean
}

export interface IUpdateStatusPayload {
    status: string
}

export const useContactValidation = () => {
    const formContactSchema = Yup.object().shape({
        name: Yup.string().required(requiredMessage('Name')),
        nick_name: Yup.string(),
        title: Yup.string(),
        job_title: Yup.string().required(requiredMessage('Job Title')),
        job_category: Yup.string().required(requiredMessage('Job Category')),
        phones: Yup.array()
            .of(
                Yup.object().shape({
                    country_code: Yup.string().required(
                        'Country code is required',
                    ),
                    number: Yup.string()
                        .required('Phone number is required')
                        .matches(
                            /^\d+$/,
                            'Phone number must contain only digits',
                        ),
                }),
            )
            .min(1, 'At least one phone number is required'),
        emails: Yup.array()
            .of(
                Yup.string()
                    .email('Invalid email format')
                    .required('Email is required'),
            )
            .min(1, 'At least one email is required'),
        organisations: Yup.array()
            .of(Yup.string().required('Organisation related is required'))
            .min(1, 'At least one organisation is required'),
        working_address: Yup.string(),
    })

    const generateInitialValue = () => {
        const initialValue: IFormContact = {
            code: '',
            name: '',
            nick_name: '',
            job_title: '',
            job_category: '',
            phones: [],
            emails: [],
            birth_date: '',
            working_address: '',
            organisations: [],
            entity_status: '',
            created: moment().format('DD/MM/YYYY'),
            updated: moment().format('DD/MM/YYYY'),
            title: '',
            status: '',
        }
        return initialValue
    }

    return {
        generateInitialValue,
        formContactSchema,
    }
}

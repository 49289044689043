import { ISTColumn } from '@components/simple-table/simple-table.interface'
import moment from 'moment'
import { INotes } from 'repository/interface/notes.interface'

export const header: ISTColumn<INotes>[] = [
    {
        accessor: 'category',
        label: 'Note Category',
        minWidth: 100,
    },
    {
        accessor: 'text',
        label: 'Notes Text',
        minWidth: 200,
        maxWidth: 400,
    },
    {
        accessor: 'created_by',
        label: 'Created By',
        minWidth: 100,
        customBuild: (dt, a, b, c, rowData) => {
            const name = rowData?.created_by?.name ?? ''
            return <>{`${name}`}</>
        },
    },
    {
        accessor: 'source',
        label: 'Source',
        minWidth: 100,
    },
    {
        accessor: 'created',
        label: 'Creation Date',
        minWidth: 100,
        customBuild: (dt, a, b, c, rowData) => {
            const dateRaw = rowData?.created ?? ''
            const date = moment(dateRaw).format('DD/MM/YYYY')
            return <>{`${date}`}</>
        },
    },
    {
        accessor: 'updated',
        label: 'Last Updated',
        minWidth: 100,
        customBuild: (dt, a, b, c, rowData) => {
            const dateRaw = rowData?.updated ?? ''
            const date = moment(dateRaw).format('DD/MM/YYYY')
            return <>{`${date}`}</>
        },
    },
]

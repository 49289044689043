import useDropdown from 'common/dropdown/dropdown.service'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import { useFormik } from 'formik'
import { useModal } from '@components/modal/modal.service'
import {
    dataSelector,
    fetchParamSelector,
    metaSelector,
    setData,
    setFetchParam,
} from './item-list.slice'
import { IFetchParams } from 'common/common.interface'
import {
    IFOrderItems,
    IFOrderItemsPayload,
    useOrderItemsValidation,
} from 'form-validation/order-item.validation'
import {
    deleteOrderItem,
    getOrderItem,
    getItemList,
    submitOrderItem,
    updateOrderItem,
} from 'repository/order-item.repository'
import { shipmentDetailSelector } from '../detail-shipment.slice'
import { IItemList, IOrderItem } from 'repository/interface/item-list.interface'
import { headerChild } from './item-list.static'
import { ITableColumn } from '@components/table/table.interface'

export const useOrderItem = () => {
    const dropdownService = useDropdown()
    const dispatch = useAppDispatch()
    const modalServiceItem = useModal()

    // States
    const [isLoadingItem, setIsLoading] = useState(false)
    const [submitLoadingItem, setSubmitLoading] = useState(false)
    const [deleteLoadingItem, setDeleteLoading] = useState(false)
    const [isNewItem, setIsNewItem] = useState(true)

    // Selector
    const dataItem = useSelector(dataSelector)
    const meta = useSelector(metaSelector)
    const fetchParams = useSelector(fetchParamSelector)
    const shipmentDetailData = useSelector(shipmentDetailSelector)

    // Formik
    const validation = useOrderItemsValidation()
    const formikItem = useFormik<IFOrderItems>({
        initialValues: validation.initialValue,
        validationSchema: validation.schema,
        validateOnChange: true,
        onSubmit: (values) => {
            submitItem(values)
        },
    })

    const openModalWithParentData = (value: IItemList) => {
        const newValue: IFOrderItems = {
            // parent
            order_code: value?.code,
            order_id: value?.order_id,
            order_status: value?.order_status,
            supplier: value?.supplier.name,
            name_order: value?.name,
            currency: {
                code: value?.currency?.code,
                name: value?.currency?.name,
                symbol: value?.currency?.symbol,
                short_code: value?.currency?.short_code,
            },
            discount_amount_order: {
                qty: value?.discount_amount?.toString() ?? '',
                unit: value?.currency?.code,
                unitLabel: value?.currency?.symbol,
            },
            discount_order: {
                qty: value?.discount?.toString() ?? '0',
                unit: 'PERCENTAGE',
                unitLabel: 'Percentage',
            },
            total_amount_order: Number(value?.total_amount),
            date_order: value?.date,
            note_order: value?.note,

            ...validation.initialValue,
        }

        formikItem.setValues(newValue)
        setIsNewItem(false)
        modalServiceItem.openModalHandling()
    }

    const headerChildrenFormatted: ITableColumn<IOrderItem>[] = [
        ...headerChild,
        {
            accessor: 'add',
            label: 'ADD ITEM',
            customHeader(parentData) {
                const newParent = parentData as unknown as IItemList
                return (
                    <div
                        onClick={() => {
                            openModalWithParentData(newParent)
                        }}
                        className="flex gap-2 px-3 border border-gray-400 justify-center rounded-md text-size-XS"
                    >
                        <i className="ri-add-line"></i>
                        ADD ITEM
                    </div>
                )
            },
        },
    ]

    const getData = async (params: IFetchParams, shipmentCode: string) => {
        setIsLoading(true)
        const response = await getItemList(params, shipmentCode)

        if (!response || !response.data) {
            setIsLoading(false)
            return []
        }
        const updatedData = response.data.map(
            (order: IItemList, index: number) => {
                const updatedItems = order.items.map(
                    (item: IOrderItem, itemIndex: number) => ({
                        ...item,
                        number: itemIndex + 1,
                    }),
                )
                return {
                    ...order,
                    number: index + 1,
                    items: updatedItems,
                }
            },
        )

        const updatedResponse = {
            ...response,
            data: updatedData,
        }

        dispatch(setData(updatedResponse))
        setIsLoading(false)
    }

    const getItem = async (
        shipmentCode: string,
        shipmentOrderCode: string,
        shipmentOrderItemCode: string,
    ) => {
        setIsLoading(true)
        const response = await getOrderItem(
            shipmentCode,
            shipmentOrderCode,
            shipmentOrderItemCode,
        )

        if (!response || !response.data) {
            setIsLoading(false)
            return []
        }

        setIsLoading(false)
        return response.data
    }

    const resetFormItem = () => {
        formikItem.setValues(validation.initialValue)
    }

    const submitItem = async (value: IFOrderItems) => {
        let response: true | undefined
        const shipmentCode = shipmentDetailData.code!
        const shipmentOrderCode = value.order_code!
        const shipmentOrderItemCode = value.item_id!
        const payload: IFOrderItemsPayload = {
            name: value.name,
            priority: value.priority ?? false,
            total_qty: value.total_qty,
            ready_qty: value.ready_qty,
            unit: value.unit,
            price: value.price,
            total_amount: value.total_amount,
            discount:
                value.discount?.unit === 'PERCENTAGE'
                    ? Number(value.discount?.qty) / 100
                    : undefined,
            discount_amount:
                value.discount?.unit === 'AMOUNT'
                    ? Number(value.discount?.qty)
                    : undefined,
            product_category: {
                code: value.product_category ?? '',
            },
            item_material: {
                code: value.item_material ?? '',
            },
            weight: Number(value.weight.qty),
            weight_unit: {
                code: value.weight.unit ?? '',
            },
            volume: Number(value.volume.qty),
            volume_unit: {
                code: value.volume.unit ?? '',
            },
            hs_code: value.hs_code,
            supplier_item_number: value.supplier_item_number,
            customer_item_number: value.customer_item_number,
            note: value.note,
        }

        if (isNewItem) {
            response = await submitOrderItem(
                shipmentCode,
                shipmentOrderCode,
                payload,
            )
        } else {
            response = await updateOrderItem(
                shipmentCode,
                shipmentOrderCode,
                shipmentOrderItemCode,
                payload,
            )
        }

        if (!response) {
            setSubmitLoading(false)
            return
        }
        resetFormItem()
        modalServiceItem.closeModalHandling()
        setSubmitLoading(false)
        getData(fetchParams, shipmentDetailData.code || '')
    }

    const deleteItem = async (
        shipmentOrderCode?: string,
        shipmentOrderItemCode?: string,
    ) => {
        const code = shipmentDetailData.code ?? ''
        const resolvedOrderId =
            shipmentOrderCode || formikItem.getFieldProps('order_code').value
        const itemId =
            shipmentOrderItemCode || formikItem.getFieldProps('item_id').value

        try {
            setDeleteLoading(true)
            await deleteOrderItem(code, resolvedOrderId, itemId)
            resetFormItem()
            modalServiceItem.closeModalHandling()
            getData(fetchParams, code)
        } catch (error) {
            console.error('Failed to delete item:', error)
        } finally {
            setDeleteLoading(false)
        }
    }

    const handleSearchItem = (search: string) => {
        dispatch(
            setFetchParam({
                page: 1,
                search: search,
            }),
        )
    }

    const selectRowItem = (value: IOrderItem) => {
        const newValue: IFOrderItems = {
            item_id: value.code,
            priority: value.priority,
            name: value.name,
            unit: value.unit,
            total_qty: value.total_qty,
            ready_qty: 0,
            price: value.price,
            discount: {
                qty: value?.discount
                    ? (Number(value.discount) * 100).toString()
                    : value?.discount_amount
                      ? value.discount_amount.toString()
                      : undefined,
                unit: value?.discount
                    ? 'PERCENTAGE'
                    : value?.discount_amount
                      ? 'AMOUNT'
                      : undefined,
                unitLabel: value?.discount
                    ? 'Percentage'
                    : value?.discount_amount
                      ? 'Amount'
                      : undefined,
            },
            total_amount: value.total_amount,
            product_category: value?.hs_code?.name,
            hs_code: value?.hs_code?.hs_code,
            weight: {
                qty: value.weight?.toString() ?? '0',
                unit: value.weight_unit?.code,
                unitLabel: value.weight_unit?.label,
            },
            volume: {
                qty: value.volume?.toString() ?? '0',
                unit: value.volume_unit?.code,
                unitLabel: value.volume_unit?.label,
            },
            supplier_item_number: value.supplier_item_number,
            customer_item_number: value.customer_item_number,
            note: value.note,
            // parent
            order_code: value.parent?.code,
            order_id: value.parent?.order_id,
            order_status: value.parent?.order_status,
            supplier: value.parent?.supplier.name,
            name_order: value.parent?.name,
            currency: {
                code: value.parent?.currency?.code,
                name: value.parent?.currency?.name,
                symbol: value.parent?.currency?.symbol,
                short_code: value.parent?.currency?.short_code,
            },
            discount_amount_order: {
                qty: value.parent?.discount_amount?.toString() ?? '',
                unit: value?.parent?.currency?.code,
                unitLabel: value?.parent?.currency?.symbol,
            },
            discount_order: {
                qty: value.parent?.discount?.toString() ?? '0',
                unit: 'PERCENTAGE',
                unitLabel: 'Percentage',
            },
            total_amount_order: Number(value.parent?.total_amount),
            date_order: value.parent?.date,
            note_order: value.parent?.note,
        }

        formikItem.setValues(newValue)
        setIsNewItem(false)
        modalServiceItem.openModalHandling()
    }

    useEffect(() => {
        getData(fetchParams, shipmentDetailData.code || '')
    }, [fetchParams])

    useEffect(() => {
        dropdownService.getQuantityUnit()
        dropdownService.getHSCodes()
        dropdownService.getProductCategories()
        dropdownService.getOrganisationSupplier()
        dropdownService.getWeightUnit()
        dropdownService.getVolumeUnit()
    }, [])

    return {
        dropdownService,
        modalServiceItem,
        isLoadingItem,
        dataItem,
        formikItem,
        isNewItem,
        submitLoadingItem,
        deleteLoadingItem,
        meta,
        fetchParams,
        headerChildrenFormatted,
        getItem,
        openModalWithParentData,
        selectRowItem,
        deleteItem,
        setIsNewItem,
        resetFormItem,
        submitItem,
        handleSearchItem,
    }
}

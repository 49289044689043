import Button from '@components/button/button.component'
import FormCheckbox from '@components/form-checkbox/form-checkbox.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import FormMeasurementInput from '@components/form-measurement-input/form-measurement-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import useDropdown from 'common/dropdown/dropdown.service'
import { IFormPackLine } from 'form-validation/pack-line.validation'
import { FormikProps, FormikProvider } from 'formik'
import { IUnit } from 'repository/interface/unit.interface'

const PackLinesForm = ({
    formik,
    submitLoading,
    deleteHandling,
    deleteLoading,
    isNew,
}: {
    formik: FormikProps<IFormPackLine>
    deleteHandling: () => void
    submitLoading: boolean
    deleteLoading: boolean
    isNew: boolean
}) => {
    const dropdownService = useDropdown()
    return (
        <div className="flex flex-col h-full">
            <div className={`flex-col overflow-auto h-[calc(100vh-240px)] p-4`}>
                <FormikProvider value={formik}>
                    <div className="flex gap-2">
                        <FormInput
                            parentDivClassName="w-full"
                            name="pack_id"
                            label="pack id"
                            useUppercaseLabel={true}
                            placeholder="(Auto Generate)"
                            disabled={true}
                        />
                        <FormDropdown
                            parentDivClassname="w-full"
                            name="pack_type"
                            options={dropdownService.packTypesData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="package type"
                        />
                    </div>
                    <FormInput
                        parentDivClassName="w-full"
                        name="total_packs"
                        label="total pack"
                        useUppercaseLabel={true}
                        placeholder="Add Total Pack"
                        type="number"
                    />
                    <FormCheckbox
                        name="in_container"
                        label="Already in Container"
                    />

                    <div className="border-b mb-3"></div>
                    <div className="text-size-M font-bold">
                        Items Information (Optional)
                    </div>
                    <div className="text-size-S themes-text-gray-v4 mb-3">
                        Show general information of items inside this packing.
                    </div>

                    <FormDropdown
                        parentDivClassname="w-full"
                        name="product_categories"
                        options={dropdownService.productCategoriesData}
                        parentDivClassName="w-full"
                        useUppercaseLabel={true}
                        label="product categories"
                        isMultiSelect={true}
                        placeholder="Select Product Category"
                        isClearable={true}
                    />
                    <FormDropdown
                        parentDivClassname="w-full"
                        name="hs_codes"
                        options={dropdownService.hsCodesData}
                        parentDivClassName="w-full"
                        useUppercaseLabel={true}
                        label="HS Codes"
                        isMultiSelect={true}
                        placeholder="Select HS Code"
                        isClearable={true}
                    />

                    <FormTextarea
                        name="goods_description"
                        parentDivClassName="mb-3"
                        label="GOODS DESCRIPTION (OPTIONAL)"
                    />

                    <div className="border-b mb-3"></div>
                    <div className="text-size-M font-bold">
                        Pack Size (Optional)
                    </div>
                    <div className="text-size-S themes-text-gray-v4 mb-3">
                        Show size details of this pack line.
                    </div>
                    <FormMeasurementInput<IUnit>
                        name={'length'}
                        label="length measure (optional)"
                        useUppercaseLabel={true}
                        options={dropdownService.lengthUnitsData}
                        parentDivClassName="mb-4"
                    />
                    <FormMeasurementInput<IUnit>
                        name={'width'}
                        label="width measure (optional)"
                        useUppercaseLabel={true}
                        options={dropdownService.lengthUnitsData}
                        parentDivClassName="mb-4"
                    />
                    <FormMeasurementInput<IUnit>
                        name={'height'}
                        label="height measure (optional)"
                        useUppercaseLabel={true}
                        options={dropdownService.lengthUnitsData}
                        parentDivClassName="mb-4"
                    />
                    <div className="flex gap-2 w-full">
                        <FormMeasurementInput<IUnit>
                            name={'weight'}
                            label="weight measure (optional)"
                            useUppercaseLabel={true}
                            options={dropdownService.weightUnitsData}
                            parentDivClassName="mb-4 w-full"
                        />
                        <FormMeasurementInput<IUnit>
                            name={'volume'}
                            label="volume measure (optional)"
                            useUppercaseLabel={true}
                            options={dropdownService.volumeUnitsData}
                            parentDivClassName="mb-4 w-full"
                        />
                    </div>
                </FormikProvider>
            </div>
            <div className="p-4 flex justify-between border-t">
                <Button
                    label="DELETE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-delete-bin-line"
                    variant="red-inverse"
                    onClick={() => {
                        deleteHandling()
                    }}
                    isLoading={deleteLoading}
                    isDisabled={isNew}
                />
                <Button
                    label="SAVE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-save-line"
                    variant="brand"
                    onClick={() => {
                        formik.submitForm()
                    }}
                    isLoading={submitLoading}
                />
            </div>
        </div>
    )
}

export default PackLinesForm

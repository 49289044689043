import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { IMeta2 } from '@components/pagination/pagination.interface'
import { IFetchParams, IResponseData } from 'common/common.interface'
import { IShippingRate } from 'repository/interface/shipping-rate.interface'

interface IShippingRateSlice {
    data: IShippingRate[]
    meta: IMeta2
    fetchParams: IFetchParams
    isLoading: boolean
}

export const initialState: IShippingRateSlice = {
    data: [],
    meta: {
        start: 1,
        size: 50,
        page: 1,
        data_count: 50,
        total_data_count: 50,
        total_page: 1,
    },
    fetchParams: {},
    isLoading: false,
}

const slice = createSlice({
    name: 'shippingRate',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoading: data }
        },
        setFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, fetchParams: data }
        },
        setData(state, action: PayloadAction<IResponseData<IShippingRate[]>>) {
            const data = action.payload
            return { ...state, data: data.data, meta: data.meta }
        },
    },
})

// these all the variables used for selector
export const fetchParamSelector = (state: RootState) =>
    state.shippingRate.fetchParams || {}
export const dataSelector = (state: RootState) => state.shippingRate.data || []
export const metaSelector = (state: RootState) => state.shippingRate.meta || {}
export const isLoadingSelector = (state: RootState) =>
    state.shippingRate.isLoading

// all actions
export const { setData, setFetchParam, setIsLoading } = slice.actions

export default slice.reducer

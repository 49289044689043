import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'

export const header: ITableColumn[] = [
    { accessor: 'name', label: 'Name', width: 10 },
    { accessor: 'transport_mode', label: 'Transport Mode', width: 10 },
    { accessor: 'container_mode', label: 'Container Mode', width: 10 },
    {
        accessor: 'origin_location.code',
        label: 'Origin Location Code',
        width: 10,
    },
    {
        accessor: 'destination_location.code',
        label: 'Destination Location Code',
        width: 10,
    },
    { accessor: 'carrier.code', label: 'Carrier Code', width: 10 },
    { accessor: 'currency.code', label: 'Currency Code', width: 10 },
    { accessor: 'price', label: 'Price', width: 10 },
    { accessor: 'unit.code', label: 'Unit Code', width: 10 },
    { accessor: 'transit_time', label: 'Transit Time' },
]

export const tabItem: ITabItem[] = [
    { label: 'All Shipping Rate', value: 'all', key: 'status' },
]

import * as Yup from 'yup'
import { requiredMessage } from './validation-message.static'
import { IMeasurement } from '@components/measurement-input/measurement-input.interface'

// Define the interface
interface IPUnit {
    code: string
}

export interface IFItemLists {
    order_code?: string
    order_id?: string
    order_status?: string
    supplier: string
    name: string
    discount?: IMeasurement
    total_amount?: IMeasurement
    date: string
    note?: string
    amount?: IMeasurement
    // file?: string
    // description?: string
    // document_type?: string
}
export interface IFItemListsPayload {
    order_id?: String
    supplier: IPUnit
    name: string
    note?: string
    currency?: IPUnit
    total_amount?: number
    discount?: number
    discount_amount?: number
    date: string
    order_status?: string
}

// Validation schema
export const useItemListsValidation = () => {
    const schema = Yup.object<IFItemLists>().shape({
        order_id: Yup.string(),
        supplier: Yup.string().required(requiredMessage('Supplier')),
        name: Yup.string().required(requiredMessage('Name')),
        note: Yup.string().optional(),
        total_amount: Yup.object<IMeasurement>().shape({
            qty: Yup.string().nullable(),
            unit: Yup.string().nullable(),
        }),
        discount: Yup.object<IMeasurement>().shape({
            qty: Yup.string().nullable(),
            unit: Yup.string().nullable(),
        }),
        discount_amount: Yup.object<IMeasurement>().shape({
            qty: Yup.string().nullable(),
            unit: Yup.string().nullable(),
        }),
        date: Yup.date().required(requiredMessage('Date')),
        order_status: Yup.string().optional(),
        // file: Yup.mixed().required(requiredMessage('File')),
        // description: Yup.string().required(requiredMessage('Description')),
        // document_type: Yup.string().required(requiredMessage('Document Type')),
    })

    // Initial values
    const initialValue: IFItemLists = {
        order_id: '(Auto Generated)',
        supplier: '',
        name: '',
        note: undefined,
        total_amount: undefined,
        discount: undefined,
        date: '',
        order_status: undefined,
    }

    return {
        initialValue,
        schema,
    }
}

import Button from '@components/button/button.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import FormMeasurementInput from '@components/form-measurement-input/form-measurement-input.component'
import useDropdown from 'common/dropdown/dropdown.service'
import { IFormContainer } from 'form-validation/container.validation'
import { FormikProps, FormikProvider } from 'formik'
import { IUnit } from 'repository/interface/unit.interface'

const ContainersForm = ({
    formik,
    submitLoading,
    deleteHandling,
    deleteLoading,
    isNew,
}: {
    formik: FormikProps<IFormContainer>
    deleteHandling: () => void
    submitLoading: boolean
    deleteLoading: boolean
    isNew: boolean
}) => {
    const dropdownService = useDropdown()
    return (
        <div className="flex flex-col h-full">
            <div className={`flex-col overflow-auto h-[calc(100vh-240px)] p-4`}>
                <FormikProvider value={formik}>
                    <FormInput
                        parentDivClassName="w-full"
                        name="container_number"
                        label="Container Number"
                        useUppercaseLabel={true}
                        placeholder="Input Container Number"
                    />
                    <FormDropdown
                        parentDivClassname="w-full"
                        name="container_type"
                        options={dropdownService.containerTypeData}
                        parentDivClassName="w-full"
                        useUppercaseLabel={true}
                        label="container type"
                        placeholder="Select Container Type"
                    />
                    <FormInput
                        parentDivClassName="w-full"
                        name="seal_number"
                        label="seal number"
                        useUppercaseLabel={true}
                        placeholder="Input Seal Number"
                    />

                    <FormMeasurementInput<IUnit>
                        name={'tare_weight'}
                        label="tare weight"
                        useUppercaseLabel={true}
                        options={dropdownService.weightUnitsData}
                        parentDivClassName="mb-4"
                    />
                    <FormDropdown
                        parentDivClassname="w-full"
                        name="delivery_mode"
                        options={dropdownService.deliveryModeData}
                        parentDivClassName="w-full"
                        useUppercaseLabel={true}
                        label="delivery Mode"
                        placeholder="Select Delivery Mode"
                    />
                </FormikProvider>
            </div>
            <div className="p-4 flex justify-between border-t">
                <Button
                    label="DELETE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-delete-bin-line"
                    variant="red-inverse"
                    onClick={() => {
                        deleteHandling()
                    }}
                    isLoading={deleteLoading}
                    isDisabled={isNew}
                />
                <Button
                    label="SAVE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-save-line"
                    variant="brand"
                    onClick={() => {
                        formik.submitForm()
                    }}
                    isLoading={submitLoading}
                />
            </div>
        </div>
    )
}

export default ContainersForm

import { IFetchParams, IResponseData } from 'common/common.interface'
import {
    deleteRequest,
    errorAxiosToast,
    fetch,
    post,
    put,
} from 'common/common.service'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import { Toast } from '@components/toast/toast.component'
import { INotes } from './interface/notes.interface'
import { IFormNotesPayload } from 'form-validation/notes.validation'

export const fetchNotes = async (
    shipmentCode: string,
    showErrorToast?: boolean,
) => {
    try {
        const response = await fetch<IResponseData<INotes[]>, IFetchParams>({
            endpoint: `${endpoints.shipments}/${shipmentCode}/notes`,
            params: {},
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Notes Data',
            })
        }
        return undefined
    }
}

export const submitNotes = async (code: string, payload: IFormNotesPayload) => {
    try {
        const endpoint = `${endpoints.shipments}/${code}/notes`
        await post<IResponseData<any>, IFormNotesPayload>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'Note is created',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Create Note',
        })
        return undefined
    }
}

export const updateNote = async (
    code: string,
    noteCode: string,
    payload: IFormNotesPayload,
) => {
    try {
        const endpoint = `${endpoints.shipments}/${code}/notes/${noteCode}`
        await put<IResponseData<any>, IFormNotesPayload>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'Note is updated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed update Note',
        })
        return undefined
    }
}

export const deleteNotes = async (code: string, noteCode: string) => {
    try {
        const endpoint = `${endpoints.shipments}/${code}/notes/${noteCode}`
        await deleteRequest<IResponseData<any>, IFormNotesPayload>({
            endpoint: endpoint,
        })

        Toast({
            header: 'Delete Success',
            message: 'Note is deleted',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed delete Note',
        })
        return undefined
    }
}

import { useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import { useAppDispatch } from 'store'
import {
    contactDataSelector,
    contactFetchParamSelector,
    contactMetaSelector,
    contactSelectedTabItemSelector,
    contactTabItemsSelector,
    setData,
    setFetchParam,
    setSelectedTabItem,
    setTabItems,
} from './contact.slice'
import { useSelector } from 'react-redux'
import { Toast } from '@components/toast/toast.component'
import { useNavigate } from 'react-router-dom'
import { IFetchParams } from 'common/common.interface'
import { useModal } from '@components/modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import {
    getContacts,
    submitContact,
    updateContact,
    deleteContact,
    updateStatus,
} from 'repository/contact.repository'
import {
    IFormContact,
    IFormContactPayload,
    IUpdateStatusPayload,
    useContactValidation,
} from 'form-validation/form-contact.validation'
import { IContact } from 'repository/interface/contact.interface'
import { FormikProps, useFormik } from 'formik'
import useDropdown from 'common/dropdown/dropdown.service'
import { generateSimpleReport } from '@services/common.export.service'
import moment from 'moment'
import { contactSummaryHeader } from './contact.static'
import { ITableColumn } from '@components/table/table.interface'

const useContact = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    // modal and overlay
    const modalService = useModal()
    const exportModalService = useModal()
    const filterOverlay = useOverlay()
    const dropdownService = useDropdown()

    // selectors
    const fetchParam = useSelector(contactFetchParamSelector)
    const contactData = useSelector(contactDataSelector)
    const contactMeta = useSelector(contactMetaSelector)
    const tabItems = useSelector(contactTabItemsSelector)
    const selectedTabItem = useSelector(contactSelectedTabItemSelector)

    // states
    const [loading, setLoading] = useState<boolean>(false)
    const [exportLoading, setExportLoading] = useState<boolean>(false)
    const [selectedValue, setSelectedValue] = useState<IFormContact>()
    const [isCreate, setCreate] = useState<boolean>(true)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isSubmitLoading, setIsSubmitLoading] = useState(false)

    // Get Data Function
    const loadData = async (parameters: IFetchParams) => {
        const { recountingData, ...params } = parameters
        try {
            setLoading(true)
            const response = await getContacts(params, true)
            if (!response) {
                setLoading(false)
                dispatch(
                    setData({
                        data: [],
                        meta: {
                            start: 0,
                            size: 0,
                            page: 0,
                            data_count: 0,
                            total_data_count: 0,
                            total_page: 0,
                        },
                    }),
                )
                return
            }
            if (recountingData) {
                getTabItems(params)
            }
            dispatch(setData(response))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const handleSearch = (values: string) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: true,
            size: 50,
            page: 1,
            search: values,
        }
        dispatch(setFetchParam(fParams))
    }

    const handlePagination = (values: number) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            recountingData: false,
            size: 50,
            page: values,
        }
        loadData(fParams)
    }

    const handleFilter = (values: IFetchParams) => {
        const fParams: IFetchParams = {
            ...fetchParam,
            ...values,
            recountingData: true,
            size: 50,
            page: 1,
            fileExportType: undefined,
        }
        dispatch(setFetchParam(fParams))
    }

    // generate tab Items
    const getTabItems = async (fParams: IFetchParams) => {
        let totalActive = 0
        let totalInactive = 0

        const rActive = await getContacts({
            ...fParams,
            status: 'ACTIVE',
        })
        const rInactive = await getContacts({
            ...fParams,
            status: 'INACTIVE',
        })

        if (!rActive || !rInactive) {
            Toast({
                header: 'Error',
                message: 'Failed count tab items data',
                type: 'error',
            })
            return
        }

        totalActive = rActive?.meta.data_count
        totalInactive = rInactive?.meta.data_count

        const tItems: ITabItem[] = tabItems.map((d) => {
            let count
            if (d.value === 'ACTIVE') count = totalActive
            else if (d.value === 'INACTIVE') count = totalInactive
            else count = 0
            return { ...d, totalData: count }
        })
        dispatch(setTabItems(tItems))
    }

    const setTabFilter = (data: ITabItem) => {
        dispatch(setSelectedTabItem(data))
    }

    const setValue = (value: IContact) => {
        const newValue: IFormContact = {
            code: value.code ?? '',
            name: value.name ?? '',
            nick_name: value.nick_name ?? '',
            title: value.title ?? '',
            job_title: value.job_title ?? '',
            job_category: value.job_category?.code ?? '',
            emails: (value.emails || []).map((data) => data),
            birth_date: value.birth_date ?? '',
            working_address: value.working_address ?? '',
            organisations: value.organisations.map((data) => {
                return data.code
            }),
            phones: (value.phones || []).map((data) => {
                return data
            }),
            created: value.created ?? '',
            updated: value.updated ?? '',
            entity_status: value.entity_status ?? '',
            status: value.status ?? '',
        }
        formik.setValues(newValue)
        setSelectedValue(newValue)
    }

    // formik
    const contactValidation = useContactValidation()
    const formik: FormikProps<IFormContact> = useFormik<IFormContact>({
        validationSchema: contactValidation.formContactSchema,
        initialValues: {
            ...contactValidation.generateInitialValue(),
            phones: [{ number: '', country_code: '' }],
            emails: [''],
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            submitForm(values)
        },
    })

    // formatter to YYYY-MM-DD
    const formatDate = (date: Date): string => {
        const formatter = new Intl.DateTimeFormat('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        })
        return formatter.format(date)
    }

    const submitForm = async (data: IFormContact) => {
        const { code, ...value } = data

        const formattedDate = value.birth_date
            ? formatDate(new Date(value.birth_date))
            : ''

        const payloadStatus = value.status ? 'ACTIVE' : 'INACTIVE'

        setIsSubmitLoading(true)
        const payload: IFormContactPayload = {
            name: value.name,
            nick_name: value.nick_name,
            title: value.title,
            job_title: value.job_title,
            job_category: {
                code: value.job_category,
            },
            phones: value.phones.map((phone) => ({
                country_code: phone.country_code,
                number: phone.number,
            })),
            emails: value.emails,
            birth_date: formattedDate ?? '',
            working_address: value.working_address,
            organisations: value.organisations.map((org) => ({
                code: org,
            })),
            status: payloadStatus,
        }

        try {
            let response: true | undefined
            if (isCreate) {
                response = await submitContact(payload)
                await getTabItems(fetchParam)
            } else {
                response = await updateContact(code ?? '', payload)
                await getTabItems(fetchParam)
            }

            setIsSubmitLoading(false)
            if (response) {
                modalService.closeModalHandling()
                formik.resetForm()
                await loadData({ ...fetchParam, page: 1, size: 50 })
            }
        } catch (error) {
            setIsSubmitLoading(false)
            console.log(error)
        }
    }

    const removeContact = async (data: IContact) => {
        try {
            setIsSubmitLoading(true)
            const response = await deleteContact(data.code ?? '')

            setIsSubmitLoading(false)
            if (response) {
                modalService.closeModalHandling()
                formik.resetForm()
                await getTabItems(fetchParam)
                await loadData({ ...fetchParam, page: 1, size: 50 })
            }
        } catch (error) {
            setIsSubmitLoading(false)
            console.error(error)
        }
    }

    // can't be used cause required emails. need a separate backend to update status
    const updateContactStatus = async (data: IContact) => {
        const { code } = data

        const payloadStatus = 'INACTIVE'

        setIsSubmitLoading(true)
        const payload: IUpdateStatusPayload = {
            status: payloadStatus,
        }

        try {
            let response: true | undefined

            response = await updateStatus(code ?? '', payload)

            setIsSubmitLoading(false)
            if (response) {
                modalService.closeModalHandling()
                formik.resetForm()
                await loadData({ ...fetchParam, page: 1, size: 50 })
            }
        } catch (error) {
            setIsSubmitLoading(false)
            console.log(error)
        }
    }

    const resetHandling = () => {
        formik.setValues(
            selectedValue ?? contactValidation.generateInitialValue,
        )
    }

    const exportLoadData = async (filter: IFetchParams): Promise<void> => {
        const params = { ...filter, fileExportType: undefined }
        const fileExportType = filter.fileExportType
        const response = await getContacts(params, true)
        if (!response) {
            setLoading(false)
            return
        }
        const data = response.data
        const date = moment().format('DD-MM-YYYY HH:mm')
        const generateReport = generateSimpleReport({
            headers: contactSummaryHeader as ITableColumn[],
            data: data,
            fileName: `Contact_${date}`,
            title: 'Contact Report',
        })

        if (fileExportType === 'xls') {
            generateReport.exportToXLS()
        } else if (fileExportType === 'pdf') {
            generateReport.exportToPDF({
                jsPDFOptions: {
                    orientation: 'l',
                    unit: 'px',
                    format: 'a4',
                },
                options: {
                    margin: { top: 5, right: 5, bottom: 5, left: 5 }, // Set padding to 5px
                    columnStyles: {
                        0: { cellWidth: 30 }, // 'Custom Column'
                        1: { cellWidth: 100 },
                    },
                },
            })
        }
        return
    }

    useEffect(() => {
        loadData({ ...fetchParam, page: 1, size: 50 })
    }, [fetchParam])

    useEffect(() => {
        dropdownService.getOrganisations()
        dropdownService.getJobCategories()
        dropdownService.getAgentOrganisations()
        dropdownService.getContactTypes()
        dropdownService.getCountries()
    }, [])

    return {
        contactMeta,
        contactData,
        setTabItems,
        tabItems,
        selectedTabItem,
        fetchParam,
        loading,
        filterOverlay,
        modalService,
        exportModalService,
        exportLoading,
        isSubmitLoading,
        formik,
        isEdit,
        isCreate,
        exportLoadData,
        removeContact,
        setIsEdit,
        setCreate,
        navigate,
        loadData,
        setTabFilter,
        handleSearch,
        handlePagination,
        handleFilter,
        setValue,
        resetHandling,
        setExportLoading,
        updateContactStatus,
    }
}

export default useContact

import useDropdown from 'common/dropdown/dropdown.service'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import {
    containersSelector,
    setContainersData,
    setFetchContainers,
    shipmentDetailSelector,
} from '../detail-shipment.slice'
import { useFormik } from 'formik'
import { useModal } from '@components/modal/modal.service'
import {
    deleteContainer,
    fetchContainers,
    submitContainers,
    updateContainers,
} from 'repository/containers.repository'
import {
    IFormContainer,
    IFormContainerPayload,
    useContainerValidation,
} from 'form-validation/container.validation'
import { IContainers } from 'repository/interface/containers.interface'

export const useContainers = () => {
    const dropdownService = useDropdown()
    const dispatch = useAppDispatch()

    // States
    const [isLoading, setIsLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [isNew, setIsNew] = useState(true)

    // Selector
    const containers = useSelector(containersSelector)
    const shipmentDetailData = useSelector(shipmentDetailSelector)

    // Variable
    const { data, isFetchNew } = containers
    const modalService = useModal()

    // Formik
    const containerService = useContainerValidation()
    const formikContainers = useFormik<IFormContainer>({
        initialValues: containerService.initialValue,
        validationSchema: containerService.schema,
        validateOnChange: true,
        onSubmit: (values) => {
            submit(values)
        },
    })

    const getContainers = async () => {
        if (!shipmentDetailData?.code) return
        if (!isFetchNew) return
        setIsLoading(true)
        const response = await fetchContainers(shipmentDetailData.code)
        if (!response?.data?.length) {
            dispatch(setContainersData([]))
        } else {
            dispatch(setContainersData(response.data))
        }

        setIsLoading(false)
        dispatch(setFetchContainers(false))
    }

    const resetForm = () => {
        formikContainers.setValues(containerService.initialValue)
    }

    const getPayloadUnitValue = (value: string | undefined) => {
        if (value === undefined) return undefined
        if (value === '0') return undefined
        return { code: value }
    }
    const getPayloadQtyValue = (value: string | undefined) => {
        if (value === undefined) return undefined
        if (value === '0') return undefined
        return value
    }

    const submit = async (value: IFormContainer) => {
        const code = shipmentDetailData.code ?? ''
        const containerCode = value.containerCode ?? ''

        const payload: IFormContainerPayload = {
            container_number: value.container_number,
            container_type: {
                code: value?.container_type,
            },
            seal_number: value.seal_number,
            tare_weight: getPayloadQtyValue(value.tare_weight.qty),
            tare_weight_unit: getPayloadUnitValue(value.tare_weight.unit),
            delivery_mode: value.delivery_mode,
        }

        setSubmitLoading(true)
        let response: true | undefined
        if (isNew) {
            response = await submitContainers(code, payload)
        } else {
            response = await updateContainers(code, containerCode, payload)
        }
        if (!response) {
            setSubmitLoading(false)
            return
        }

        resetForm()
        modalService.closeModalHandling()
        setSubmitLoading(false)
        getContainers()
    }

    const selectRow = (value: IContainers) => {
        const newValue: IFormContainer = {
            container_number: value.container_number ?? '',
            container_type: value.container_type?.code ?? '',
            seal_number: value.seal_number ?? '',
            tare_weight: {
                qty: value.tare_weight?.toString() ?? '0',
                unit: value.tare_weight_unit?.code ?? '',
                unitLabel: value.tare_weight_unit?.label ?? '',
            },
            delivery_mode: value.delivery_mode ?? '',
            code: value.code ?? '',
        }
        formikContainers.setValues(newValue)
        modalService.openModalHandling()
    }

    const deleteItem = async () => {
        const code = shipmentDetailData.code ?? ''
        const containerCode = formikContainers.getFieldProps('code').value
        setDeleteLoading(true)
        await deleteContainer(code, containerCode)
        setDeleteLoading(false)
        resetForm()
        modalService.closeModalHandling()
        dispatch(setFetchContainers(true))
        getContainers()
    }

    useEffect(() => {
        getContainers()
    }, [isFetchNew, shipmentDetailData])

    useEffect(() => {
        dropdownService.getContainerType()
        dropdownService.getWeightUnit()
        dropdownService.getDeliveryMode()
    }, [])

    return {
        dropdownService,
        modalService,
        isLoading,
        data,
        formikContainers,
        isNew,
        submitLoading,
        deleteLoading,
        deleteItem,
        setIsNew,
        resetForm,
        submit,
        selectRow,
    }
}

import TabMaster from '@components/tab-master/tab-master.component'
import Table from '@components/table/table.component'
import Button from '@components/button/button.component'
import { IShipment } from 'repository/interface/shipment.interface'
import useShipments from './shipments.service'
import { ShipmentType } from './shipments.interface'
import ShipmentsExportModal from './components/filter/shipments-export.component'
import ShipmentsFilterOverlay from './components/filter/shipments-filter-overlay.component'
import { useNavigate } from 'react-router-dom'

const Shipments = (shipmentType: ShipmentType) => {
    const {
        tabItems,
        shipmentsMeta,
        shipmentsData,
        selectedTabItem,
        loading,
        fetchParam,
        exportModalService,
        exportLoading,
        filterOverlay,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
        getShipmentColumn,
        setExportLoading,
        exportLoadData,
        handleFilter,
    } = useShipments(shipmentType)
    const nav = useNavigate()
    const rowOptionComponent = (
        rowData: IShipment,
        closeHandling: () => void,
    ) => (
        <div className="w-full">
            <Button
                onClick={() => {
                    closeHandling()
                }}
                className="!w-full border-none text-left"
                variant={'default'}
                label="Update Status"
                icon="ri-pencil-line"
            />
            <Button
                onClick={() => {
                    closeHandling()
                }}
                className="!w-full border-none text-left"
                variant={'default'}
                label="Generate SI"
                icon="ri-download-2-line"
            />
            <Button
                onClick={() => {
                    closeHandling()
                }}
                className="!w-full border-none text-left themes-text-red-v3"
                variant={'default'}
                label="Cancel Shipment"
                icon="ri-close-line"
            />
        </div>
    )

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)] pt-0">
                <TabMaster
                    items={tabItems || []}
                    tabFilter={selectedTabItem}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search Shipment ID'}
                    containerSearchClassName={'flex-1'}
                    initialSearch={fetchParam.search}
                    onSearchSubmit={(values) => handleSearch(values)}
                    iconButton1={{
                        onClick: () => filterOverlay.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <ShipmentsFilterOverlay
                                filterOverlayService={filterOverlay}
                                onFilterSubmit={(data) => {
                                    console.log(data)
                                    handleFilter(data)
                                }}
                            />
                        ),
                    }}
                    actionButton1={{
                        onClick: () => {
                            navigate(
                                `/freight-forwarding/${shipmentType.toLowerCase()}/create`,
                            )
                        },
                        label: 'CREATE SHIPMENT',
                        className: '!text-size-S',
                    }}
                />

                <Table<IShipment>
                    headers={getShipmentColumn()}
                    data={shipmentsData}
                    loading={loading}
                    nextHandling={(page) => {
                        handlePagination(page)
                    }}
                    previousHandling={(page) => {
                        handlePagination(page)
                    }}
                    meta2={shipmentsMeta}
                    moduleTitle={'Inquiry'}
                    enableExport={true}
                    exportModalService={exportModalService}
                    rowOptionComponent={rowOptionComponent}
                    onRowClick={(data) => {
                        nav('/freight-forwarding/details/' + data.code)
                    }}
                />
            </div>

            <ShipmentsExportModal
                modalService={exportModalService}
                onSubmit={async (data) => {
                    setExportLoading(true)
                    await exportLoadData(data)
                    setExportLoading(false)
                    exportModalService.closeModalHandling()
                }}
                isLoading={exportLoading}
            />
        </div>
    )
}

export default Shipments

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect, useState } from 'react';
export function useTable(_a) {
    var data = _a.data, headers = _a.headers, checkboxVisible = _a.checkboxVisible, checkboxDataHandling = _a.checkboxDataHandling, tabFilterItem = _a.tabFilterItem, resetCheckedInitialValue = _a.resetCheckedInitialValue;
    // initial
    var _b = useState(''), sortBy = _b[0], setSortBy = _b[1];
    var _c = useState('asc'), sortOrder = _c[0], setSortOrder = _c[1];
    var _d = useState([]), generatedData = _d[0], setGeneratedData = _d[1];
    var _e = useState([]), checkedData = _e[0], setCheckedData = _e[1];
    var _f = useState(false), isCheckedAll = _f[0], setIsCheckedAll = _f[1];
    // useEffect ---------------------------------------------------------------
    useEffect(function () {
        if (!tabFilterItem) {
            return;
        }
        setGeneratedData(generateData(generatedData));
    }, []);
    useEffect(function () {
        // setIsCheckedAll(false)
        setGeneratedData(generateData(data));
    }, [data]);
    useEffect(function () {
        setCheckedData(resetCheckedInitialValue !== null && resetCheckedInitialValue !== void 0 ? resetCheckedInitialValue : []);
    }, [resetCheckedInitialValue]);
    // End useEffect -----------------------------------------------------------
    // additional function -----------------------------------------------------
    function generateData(dt) {
        if ((typeof dt === 'object' && !Array.isArray(dt)) || !dt) {
            return [];
        }
        var dt_ = dt;
        if (tabFilterItem) {
            if (tabFilterItem.value === 'all') {
                dt_ = data.filter(function (d) {
                    return d;
                });
            }
            else {
                dt_ = data.filter(function (d) {
                    if (d[tabFilterItem.key] === tabFilterItem.value) {
                        return d;
                    }
                });
            }
        }
        if (checkboxVisible) {
            setIsCheckedAll(false);
            dt_ = dt_.map(function (dt__) {
                var newObj = Object.assign({ selected: false }, dt__);
                return newObj;
            });
        }
        return dt_;
    }
    function setSortColumn(accessor, isAscending) {
        var sortOrder = !isAscending ? 'asc' : 'desc';
        var sortedData = [];
        setSortOrder(sortOrder);
        setSortBy(accessor);
        if (!isAscending) {
            sortedData = __spreadArray([], generatedData, true).sort(function (a, b) {
                return a[accessor] > b[accessor] ? 1 : -1;
            });
        }
        else {
            sortedData = __spreadArray([], generatedData, true).sort(function (a, b) {
                return a[accessor] < b[accessor] ? 1 : -1;
            });
        }
        setGeneratedData(sortedData);
    }
    function checkboxAllHandling(isChecked) {
        var checkedData_ = [];
        var generatedData_ = [];
        if (isChecked) {
            generatedData_ = generatedData.map(function (dt) {
                return __assign(__assign({}, dt), { selected: true });
            });
            checkedData_ = generatedData.map(function (dt) {
                // eslint-disable-next-line no-unused-vars
                var selected = dt.selected, values = __rest(dt, ["selected"]);
                return values;
            });
        }
        else {
            generatedData_ = generatedData.map(function (dt) {
                return __assign(__assign({}, dt), { selected: false });
            });
        }
        setIsCheckedAll(isChecked);
        setCheckedData(checkedData_);
        setGeneratedData(generatedData_);
        checkboxDataHandling && checkboxDataHandling(checkedData_);
    }
    function checkboxSingleHandling(isChecked, data, index) {
        var checkedData_ = __spreadArray([], checkedData, true);
        var generatedData_ = __spreadArray([], generatedData, true);
        if (isChecked) {
            generatedData_[index] = __assign(__assign({}, data), { selected: true });
            setGeneratedData(generatedData_);
            var values = __rest(data, []);
            checkedData_.push(values);
        }
        else {
            generatedData_[index] = __assign(__assign({}, data), { selected: false });
            setGeneratedData(generatedData_);
            var idx = checkedData_.findIndex(function (d) { return (d === null || d === void 0 ? void 0 : d.id) === (data === null || data === void 0 ? void 0 : data.id); });
            checkedData_.splice(idx, 1);
        }
        setCheckedData(checkedData_);
        checkboxDataHandling && checkboxDataHandling(checkedData_);
    }
    // end function -------------------------------------------------
    // return function or variable
    var generatedHeaders = headers;
    return {
        generatedData: generatedData,
        generatedHeaders: generatedHeaders,
        sortBy: sortBy,
        sortOrder: sortOrder,
        setSortColumn: setSortColumn,
        checkboxSingleHandling: checkboxSingleHandling,
        checkboxAllHandling: checkboxAllHandling,
        checkedData: checkedData,
        isCheckedAll: isCheckedAll,
        setIsCheckedAll: setIsCheckedAll,
    };
}

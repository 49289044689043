import { IFetchParams, IResponseData } from 'common/common.interface'
import { IChargeCode } from './interface/charge-code.interface'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import {
    deleteRequest,
    errorAxiosToast,
    fetch,
    post,
    put,
} from 'common/common.service'
import { IFChargeCode } from 'form-validation/charge-code.validation'
import { Toast } from '@components/toast/toast.component'

export const getChargeCode = async (
    params: IFetchParams,
    showErrorToast?: boolean,
) => {
    try {
        const endpoint = `${endpoints.chargeCodes}`
        const response = await fetch<
            IResponseData<IChargeCode[]>,
            IFetchParams
        >({
            endpoint: endpoint,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Charge Code',
            })
        }
        return undefined
    }
}

export const submitChargeCode = async (payload: IFChargeCode) => {
    try {
        const endpoint = `${endpoints.chargeCodes}`
        await post<IResponseData<any>, IFChargeCode>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'Charge Code is created',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Create Charge Code',
        })
        return undefined
    }
}

export const updateChargeCode = async (values: IFChargeCode) => {
    try {
        const { code, ...payload } = values
        const endpoint = `${endpoints.chargeCodes}/${code}`
        await put<IResponseData<any>, IFChargeCode>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'Charge Code is updated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed update Charge Code',
        })
        return undefined
    }
}

export const deleteChargeCode = async (code: string) => {
    try {
        const endpoint = `${endpoints.chargeCodes}/${code}`
        await deleteRequest({
            endpoint: endpoint,
        })

        Toast({
            header: 'Delete Success',
            message: 'Charge Code is deleted',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed delete Charge Code',
        })
        return undefined
    }
}

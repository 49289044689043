import useDropdown from 'common/dropdown/dropdown.service'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import { useFormik } from 'formik'
import { useModal } from '@components/modal/modal.service'
import {
    dataSelector,
    fetchParamSelector,
    metaSelector,
    setData,
    setFetchParam,
} from './item-list.slice'
import { IFetchParams } from 'common/common.interface'
import {
    IFItemLists,
    IFItemListsPayload,
    useItemListsValidation,
} from 'form-validation/item-list.validation'
import {
    deleteItemList,
    getItemList,
    submitItemList,
    updateItemList,
} from 'repository/item-list.repository'
import { IItemList, IOrderItem } from 'repository/interface/item-list.interface'
import { shipmentDetailSelector } from '../detail-shipment.slice'
import moment from 'moment'

export const useItemLists = () => {
    const dropdownService = useDropdown()
    const dispatch = useAppDispatch()
    const modalService = useModal()

    // States
    const [isLoading, setIsLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [isNew, setIsNew] = useState(true)

    // Selector
    const data = useSelector(dataSelector)
    const meta = useSelector(metaSelector)
    const fetchParams = useSelector(fetchParamSelector)
    const shipmentDetailData = useSelector(shipmentDetailSelector)

    // Formik
    const validation = useItemListsValidation()
    const formik = useFormik<IFItemLists>({
        initialValues: validation.initialValue,
        validationSchema: validation.schema,
        validateOnChange: true,
        onSubmit: (values) => {
            submit(values)
        },
    })

    const getData = async (params: IFetchParams, shipmentCode: string) => {
        setIsLoading(true)
        const response = await getItemList(params, shipmentCode)

        if (!response || !response.data) {
            setIsLoading(false)
            return []
        }
        const updatedData = response.data.map(
            (order: IItemList, index: number) => {
                const updatedItems = order.items.map(
                    (item: IOrderItem, itemIndex: number) => ({
                        ...item,
                        number: itemIndex + 1,
                    }),
                )
                return {
                    ...order,
                    number: index + 1,
                    items: updatedItems,
                }
            },
        )

        const updatedResponse = {
            ...response,
            data: updatedData,
        }

        dispatch(setData(updatedResponse))
        setIsLoading(false)
    }

    const resetForm = () => {
        formik.setValues(validation.initialValue)
    }

    const submit = async (value: IFItemLists) => {
        let response: true | undefined
        const code = shipmentDetailData.code ?? ''
        const orderCode = formik.getFieldProps('order_code').value
        const payload: IFItemListsPayload = {
            supplier: { code: value.supplier },
            name: value.name,
            note: value.note,
            discount:
                value.discount?.unit === 'PERCENTAGE'
                    ? Number(value.discount?.qty) / 100
                    : undefined,
            discount_amount:
                value.discount?.unit === 'AMOUNT'
                    ? Number(value.discount?.qty)
                    : undefined,
            date: moment(value.date).format('YYYY-MM-DD HH:mm:ss'),
            order_status: value.order_status,
            total_amount: Number(value.total_amount?.qty),
            currency: value?.amount?.unit
                ? {
                      code: value?.amount?.unit ?? '',
                  }
                : undefined,
        }

        if (isNew) {
            response = await submitItemList(code, payload)
        } else {
            response = await updateItemList(code, orderCode, payload)
        }

        if (!response) {
            setSubmitLoading(false)
            return
        }
        resetForm()
        modalService.closeModalHandling()
        setSubmitLoading(false)
        getData(fetchParams, shipmentDetailData.code || '')
    }

    const selectRow = (value: IItemList) => {
        const totalAmount: number = value.items.reduce(
            (sum, item) => sum + item.total_amount,
            10000,
        )

        const newValue: IFItemLists = {
            supplier: value.supplier.code,
            name: value?.name,
            note: value?.note,
            total_amount: {
                qty: value.total_amount?.toString() ?? '0',
                unit: value?.currency?.code,
                unitLabel: value?.currency?.symbol,
            },
            amount: {
                qty: totalAmount?.toString() ?? '0',
                unit: value?.currency?.code,
                unitLabel: value?.currency?.symbol,
            },
            discount: {
                qty: value?.discount
                    ? (Number(value.discount) * 100).toString()
                    : value?.discount_amount
                      ? value.discount_amount.toString()
                      : undefined,
                unit: value?.discount
                    ? 'PERCENTAGE'
                    : value?.discount_amount
                      ? 'AMOUNT'
                      : undefined,
                unitLabel: value?.discount
                    ? 'Percentage'
                    : value?.discount_amount
                      ? 'Amount'
                      : undefined,
            },
            date: value?.date ?? '',
            order_status: value?.order_status,
            order_code: value.code ?? '',
            order_id: value?.order_id ? value?.order_id : 'auto generated',
        }

        formik.setValues(newValue)
        setIsNew(false)
        modalService.openModalHandling()
    }

    const deleteData = async (orderId?: string) => {
        const code = shipmentDetailData.code ?? ''
        const resolvedOrderId =
            orderId || formik.getFieldProps('order_code').value

        try {
            setDeleteLoading(true)
            await deleteItemList(code, resolvedOrderId)
            resetForm()
            modalService.closeModalHandling()
            getData(fetchParams, shipmentDetailData.code || '')
        } catch (error) {
            console.error('Failed to delete item:', error)
        } finally {
            setDeleteLoading(false)
        }
    }

    const handleSearch = (search: string) => {
        dispatch(
            setFetchParam({
                page: 1,
                search: search,
            }),
        )
    }

    useEffect(() => {
        getData(fetchParams, shipmentDetailData.code || '')
    }, [fetchParams])

    useEffect(() => {
        dropdownService.getOrderStatus()
        dropdownService.getOrganisationSupplier()
        dropdownService.getCurrency()
        dropdownService.getDocumentType()
    }, [])

    return {
        dropdownService,
        modalService,
        isLoading,
        data,
        formik,
        isNew,
        submitLoading,
        deleteLoading,
        meta,
        fetchParams,
        deleteData,
        setIsNew,
        resetForm,
        submit,
        selectRow,
        handleSearch,
    }
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { IShipment } from 'repository/interface/shipment.interface'
import { IPackLines } from 'repository/interface/packlines.interface'
import { IContainers } from 'repository/interface/containers.interface'
import { INotes } from 'repository/interface/notes.interface'
import { IEDoc } from 'repository/interface/edocs.interface'
interface IShipmentDetailSlice {
    detailShipment: IShipment
    packLines: { data: IPackLines[]; isFetchNew: boolean }
    containers: { data: IContainers[]; isFetchNew: boolean }
    notes: { data: INotes[]; isFetchNew: boolean }
    edocs: { data: IEDoc[]; isFetchNew: boolean }
}

export const initialState: IShipmentDetailSlice = {
    detailShipment: {},
    packLines: {
        data: [],
        isFetchNew: true,
    },
    containers: {
        data: [],
        isFetchNew: true,
    },
    notes: {
        data: [],
        isFetchNew: true,
    },
    edocs: {
        data: [],
        isFetchNew: true,
    },
}

const shipmentDetailSlice = createSlice({
    name: 'shipmentDetail',
    initialState,
    reducers: {
        setShipmentDetailData(state, action: PayloadAction<IShipment>) {
            const data = action.payload
            return { ...state, detailShipment: data }
        },
        setPackLinesData(state, action: PayloadAction<IPackLines[]>) {
            const data = action.payload
            return {
                ...state,
                packLines: {
                    isFetchNew: state.packLines.isFetchNew,
                    data: data,
                },
            }
        },
        setFetchPackLines(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return {
                ...state,
                packLines: {
                    isFetchNew: data,
                    data: state.packLines.data,
                },
            }
        },
        setContainersData(state, action: PayloadAction<IContainers[]>) {
            const data = action.payload
            return {
                ...state,
                containers: {
                    isFetchNew: state.packLines.isFetchNew,
                    data: data,
                },
            }
        },
        setFetchContainers(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return {
                ...state,
                containers: {
                    isFetchNew: data,
                    data: state.containers.data,
                },
            }
        },
        setNotesData(state, action: PayloadAction<INotes[]>) {
            const data = action.payload
            return {
                ...state,
                notes: {
                    isFetchNew: state.notes.isFetchNew,
                    data: data,
                },
            }
        },
        setFetchNotes(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return {
                ...state,
                notes: {
                    isFetchNew: data,
                    data: state.notes.data,
                },
            }
        },
        setEdocsData(state, action: PayloadAction<IEDoc[]>) {
            const data = action.payload
            return {
                ...state,
                edocs: {
                    isFetchNew: state.edocs.isFetchNew,
                    data: data,
                },
            }
        },
        setFetchEdocs(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return {
                ...state,
                edocs: {
                    isFetchNew: data,
                    data: state.edocs.data,
                },
            }
        },
    },
})

// these all the variables used for selector
export const shipmentDetailSelector = (state: RootState) =>
    state.shipmentDetail.detailShipment || {}
export const packLinesSelector = (state: RootState) =>
    state.shipmentDetail.packLines || {}
export const containersSelector = (state: RootState) =>
    state.shipmentDetail.containers || {}
export const notesSelector = (state: RootState) =>
    state.shipmentDetail.notes || {}
export const eDocsSelector = (state: RootState) =>
    state.shipmentDetail.edocs || {}

// all actions
export const {
    setShipmentDetailData,
    setPackLinesData,
    setFetchPackLines,
    setContainersData,
    setFetchContainers,
    setFetchNotes,
    setNotesData,
    setEdocsData,
    setFetchEdocs,
} = shipmentDetailSlice.actions

export default shipmentDetailSlice.reducer

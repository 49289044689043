import Table from '@components/table/table.component'
import { packLinesHeader } from './pack-lines.static'
import { usePackLines } from './pack-lines.service'
import FormInput from '@components/form-input/form-input.component'
import { FormikProvider } from 'formik'
import SimpleTable from '@components/simple-table/simple-table.component'
import DetailShipmentModal from '../detail-shipment-modal.component'
import PackLinesForm from './pack-lines-form.component'

const PackLines = () => {
    const {
        data,
        isLoading,
        formikPackLines,
        formikTotal,
        modalService,
        isNew,
        submitLoading,
        deleteLoading,
        deleteItem,
        resetForm,
        selectRow,
        setIsNew,
    } = usePackLines()
    return (
        <div className="flex flex-col gap-6 h-full overflow-auto">
            <div className="h-[calc(100vh-310px)] border-b ">
                <SimpleTable
                    containerClassName="overflow-auto"
                    data={data}
                    loading={isLoading}
                    headers={packLinesHeader}
                    isBorder={false}
                    showHeaderOnEmpty={true}
                    headerCostum={'themes-bg-gray-v1'}
                    additionalStickyHeader={true}
                    additionalStickyHeaderClassname=""
                    additionalStickyHeaderComponent={
                        <div
                            className="!w-[60px] flex justify-center items-center h-full row-container border-l"
                            onClick={() => {
                                modalService.openModalHandling()
                                resetForm()
                                setIsNew(true)
                            }}
                        >
                            <i className="ri-add-line" />
                            ADD
                        </div>
                    }
                    onRowClick={(value) => {
                        selectRow(value)
                        setIsNew(false)
                    }}
                />
            </div>
            <div className="w-full">
                <FormikProvider value={formikTotal}>
                    <div className="flex sm:flex-wrap gap-4 sm:gap-2 px-4">
                        <FormInput
                            parentDivClassName="w-full mb-0"
                            name="total_lines"
                            label="TOTAL Lines"
                            disabled={true}
                        />
                        <FormInput
                            parentDivClassName="w-full mb-0"
                            name="total_packs"
                            label="TOTAL PACKS"
                            disabled={true}
                        />
                        <FormInput
                            parentDivClassName="w-full mb-0"
                            name="total_weight"
                            label="TOTAL WEIGHT"
                            disabled={true}
                        />
                        <FormInput
                            parentDivClassName="w-full mb-0"
                            name="total_volume"
                            label="TOTAL VOLUME"
                            disabled={true}
                        />
                    </div>
                </FormikProvider>
            </div>

            <DetailShipmentModal
                form={
                    <PackLinesForm
                        formik={formikPackLines}
                        submitLoading={submitLoading}
                        deleteHandling={() => {
                            deleteItem()
                        }}
                        deleteLoading={deleteLoading}
                        isNew={isNew}
                    />
                }
                modalService={modalService}
                title={isNew ? 'Create New Pack Line' : 'Pack Line: 12345'}
            />
        </div>
    )
}

export default PackLines

import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { useModal } from '@components/modal/modal.service'
import { Toast } from '@components/toast/toast.component'
import useDropdown from 'common/dropdown/dropdown.service'
import {
    IFUser,
    userFormInitial,
    userFormValidation,
} from 'form-validation/user.validation'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getRegion } from 'repository/common.repository'
import { userDetailDataSelector } from '../user-access-detail/user-access-detail.slice'
import { IUserDetail, IUserUpdate } from 'repository/interface/user.interface'
import { createUser, updateUser } from 'repository/user.repository'

import useUserAccess from '../user-access.service'

export interface IUseUserAccessForm {
    isNew: boolean
    isProfilePage: boolean
}
export const useUserAccessForm = ({ isNew }: IUseUserAccessForm) => {
    const { refreshSummary } = useUserAccess()
    const nav = useNavigate()
    const { id } = useParams()
    const userDetailData = useSelector(userDetailDataSelector)
    const [province, setProvince] = useState<IDropdownItem[]>([])
    const [city, setCity] = useState<IDropdownItem[]>([])
    const moduleModalService = useModal()
    const dropdownService = useDropdown()

    const dataToFormik = (data: IUserDetail) => {
        const newData: IFUser = {
            fullName: data.name,
            job_title: data.job_title,
            job_category: data.job_category.code,
            email: data.email,
            user_name: data.user_name,
            role: data.role.code,
            site: data.site.code,
            country: '',
            state: '',
            city: '',
            status: data.status,
            phone: data.phone,
            mobile: data.mobile,
            emergency_contact_name: data.emergency_contact_name,
            emergency_contact_phone: data.emergency_contact_phone,
        }
        return newData
    }
    const formikToPayload = (data: IFUser) => {
        const newData: IUserUpdate = {
            name: data.fullName,
            job_title: data.job_title,
            job_category: { code: data.job_category },
            email: data.email,
            status: data.status,
            role: { code: data.role },
            site: { code: data.site },
            last_name: '',
            first_name: '',
            phone: data.phone,
            mobile: data.mobile,
            emergency_contact_name: data.emergency_contact_name,
            emergency_contact_phone: data.emergency_contact_phone,
            user_name: data.user_name,
        }
        return newData
    }

    const formikModule = useFormik<IFUser>({
        validationSchema: userFormValidation,
        initialValues: isNew ? userFormInitial : dataToFormik(userDetailData),
        onSubmit: async (values) => {
            let response
            if (isNew) {
                response = await createUser(formikToPayload(values), false)
            } else {
                response = await updateUser(id, formikToPayload(values), false)
            }

            if (!response) {
                const fullname = values.fullName
                const message = isNew
                    ? `Failed to create a User.`
                    : `"${fullname}" failed to Updated.`
                Toast({
                    header: 'Failed!',
                    message: message,
                    type: 'error',
                })
                return
            }
            const fullname = values.fullName
            const title = isNew
                ? `User "${fullname}" Created`
                : `User "${fullname}" Updated`
            const message = isNew
                ? `"${fullname}" has been successfully Created.`
                : `"${fullname}" has been successfully Updated.`
            Toast({
                header: title,
                message: message,
                type: 'success',
            })
            refreshSummary()
        },
    })

    const getProvinceDropdown = async () => {
        // const region = dropdownService.getStates()
        const region = getRegion()
        const dropdown: IDropdownItem[] = region.map((d) => {
            return { label: d.province, value: d.province } as IDropdownItem
        })
        setProvince(dropdown)
    }

    const getCityDropdown = async (selectedProvince: string) => {
        const region = getRegion()
        const province = region.find((d) => d.province === selectedProvince)
        if (!province) {
            setCity([])
            return
        }

        const dropdown = province.city.map((d) => {
            return { label: d, value: d } as IDropdownItem
        })
        setCity(dropdown)
    }

    // Return ----------------------------------------------------------------------
    return {
        formikModule,
        province,
        city,
        moduleModalService,
        dropdownService,
        refreshSummary,
        nav,
        getCityDropdown,
        getProvinceDropdown,
    }
}

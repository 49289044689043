import { useNavigate } from 'react-router-dom'
import SettingsButton from './component/settings-button.component'
import StaticDataModal from './component/static-data-modal.component'
import { useSettings, useStaticDataService } from './settings.services'

export default function Settings() {
    const nav = useNavigate()
    const { isAccordion1Open, isAccordion2Open, handlingAccordion } =
        useSettings()
    const { modalService, isLoading, title, data, formik, getDataHandling } =
        useStaticDataService()

    const icon1 = isAccordion1Open
        ? 'ri-arrow-up-s-line'
        : 'ri-arrow-down-s-line'
    const icon2 = isAccordion2Open
        ? 'ri-arrow-up-s-line'
        : 'ri-arrow-down-s-line'

    return (
        <div className="flex flex-col rounded-lg shadow-md themes-bg-white container-global content-full-height">
            <div className="overflow-auto">
                <div
                    className="flex p-4 gap-4 border-b cursor-pointer"
                    onClick={() => {
                        handlingAccordion(1)
                    }}
                >
                    <div className="flex items-center">
                        <i className="text-size-XL ri-settings-3-line"></i>
                    </div>
                    <div className="flex flex-col flex-grow gap-[0.2rem]">
                        <p className="text-size-M">
                            Freight Forwarding Settings
                        </p>
                        <p className="text-size-S">
                            Manage app settings and preferences
                        </p>
                    </div>
                    <div className="flex items-center">
                        <i
                            className={`${icon1} text-size-XL cursor-pointer`}
                        ></i>
                    </div>
                </div>

                <div
                    className={`
                    p-4 mobile:!grid-cols-1 sm:grid-cols-2 md:grid-cols-3 grid lg:grid-cols-4 gap-4 sm:gap-2 themes-bg-gray-v2
                    overflow-hidden transition-all duration-800 ease-in-out
                   ${isAccordion1Open ? 'max-h-100 opacity-100' : 'max-h-0 opacity-0 !py-0'}
                `}
                >
                    <SettingsButton
                        icon="ri-list-settings-fill"
                        label="Charge Codes"
                        subLabel="Manage Charge Code"
                        onClick={() => {
                            nav('/charge-code')
                        }}
                    />
                    <SettingsButton
                        icon="ri-list-settings-fill"
                        label="Service Rates"
                        subLabel="Manage Service Rate"
                        onClick={() => {
                            nav('/service-rate')
                        }}
                    />
                    <SettingsButton
                        icon="ri-list-settings-fill"
                        label="Shipping Rates"
                        subLabel="Manage Shipping Rates"
                        onClick={() => {
                            nav('/shipping-rate')
                        }}
                    />
                    <SettingsButton
                        icon="ri-list-settings-fill"
                        label="Product Category"
                        subLabel="Manage Product Category"
                        onClick={() => {
                            nav('/product-category')
                        }}
                    />
                    <SettingsButton
                        icon="ri-list-settings-fill"
                        label="HS Code"
                        subLabel="Manage HS Code"
                        onClick={() => {
                            nav('/hs-code')
                        }}
                    />
                    <SettingsButton
                        icon="ri-list-settings-fill"
                        label="UN/Locode"
                        subLabel="Manage UN/Locode"
                        onClick={() => {
                            nav('/unlocodes')
                        }}
                    />
                </div>

                <div
                    className="flex p-4 gap-4 border-b border-t cursor-pointer"
                    onClick={() => {
                        handlingAccordion(2)
                    }}
                >
                    <div className=" text-size-XL flex items-center">
                        <i className="ri-database-2-line"></i>
                    </div>
                    <div className="flex flex-col flex-grow gap-[0.2rem]">
                        <p className="text-size-M">Static Data</p>
                        <p className="text-size-S">
                            View all of the static data
                        </p>
                    </div>
                    <div className="flex items-center">
                        <i
                            className={`${icon2} text-size-XL cursor-pointer`}
                        ></i>
                    </div>
                </div>

                <div
                    className={`
                    p-4 mobile:!grid-cols-1 sm:grid-cols-2 md:grid-cols-3 grid lg:grid-cols-4 gap-4 sm:gap-2 themes-bg-gray-v2
                    overflow-hidden transition-all duration-800 ease-in-out
                   ${isAccordion2Open ? 'max-h-100 opacity-100' : 'max-h-0 opacity-0 !py-0'}
                `}
                >
                    <SettingsButton
                        icon="ri-database-2-line"
                        label="Transport Mode"
                        subLabel="View Transport Mode"
                        onClick={() => {
                            getDataHandling('transport_mode')
                        }}
                    />
                    <SettingsButton
                        icon="ri-database-2-line"
                        label="Container Mode"
                        subLabel="View List of Container Mode"
                        onClick={() => {
                            getDataHandling('container_mode')
                        }}
                    />
                    <SettingsButton
                        icon="ri-database-2-line"
                        label="Order Status"
                        subLabel="View List of Order Status"
                        onClick={() => {
                            getDataHandling('order_status')
                        }}
                    />
                    <SettingsButton
                        icon="ri-database-2-line"
                        label="Shipment Status"
                        subLabel="View List of Shipment Status"
                        onClick={() => {
                            getDataHandling('shipment_status')
                        }}
                    />
                    <SettingsButton
                        icon="ri-database-2-line"
                        label="Booking Status"
                        subLabel="View List of Booking Status"
                        onClick={() => {
                            getDataHandling('booking_status')
                        }}
                    />
                    <SettingsButton
                        icon="ri-database-2-line"
                        label="Note Category"
                        subLabel="View List of Note Category"
                        onClick={() => {
                            getDataHandling('note_category')
                        }}
                    />
                </div>
            </div>

            <StaticDataModal
                loadingData={isLoading}
                modalService={modalService}
                title={title}
                data={data}
                formik={formik}
            />
        </div>
    )
}

import { header } from './edocs.static'
import SimpleTable from '@components/simple-table/simple-table.component'
import DetailShipmentModal from '../detail-shipment-modal.component'
import { useEdocs } from './edocs.service'
import EDocsForm from './edocs-form.component'

const Edocs = () => {
    const {
        data,
        isLoading,
        formikEdocs,
        modalService,
        isNew,
        submitLoading,
        deleteLoading,
        deleteItem,
        resetForm,
        selectRow,
        setIsNew,
        setSelectedFile,
    } = useEdocs()

    return (
        <div className="flex flex-col gap-6 h-full overflow-auto">
            <div className="h-[calc(100vh-310px)] border-b ">
                <SimpleTable
                    containerClassName="overflow-auto"
                    data={data}
                    loading={isLoading}
                    headers={header}
                    isBorder={false}
                    showHeaderOnEmpty={true}
                    headerCostum={'themes-bg-gray-v1'}
                    additionalStickyHeader={true}
                    additionalStickyHeaderClassname=""
                    additionalStickyHeaderComponent={
                        <div
                            className="!w-[60px] flex justify-center items-center h-full row-container border-l"
                            onClick={() => {
                                modalService.openModalHandling()
                                resetForm()
                                setIsNew(true)
                            }}
                        >
                            <i className="ri-add-line" />
                            ADD
                        </div>
                    }
                    onRowClick={(value) => {
                        selectRow(value)
                        setIsNew(false)
                    }}
                />
            </div>

            <DetailShipmentModal
                form={
                    <EDocsForm
                        formik={formikEdocs}
                        deleteHandling={() => {
                            deleteItem()
                        }}
                        deleteLoading={deleteLoading}
                        submitLoading={submitLoading}
                        isNew={isNew}
                        selectFileHandling={(e) => {
                            const file = e.target.files?.[0] ?? undefined
                            if (file) {
                                setSelectedFile(file)
                            }
                        }}
                    />
                }
                modalService={modalService}
                title={isNew ? 'Upload New Edocs' : 'Update Edocs'}
            />
        </div>
    )
}

export default Edocs

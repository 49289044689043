import * as Yup from 'yup'
import { requiredMessage } from './validation-message.static'
import { IContact } from 'repository/interface/contact.interface'
import { userDataSelector } from 'pages/login/login.slice'
import { useSelector } from 'react-redux'
import { IUserAuth } from 'repository/interface/user-auth.interface'
import moment from 'moment'

export interface IFormBooking {
    booking_id: string
    created_by: string
    created: string
    updated: string
    status: string
    transport_mode: 'AIR' | 'SEA' | ''
    shipper: string
    shipper_address: string
    shipper_address_detail: string
    include_pick_up: boolean
    consignee: string
    consignee_address: string
    consignee_address_detail: string
    with_delivery: boolean
    etd: string
    eta: string
    origin_location: string
    origin_location_country: string
    origin_location_state: string
    destination_location: string
    destination_location_country: string
    destination_location_state: string
    agent?: IContact
    notify_contacts: IContact[]
}

export interface IFormBookingPayload {
    transport_mode: 'AIR' | 'SEA' | ''
    shipper: { code: string }
    shipper_address: { code: string }
    include_pick_up: boolean
    consignee: { code: string }
    consignee_address: { code: string }
    with_delivery: boolean
    etd: string
    eta: string
    origin_location: { code: string }
    destination_location: { code: string }
    agent?: { code: string }
    notify_contacts?: { code: string }[]
    booking_id?: string
    created_by?: string
    created_at?: string
    status: string
}

export const useBookingValidation = () => {
    const user: IUserAuth = useSelector(userDataSelector)
    const schema = Yup.object<IFormBooking>().shape({
        booking_id: Yup.string(),
        transport_mode: Yup.string().required(
            requiredMessage('Transport Mode'),
        ),
        shipper: Yup.string().required(requiredMessage('Shipper')),
        shipper_address: Yup.string().required(
            requiredMessage('Shipper Address'),
        ),
        include_pick_up: Yup.string(),
        consignee: Yup.string(),
        consignee_address: Yup.string().required(
            requiredMessage('Consignee Address'),
        ),
        with_delivery: Yup.string(),
        etd: Yup.string().required(requiredMessage('ETD')),
        eta: Yup.string().required(requiredMessage('ETA')),
        origin_location: Yup.string(),
        origin_location_country: Yup.string(),
        origin_location_state: Yup.string(),
        destination_location: Yup.string().required(
            requiredMessage('Destination Location'),
        ),
        destination_location_country: Yup.string(),
        destination_location_state: Yup.string(),
        agent: Yup.object()
            .shape({
                code: Yup.string().nullable(),
                email: Yup.string(),
                phone: Yup.string(),
            })
            .nullable(),
        notify_contacts: Yup.array().of(
            Yup.object().shape({
                code: Yup.string().required('Please select contact to notify'),
                email: Yup.string(),
                phone: Yup.string(),
            }),
        ),
    })

    const generateInitialValue = () => {
        const initialValue: IFormBooking = {
            transport_mode: 'AIR',
            shipper: '',
            shipper_address: '',
            include_pick_up: false,
            consignee: '',
            consignee_address: '',
            with_delivery: false,
            etd: '',
            eta: '',
            origin_location: '',
            destination_location: '',
            agent: {
                code: '',
                name: '',
                entity_status: '',
                emails: [],
                organisations: [],
            },
            notify_contacts: [],
            booking_id: '(auto generated)',
            created_by: user.fullName,
            created: moment().format('DD/MM/YYYY'),
            status: 'OPEN',
            origin_location_country: '',
            origin_location_state: '',
            destination_location_country: '',
            destination_location_state: '',
            shipper_address_detail: '',
            consignee_address_detail: '',
            updated: moment().format('DD/MM/YYYY'),
        }
        return initialValue
    }

    return {
        generateInitialValue,
        schema,
    }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import SpinnerTable from '@components/spinner-table/spinner-table.component';
import { TableExpandableRow } from './row/table-expandable-row.component';
import { useTableExpandable } from './table-expandable.services';
import './table-expandable.style.css';
import EmptyResult from '@components/empty-result/empty-result.component';
import Pagination from '@components/pagination/pagination.component';
function TableExpandable(_a) {
    var props = __rest(_a, []);
    var _b = useTableExpandable(props), data = _b.data, header = _b.header, headerChild = _b.headerChild, childAccessor = _b.childAccessor;
    // Handling data loading
    var loadingMessage = "Loading ".concat(props.moduleTitle, " Data . . .");
    var notFoundMessage = "No data found";
    if (props.loading === true)
        return _jsx(SpinnerTable, { message: loadingMessage });
    if (props.data.length <= 0)
        return _jsx(EmptyResult, { message: notFoundMessage });
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "table-expandable h-full w-full  flex flex-col overflow-auto" }, { children: _jsx("div", __assign({ className: "flex-1 ".concat(!data.length && 'flex') }, { children: _jsxs("table", __assign({ className: "w-full table-auto" }, { children: [_jsx("thead", __assign({ className: "thead-expandable" }, { children: _jsxs("tr", { children: [header.map(function (data, idx) {
                                            var showLabel = data.showLabel === undefined
                                                ? true
                                                : data.showLabel;
                                            var width = data.width
                                                ? data.width + 'px'
                                                : '';
                                            var minWidth = data.minWidth
                                                ? data.minWidth + 'px'
                                                : '';
                                            return (_jsx("th", __assign({ className: "th-expandable", style: {
                                                    width: width,
                                                    minWidth: minWidth,
                                                } }, { children: _jsx("div", __assign({ className: "".concat((data === null || data === void 0 ? void 0 : data.headerClassName) || '') }, { children: !showLabel ? null : data.label }), data.accessor) }), 'header-' + idx));
                                        }), props.addItemHandling && (_jsx("th", __assign({ className: "th-expandable sticky right-0 pn flex justify-end items-center cursor-pointer themes-bg-white", onClick: function () {
                                                props.addItemHandling &&
                                                    props.addItemHandling();
                                            }, colSpan: props.rowOptionComponent
                                                ? 1
                                                : undefined }, { children: _jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx("i", { className: "ri-add-line" }), "ADD"] })) }), 'header-button'))] }) })), _jsxs("tbody", { children: [data.length < 1 ? (_jsx("tr", { children: _jsx("td", __assign({ colSpan: 11.5 }, { children: _jsx("div", __assign({ className: "w-full h-full flex justify-center items-center" }, { children: notFoundMessage })) })) })) : (_jsx(_Fragment, {})), data.map(function (row, idx) {
                                        return (_jsx(TableExpandableRow, { index: idx, data: row, headerParent: header, headerChild: headerChild, childAccessor: childAccessor, addChildRowHandling: function () {
                                                var _a;
                                                return props.addChildRowHandling &&
                                                    props.addChildRowHandling((_a = row['id']) !== null && _a !== void 0 ? _a : '');
                                            }, highlightOnExpand: props.highlightOnExpand, rowOptionComponent: props.rowOptionComponent, rowChildrenOptionComponent: props.rowChildrenOptionComponent, addItemHandling: props.addItemHandling, parentChildSameHeader: props.parentChildSameHeader, onParentClick: props.onParentClick }, 'row-' + idx + (row === null || row === void 0 ? void 0 : row.id)));
                                    })] })] })) })) })), props.usePagination && (_jsxs("div", __assign({ className: "border-t themes-border-color flex items-center justify-between" }, { children: [_jsx(Pagination, { meta: props.meta, meta2: props.meta2, meta3: props.meta3, previousHandling: function (page) {
                            (props === null || props === void 0 ? void 0 : props.previousHandling) &&
                                props.previousHandling(page);
                        }, nextHandling: function (page) {
                            (props === null || props === void 0 ? void 0 : props.nextHandling) && props.nextHandling(page);
                        } }), _jsx("div", __assign({ className: "m-2 flex gap-2" }, { children: props.additionalButtonBottom }))] })))] }));
}
export default TableExpandable;

function FileItem() {
    return (
        <div className="flex flex-row justify-between items-center w-full py-3 border-b border-[#D9DDE1]">
            <div className="flex flex-row gap-3 w-full">
                <i className="ri-file-line text-[2rem] text-[#00B0F2]"></i>
                <div className="flex flex-col gap-1">
                    <p className="text-size-S">Photono1.jpg</p>
                    <p className="text-size-XS text-[#8D99A5]">25 KB</p>
                </div>
            </div>
            <button>
                <i className="ri-download-2-line text-[1.5rem] text-[#5B6C7C]"></i>
            </button>
        </div>
    )
}

export default FileItem

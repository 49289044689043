import Button from '@components/button/button.component'
import { IItemList, IOrderItem } from 'repository/interface/item-list.interface'

export const ParentOption = ({
    rowData,
    closeHandling,
    openModalEdit,
    deleteHandling,
    deleteLoading,
}: {
    rowData: IItemList
    closeHandling: () => void
    openModalEdit: (data: IItemList) => void
    deleteHandling: () => void
    deleteLoading: boolean
}) => (
    <div className="w-full ">
        <Button
            onClick={() => {
                closeHandling()
                openModalEdit(rowData)
            }}
            className="!w-full border-none text-left themes-text-gray-v6"
            variant={'default'}
            label="Edit Order"
            icon="ri-pencil-line"
        />
        <Button
            onClick={() => {
                console.log('Delete button clicked, rowData:', rowData)
                closeHandling()
                deleteHandling()
            }}
            className="!w-full border-none text-left"
            variant={'red-inverse'}
            label="Remove Order"
            isLoading={deleteLoading}
            icon="ri-delete-bin-line"
        />
    </div>
)

export const ChildrenOption = ({
    rowData,
    closeHandling,
    openModalEdit,
    deleteHandling,
    deleteLoading,
}: {
    rowData: IOrderItem
    closeHandling: () => void
    openModalEdit: (data: IOrderItem) => void
    deleteHandling: () => void
    deleteLoading: boolean
}) => (
    <div className="w-full ">
        <Button
            onClick={() => {
                closeHandling()
                openModalEdit(rowData)
            }}
            className="!w-full border-none text-left themes-text-gray-v6"
            variant={'default'}
            label="Edit Item"
            icon="ri-pencil-line"
        />
        <Button
            onClick={() => {
                closeHandling()
                deleteHandling()
            }}
            className="!w-full border-none text-left"
            variant={'red-inverse'}
            label="Remove Item"
            isLoading={deleteLoading}
            icon="ri-delete-bin-line"
        />
    </div>
)

import { IFetchParams, IResponseData } from 'common/common.interface'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import {
    deleteRequest,
    errorAxiosToast,
    fetch,
    post,
    put,
} from 'common/common.service'
import { Toast } from '@components/toast/toast.component'
import { IItemList, IOrderItem } from './interface/item-list.interface'
import { IFOrderItemsPayload } from 'form-validation/order-item.validation'

export const getOrderItem = async (
    shipmentCode: string,
    shipmentOrderCode: string,
    shipmentOrderItemCode: string,
    showErrorToast?: boolean,
) => {
    try {
        const endpoint = `shipments/${shipmentCode}/${endpoints.order}/${shipmentOrderCode}/${endpoints.item}/${shipmentOrderItemCode}`
        const response = await fetch<IResponseData<IOrderItem>, IFetchParams>({
            endpoint: endpoint,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Orders',
            })
        }
        return undefined
    }
}

export const getItemList = async (
    params: IFetchParams,
    shipmentCode: string,
    showErrorToast?: boolean,
) => {
    try {
        const endpoint = `shipments/${shipmentCode}/${endpoints.order}/`
        const response = await fetch<IResponseData<IItemList[]>, IFetchParams>({
            endpoint: endpoint,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Orders',
            })
        }
        return undefined
    }
}

export const submitOrderItem = async (
    shipmentCode: string,
    shipmentOrderCode: string,
    payload: IFOrderItemsPayload,
) => {
    try {
        const endpoint = `${endpoints.shipments}/${shipmentCode}/orders/${shipmentOrderCode}/${endpoints.item}`
        await post<IResponseData<any>, IFOrderItemsPayload>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'Order item created',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Create Order item',
        })
        return undefined
    }
}

export const updateOrderItem = async (
    shipmentCode: string,
    shipmentOrderCode: string,
    shipmentOrderItemCode: string,
    payload: IFOrderItemsPayload,
) => {
    try {
        const endpoint = `${endpoints.shipments}/${shipmentCode}/orders/${shipmentOrderCode}/${endpoints.item}/${shipmentOrderItemCode}`
        await put<IResponseData<any>, IFOrderItemsPayload>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'Order item is updated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed update order item',
        })
        return undefined
    }
}

export const deleteOrderItem = async (
    shipmentCode: string,
    shipmentOrderCode: string,
    shipmentOrderItemCode: string,
) => {
    try {
        const endpoint = `${endpoints.shipments}/${shipmentCode}/orders/${shipmentOrderCode}/items/${shipmentOrderItemCode}`
        await deleteRequest({
            endpoint: endpoint,
        })

        Toast({
            header: 'Delete Success',
            message: 'Order is deleted',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed delete Order',
        })
        return undefined
    }
}

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useEffect, useState } from 'react';
export function useTableExpandableRow(_a) {
    var _b;
    var data = _a.data, props = __rest(_a, ["data"]);
    var _c = useState(false), isExpand = _c[0], setIsExpand = _c[1];
    var childData = (_b = data[props.childAccessor]) !== null && _b !== void 0 ? _b : [];
    var _d = useState(childData.length), currentChildLength = _d[0], setCurrentChildLength = _d[1];
    // handling expand if new data was added
    useEffect(function () {
        if (childData.length > currentChildLength) {
            setIsExpand(true);
        }
        setCurrentChildLength(childData.length);
    }, [childData.length]);
    // when delete
    useEffect(function () {
        if (childData.length > currentChildLength) {
            setIsExpand(true);
        }
        setCurrentChildLength(childData.length);
    }, [childData.length]);
    // Recursion function for print value of nested object 
    function printValue(obj, accessor, index) {
        var key = accessor === null || accessor === void 0 ? void 0 : accessor[index];
        var newObj = obj === null || obj === void 0 ? void 0 : obj[key];
        if (obj === undefined) {
            console.log('ERROR PRINT VALUE :', accessor, index, obj);
            return '-';
        }
        if (typeof obj === 'object') {
            return printValue(newObj, accessor, index + 1);
        }
        else {
            return obj;
        }
    }
    return {
        data: data,
        isExpand: isExpand,
        childData: childData,
        setIsExpand: setIsExpand,
        printValue: printValue
    };
}

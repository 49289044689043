var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect, useState } from 'react';
export function useTableExpandable(_a) {
    var data = _a.data, headerChild = _a.headerChild, headerParent = _a.headerParent, childAccessor = _a.childAccessor;
    var _b = useState([]), header = _b[0], setHeader = _b[1];
    //remove child accessor in header
    useEffect(function () {
        var dt = headerParent.filter(function (d) {
            if (d.accessor === childAccessor)
                return false;
            return true;
        });
        var expandableColumn = {
            accessor: 'expandable-button-icon',
            showLabel: false,
            label: '',
            width: 10,
        };
        setHeader(__spreadArray([expandableColumn], dt, true));
    }, []);
    return {
        childAccessor: childAccessor,
        data: data,
        headerChild: headerChild,
        headerParent: headerParent,
        header: header,
    };
}

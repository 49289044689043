import { header, tabItem } from './unlocodes.static'
import Table from '@components/table/table.component'
import TabMaster from '@components/tab-master/tab-master.component'
import { useUnlocodes } from './unlocodes.service'
import { IUnlocodes } from 'repository/interface/unlocodes.interface'

const Unlocodes = () => {
    const { data, isLoading, meta, fetchParams, handleSearch } = useUnlocodes()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)] pt-0">
                <TabMaster
                    items={tabItem}
                    tabFilter={tabItem[0]}
                    onChange={() => {}}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search By Name'}
                    containerSearchClassName={'flex-1'}
                    initialSearch={fetchParams.search}
                    onSearchSubmit={(values) => handleSearch(values)}
                />

                <Table<IUnlocodes>
                    headers={header}
                    data={data}
                    loading={isLoading}
                    nextHandling={(page) => {}}
                    previousHandling={(page) => {}}
                    meta2={meta}
                    moduleTitle={'Product Code'}
                    enableExport={false}
                />
            </div>
        </div>
    )
}

export default Unlocodes

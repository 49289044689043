import Button from '@components/button/button.component'
import FormInput from '@components/form-input/form-input.component'
import { IFProductCategory } from 'form-validation/product-category.validation'
import { FormikProps, FormikProvider } from 'formik'

const ProductCategoryForm = ({
    formik,
    submitLoading,
    deleteHandling,
    deleteLoading,
    isNew,
}: {
    formik: FormikProps<IFProductCategory>
    deleteHandling: () => void
    submitLoading: boolean
    deleteLoading: boolean
    isNew: boolean
}) => {
    return (
        <div className="flex flex-col h-full">
            <div className={`flex-col overflow-auto h-[calc(100vh-240px)] p-4`}>
                <FormikProvider value={formik}>
                    <FormInput
                        parentDivClassName="w-full"
                        name="code"
                        label="Code"
                        useUppercaseLabel={true}
                        placeholder="Input Code"
                        disabled
                    />
                    <FormInput
                        parentDivClassName="w-full"
                        name="name"
                        label="Name"
                        useUppercaseLabel={true}
                        placeholder="Input Name"
                    />
                </FormikProvider>
            </div>
            <div className="p-4 flex justify-between border-t">
                <Button
                    label="DELETE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-delete-bin-line"
                    variant="red-inverse"
                    onClick={() => {
                        deleteHandling()
                    }}
                    isLoading={deleteLoading}
                    isDisabled={isNew}
                />
                <Button
                    label="SAVE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-save-line"
                    variant="brand"
                    onClick={() => {
                        formik.submitForm()
                    }}
                    isLoading={submitLoading}
                />
            </div>
        </div>
    )
}

export default ProductCategoryForm

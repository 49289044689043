import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import useDropdown from 'common/dropdown/dropdown.service'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { ITransportMode } from 'repository/interface/transport-mode.interface'
import useOverlay from '@components/overlay/overlay.service'
import moment from 'moment'
import {
    IFormBooking,
    IFormBookingPayload,
    useBookingValidation,
} from 'form-validation/form-booking.validation'
import {
    getBookingDetail,
    submitBooking,
    updateBooking,
} from 'repository/booking.repository'
import { IContact } from 'repository/interface/contact.interface'
import {
    fetchOrganisations,
    fetchOrganisationsAddress,
} from 'repository/dropdown.repository'
import { IAddress } from 'repository/interface/address.interface'
import { IOrganisation } from 'repository/interface/organisation.interface'

const useBookingForm = (isDetailPage: boolean) => {
    const navigate = useNavigate()
    const serviceRatesOverlay = useOverlay()
    const params = useParams()
    const dropdownService = useDropdown()

    // states
    const [selectedTransport, setSelectedTransport] =
        useState<IDropdownItem<ITransportMode> | null>(null)
    const [isSubmitLoading, setIsSubmitLoading] = useState(false)
    const [isDetailLoading, setDetailLoading] = useState(false)
    const [shipperAddress, setShipperAddres] = useState<
        IDropdownItem<IAddress>[]
    >([])
    const [consigneeAddress, setConsigneeAddres] = useState<
        IDropdownItem<IAddress>[]
    >([])
    const [agents, setAgents] = useState<IDropdownItem<IOrganisation>[]>([])
    const [notify, setNotify] = useState<IDropdownItem<IOrganisation>[]>([])

    // formik
    const bookingValidation = useBookingValidation()
    const formik = useFormik<IFormBooking>({
        validationSchema: bookingValidation.schema,
        validateOnChange: true,
        initialValues: bookingValidation.generateInitialValue(),
        onSubmit: (values) => {
            submitForm(values)
        },
    })

    const submitForm = async (value: IFormBooking) => {
        setIsSubmitLoading(true)
        const payload: IFormBookingPayload = {
            transport_mode: value.transport_mode,
            shipper: {
                code: value.shipper,
            },
            shipper_address: {
                code: value.shipper_address,
            },
            include_pick_up: value.include_pick_up,
            consignee: {
                code: value.consignee,
            },
            consignee_address: {
                code: value.consignee_address,
            },
            with_delivery: false,
            etd: moment(value.etd).format('YYYY-MM-DD HH:mm:ss'),
            eta: moment(value.etd).format('YYYY-MM-DD HH:mm:ss'),
            origin_location: {
                code: value.origin_location,
            },
            destination_location: {
                code: value.destination_location,
            },
            agent: value.agent?.code ? { code: value.agent.code } : undefined,
            notify_contacts:
                value.notify_contacts.length < 1 || undefined || null
                    ? undefined
                    : value.notify_contacts.map((d) => {
                          return { code: d.code ?? '' }
                      }),
            status: value.status,
        }

        try {
            let response: true | undefined
            if (!isDetailPage) {
                response = await submitBooking(payload)
            } else {
                const code = params.id ?? ''
                response = await updateBooking(code, payload)
            }

            setIsSubmitLoading(false)
            if (response) {
                navigate('/inquiry')
                formik.resetForm()
            }
        } catch (error) {
            setIsSubmitLoading(false)
            console.log(error)
        }
    }

    const addNewNotify = () => {
        const notifyContactData = formik.values.notify_contacts
        const newContact: IContact = {
            code: '',
            name: '',
            entity_status: '',
            emails: [],
            organisations: [],
            phones: [],
        }

        formik.setValues((state) => ({
            ...state,
            notify_contacts: [...notifyContactData, newContact],
        }))
    }

    const deleteNotifyByIndex = (index: number) => {
        const notifyData = formik.values.notify_contacts
        const updateData = notifyData.filter((d, i) => index !== i)
        formik.setValues((state) => ({ ...state, notify_contacts: updateData }))
    }

    const getDetailData = async () => {
        const code = params.id
        setDetailLoading(true)
        if (!code) {
            setDetailLoading(false)
            navigate('/inquiry')
            return
        }
        const response = await getBookingDetail(code)
        if (!response) {
            setDetailLoading(false)
            navigate('/inquiry')
            return
        }

        const data = response.data
        const form: IFormBooking = {
            shipper_address_detail: '',
            consignee_address_detail: '',
            origin_location_country: '',
            origin_location_state: '',
            destination_location_country: '',
            destination_location_state: '',
            created: data?.created ?? '',
            booking_id: data?.booking_id ?? '',
            created_by: data?.created_by ?? '',
            updated: data?.updated ?? '',
            status: data?.status ?? '',
            transport_mode: data?.transport_mode ?? '',
            shipper: data?.shipper?.code ?? '',
            shipper_address: data?.shipper_address?.code ?? '',
            include_pick_up: data?.include_pick_up ?? false,
            consignee: data?.consignee?.code ?? '',
            consignee_address: data?.consignee_address?.code ?? '',
            with_delivery: data?.with_delivery ?? false,
            etd: data?.etd ?? '',
            eta: data?.eta ?? '',
            origin_location: data.origin_location?.code ?? '',
            destination_location: data.destination_location?.code ?? '',
            agent: {
                code: data?.agent?.code ?? '',
                name: '',
                entity_status: '',
                emails: [],
                organisations: [],
            },
            notify_contacts: data?.notify_contacts ?? [],
        }
        formik.setValues(form)
        setDetailLoading(false)
    }

    const addressHandling = async (
        selectedCode: string,
        type: 'shipper' | 'consignee',
    ) => {
        const response = await fetchOrganisationsAddress({
            organisationCode: selectedCode,
        })
        if (!response) return

        const dropdown: IDropdownItem<IAddress>[] = response.data.map((d) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        if (type === 'consignee') {
            setConsigneeAddres(dropdown)
        } else {
            setShipperAddres(dropdown)
        }
    }

    const getAgent = async () => {
        const response = await fetchOrganisations('agents')
        if (!response) {
            setAgents([])
            return
        }

        const dropdown: IDropdownItem<IOrganisation>[] = response.data.map(
            (d) => {
                return {
                    label: `${d.name}`,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        setAgents(dropdown)
    }

    const getNotifies = async () => {
        const response = await fetchOrganisations('notifies')
        if (!response) {
            setNotify([])
            return
        }

        const dropdown: IDropdownItem<IOrganisation>[] = response.data.map(
            (d) => {
                return {
                    label: `${d.name}`,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        setNotify(dropdown)
    }

    useEffect(() => {
        if (isDetailPage) {
            getDetailData()
        }
    }, [])
    useEffect(() => {
        dropdownService.getUnlocos()
        dropdownService.getShipperOrganisations()
        dropdownService.getConsigneeOrganisations()
        dropdownService.getOrganisations()
        dropdownService.getBookingStatuses()
        getNotifies()
        getAgent()
    }, [])

    useEffect(() => {
        if (!selectedTransport) return
        dropdownService.getContainerModes(selectedTransport.value as string)
    }, [selectedTransport])

    return {
        addressHandling,
        navigate,
        deleteNotifyByIndex,
        addNewNotify,
        setSelectedTransport,
        getNotifies,
        getAgent,
        submitForm,
        formik,
        dropdownService,
        serviceRatesOverlay,
        isSubmitLoading,
        shipperAddress,
        consigneeAddress,
        agents,
        notify,
        isDetailLoading,
    }
}

export default useBookingForm

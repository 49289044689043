import { IFetchParams, IResponseData } from 'common/common.interface'
import {
    deleteRequest,
    errorAxiosToast,
    fetch,
    post,
    put,
} from 'common/common.service'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import { Toast } from '@components/toast/toast.component'
import { IContainers } from './interface/containers.interface'
import { IFormContainerPayload } from 'form-validation/container.validation'

export const fetchContainers = async (
    shipmentCode: string,
    showErrorToast?: boolean,
) => {
    try {
        const response = await fetch<
            IResponseData<IContainers[]>,
            IFetchParams
        >({
            endpoint: `${endpoints.shipments}/${shipmentCode}/containers`,
            params: {},
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Bookings Data',
            })
        }
        return undefined
    }
}

export const submitContainers = async (
    code: string,
    payload: IFormContainerPayload,
) => {
    try {
        const endpoint = `${endpoints.shipments}/${code}/containers`
        await post<IResponseData<any>, IFormContainerPayload>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'Container is created',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Create Container',
        })
        return undefined
    }
}

export const updateContainers = async (
    code: string,
    containerCode: string,
    payload: IFormContainerPayload,
) => {
    try {
        const endpoint = `${endpoints.shipments}/${code}/containers/${containerCode}`
        await put<IResponseData<any>, IFormContainerPayload>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'Container is updated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed update Container',
        })
        return undefined
    }
}

export const deleteContainer = async (code: string, containerCode: string) => {
    try {
        const endpoint = `${endpoints.shipments}/${code}/containers/${containerCode}`
        await deleteRequest({
            endpoint: endpoint,
        })

        Toast({
            header: 'Delete Success',
            message: 'Container is deleted',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed delete Container',
        })
        return undefined
    }
}

import { ITabItem } from '@components/tab/tab.interface'

export const shipmentDetailTabs: ITabItem[] = [
    {
        label: 'Details',
        key: 'detail',
        value: 'details',
        className: 'brand',
    },
    {
        label: 'Item List',
        key: 'item_list',
        value: 'item_list',
        className: 'brand',
    },
    {
        label: 'Pack Lines',
        key: 'pack_lines',
        value: 'pack_lines',
        className: 'brand',
    },
    {
        label: 'Containers',
        key: 'containers',
        value: 'containers',
        className: 'brand',
    },
    {
        label: 'Notes',
        key: 'notes',
        value: 'notes',
        className: 'brand',
    },
    {
        label: 'eDocumentation',
        key: 'eDocumentation',
        value: 'eDocumentation',
        className: 'brand',
    },
]

export const additionalInfoTabs: ITabItem[] = [
    {
        label: 'Charges',
        key: 'additional_info',
        value: 'charges',
        className: 'brand',
    },
    {
        label: 'Contact Info',
        key: 'additional_info',
        value: 'contact_info',
        className: 'brand',
    },
    // {
    //     label: 'Goods Info',
    //     key: 'additional_info',
    //     value: 'goods_info',
    //     className: 'brand',
    // },
]

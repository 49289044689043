import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'

export const header: ITableColumn[] = [
    { accessor: 'name', label: 'Name', width: 10 },
    { accessor: 'charge_code', label: 'Charge Code', width: 10 },
    { accessor: 'gl_accrual', label: 'GL Accrual', width: 10 },
    { accessor: 'gl_cost', label: 'GL Cost', width: 10 },
    { accessor: 'gl_revenue', label: 'GL Revenue', width: 10 },
    { accessor: 'gl_wip', label: 'GL WIP', width: 10 },
    { accessor: 'price', label: 'Price', width: 10 },
    { accessor: 'unit.label', label: 'Unit', minWidth: 10 },
]

export const tabItem: ITabItem[] = [
    { label: 'All Charge Code', value: 'all', key: 'status' },
]

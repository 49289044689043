import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { ISTColumn } from '@components/simple-table/simple-table.interface'
import { ITableColumn } from '@components/table/table.interface'

export const staticDataTableHeader: ITableColumn<IDropdownItem>[] = [
    {
        accessor: 'value',
        label: 'Code',
        width: 140,
    },
    {
        accessor: 'label',
        label: 'Description',
    },
]

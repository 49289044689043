import Button from '@components/button/button.component'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import FormCheckbox from '@components/form-checkbox/form-checkbox.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import useDropdown from 'common/dropdown/dropdown.service'
import { IFChargeCode } from 'form-validation/charge-code.validation'
import { FormikProps, FormikProvider } from 'formik'
import { IUnit } from 'repository/interface/unit.interface'

const ChargeCodeForm = ({
    formik,
    submitLoading,
    deleteHandling,
    deleteLoading,
    isNew,
}: {
    formik: FormikProps<IFChargeCode>
    deleteHandling: () => void
    submitLoading: boolean
    deleteLoading: boolean
    isNew: boolean
}) => {
    const dropdownService = useDropdown()
    const dollarIcon = (
        <div className="flex items-center mb-3 px-4 themes-bg-gray-v4 themes-text-white">
            $
        </div>
    )
    return (
        <div className="flex flex-col h-full">
            <div className={`flex-col overflow-auto h-[calc(100vh-240px)] p-4`}>
                <FormikProvider value={formik}>
                    <div className="flex gap-4">
                        <FormInput
                            parentDivClassName="w-full"
                            name="charge_code"
                            label="Charge Code"
                            useUppercaseLabel={true}
                            placeholder="Input Charge Code"
                        />
                        <FormInput
                            parentDivClassName="w-full"
                            name="name"
                            label="Name"
                            useUppercaseLabel={true}
                            placeholder="Input Name"
                        />
                    </div>

                    <FormInput
                        parentDivClassName="w-full flex-grow"
                        name="gl_accrual"
                        label="GL Accrual"
                        useUppercaseLabel={true}
                        placeholder="Input GL Accrual"
                        className="!rounded-l-none"
                    />
                    <FormInput
                        parentDivClassName="w-full"
                        name="gl_cost"
                        label="GL Cost"
                        useUppercaseLabel={true}
                        placeholder="Input GL Cost"
                        className="!rounded-l-none"
                    />
                    <FormInput
                        parentDivClassName="w-full"
                        name="gl_revenue"
                        label="GL Revenue"
                        useUppercaseLabel={true}
                        placeholder="Input GL Revenue"
                        className="!rounded-l-none"
                    />
                    <FormInput
                        parentDivClassName="w-full"
                        name="gl_wip"
                        label="GL WIP"
                        useUppercaseLabel={true}
                        placeholder="Input GL WIP"
                        className="!rounded-l-none"
                    />

                    <div className="flex gap-2">
                        <div className="flex w-full">
                            <FormInput
                                parentDivClassName="w-full"
                                name="price"
                                label="Price Per Unit"
                                useUppercaseLabel={true}
                                placeholder="Input Price Per Unit"
                                className="!rounded-l-none"
                                type="number"
                            />
                        </div>
                        <FormDropdown
                            parentDivClassname="w-full"
                            name="unit.code"
                            options={dropdownService.volumeUnitsData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="Unit"
                            placeholder="Select Volume Unit"
                            additionalOnClick={(v) => {
                                const value = v as
                                    | IDropdownItem<IUnit>
                                    | undefined
                                if (value === undefined) return undefined
                                return { code: value }
                            }}
                        />
                    </div>
                    <FormCheckbox
                        label="Allow Override"
                        name="allow_override"
                        useUppercaseLabel={true}
                    />
                </FormikProvider>
            </div>
            <div className="p-4 flex justify-between border-t">
                <Button
                    label="DELETE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-delete-bin-line"
                    variant="red-inverse"
                    onClick={() => {
                        deleteHandling()
                    }}
                    isLoading={deleteLoading}
                    isDisabled={isNew}
                />
                <Button
                    label="SAVE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-save-line"
                    variant="brand"
                    onClick={() => {
                        formik.submitForm()
                    }}
                    isLoading={submitLoading}
                />
            </div>
        </div>
    )
}

export default ChargeCodeForm

import { configureStore } from '@reduxjs/toolkit'
import { AnyAction, combineReducers, Store } from 'redux'
import { persistReducer } from 'redux-persist'
import { persistStore } from 'redux-persist'
import thunk, { ThunkDispatch } from 'redux-thunk'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import storage from 'redux-persist/lib/storage'
import commonReducer from 'common/common.slice'
import dropdownReducer from 'common/dropdown/dropdown.slice'
import organisationReducer from '../pages/organisation/organisation.slice'
import loginReducer, {
    initialState as initialStateLogin,
} from '../pages/login/login.slice'
import userReducer, {
    initialState as initialStateUser,
} from '../pages/user-acces/user-access.slice'
import userDetailReducer, {
    initialState as initialStateUserDetail,
} from '../pages/user-acces/user-access-detail/user-access-detail.slice'
import configReducer from '../config/config.slice'
import layoutReducer from '../layout/layout.slice'
import bookingReducer, {
    initialState as initialBookings,
} from '../pages/bookings/bookings.slice'
import quotesReducerLimajari, {
    initialState as initialStateQuotesLimajari,
} from 'pages/quotes/quotes.slice'
import contactReducer, {
    initialState as initialContact,
} from '../pages/contact/contact.slice'
import shipmentReducer, {
    initialState as initialStateShipment,
} from 'pages/shipments/shipments.slice'
import shipmentDetailReducer, {
    initialState as initialStateShipmentDetail,
} from 'pages/shipments/form/detail-shipment/detail-shipment.slice'
import settingsReducer, {
    initialState as initialSettings,
} from 'pages/settings/settings.slice'
import chargeCodeReducer, {
    initialState as initialChargeCode,
} from 'pages/charge-code/charge-code.slice'
import productCategoryReducer, {
    initialState as initialProductCategory,
} from 'pages/product-category/product-category.slice'
import hsCodeReducer, {
    initialState as initialHsCode,
} from 'pages/hs-code/hs-code.slice'
import unlocodesReducer, {
    initialState as initialUnlocodes,
} from 'pages/unlocodes/unlocodes.slice'
import serviceRateReducer, {
    initialState as initialServiceRate,
} from 'pages/service-rate/service-rate.slice'
import itemListReducer, {
    initialState as initialItemList,
} from 'pages/shipments/form/detail-shipment/item-list/item-list.slice'
import shippingRateReducer, {
    initialState as initialShippingRate,
} from 'pages/shipping-rate/shipping-rate.slice'

const appReducer = combineReducers({
    // common
    common: commonReducer,
    login: loginReducer,
    user: userReducer,
    userDetail: userDetailReducer,
    config: configReducer,
    layout: layoutReducer,
    dropdown: dropdownReducer,
    organisation: organisationReducer,
    settings: settingsReducer,

    // limajari stores
    quotes: quotesReducerLimajari,
    booking: bookingReducer,
    contact: contactReducer,
    shipment: shipmentReducer,
    shipmentDetail: shipmentDetailReducer,
    chargeCode: chargeCodeReducer,
    productCategory: productCategoryReducer,
    hsCode: hsCodeReducer,
    unlocodes: unlocodesReducer,
    serviceRate: serviceRateReducer,
    itemList: itemListReducer,
    shippingRate: shippingRateReducer,
})

export const rootReducer = (
    state: ReturnType<typeof appReducer> | undefined,
    action: AnyAction,
): ReturnType<typeof appReducer> => {
    if (action.type === 'RESET_STORE') {
        if (state) {
            state = {
                ...state,
                login: initialStateLogin,
                user: initialStateUser,
                userDetail: initialStateUserDetail,
                // limajari
                quotes: initialStateQuotesLimajari,
                booking: initialBookings,
                contact: initialContact,
                shipment: initialStateShipment,
                shipmentDetail: initialStateShipmentDetail,
                settings: initialSettings,
                chargeCode: initialChargeCode,
                productCategory: initialProductCategory,
                hsCode: initialHsCode,
                unlocodes: initialUnlocodes,
                serviceRate: initialServiceRate,
                itemList: initialItemList,
                shippingRate: initialShippingRate,
            }
        }
    }
    return appReducer(state, action)
}
const key = 'loglines_store'

const persistConfig = {
    key,
    storage,
    whitelist: ['config', 'login', 'common', 'settings'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const devTools = process.env.NODE_ENV !== 'production'
export const store = configureStore({
    reducer: persistedReducer,
    devTools,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoreActions: true,
            },
        }).concat(thunk),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppThunkDispatch = ThunkDispatch<RootState, unknown, AnyAction>
export type AppStore = Omit<Store<RootState, AnyAction>, 'dispatch'> & {
    dispatch: AppThunkDispatch
}

export type AppDispatch = ThunkDispatch<string, any, AnyAction>

// Create the typed versions of the useDispatch and useSelector Hooks
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// reset store
export const resetStore = () => store.dispatch({ type: 'RESET_STORE' })

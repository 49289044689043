import moment from 'moment';
import { useEffect } from 'react';
import i18n from '../config/config-i18n.service';
export var formatText = function (text) {
    return text
        .replace(/_/g, ' ')
        .toLowerCase()
        .split(' ')
        .map(function (word) { return word.charAt(0).toUpperCase() + word.slice(1); })
        .join(' ');
};
export var formatDate = function (dateString, typeFormat) {
    var date = moment(dateString);
    // check invalid date
    if (!date.isValid() || !dateString) {
        return '';
    }
    var formattedDate = date.format(typeFormat ? typeFormat : 'DD/MM/YYYY');
    return formattedDate;
};
export var toCamelCase = function (str) {
    // Split kata-kata berdasarkan spasi atau underscore
    var words = str.split(/\s|_/);
    // Ubah setiap kata menjadi camelCase
    var camelCaseWords = words.map(function (word, index) {
        // Pada kata pertama, gunakan huruf kecil
        if (index === 0) {
            return word.toLowerCase();
        }
        // Pada kata lainnya, gunakan huruf besar di awal
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    // Gabungkan kata-kata menjadi satu string
    return camelCaseWords.join('');
};
export var revertToTitleCase = function (str) {
    // Breaks a string into an array of words
    var words = str.match(/[A-Za-z][a-z]*/g) || [];
    // Combine words with spaces and change the first letter of each word to uppercase
    return words
        .map(function (word) { return word.charAt(0).toUpperCase() + word.slice(1); })
        .join(' ');
};
export var capitalizeFirstLetter = function (str) {
    var words = str.split(/\s|_/);
    var CapitalizedFirstLetter = words.map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return CapitalizedFirstLetter.join(' ');
};
export var numberWithCommas = function (x) {
    return x === null || x === void 0 ? void 0 : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export var removeUnderscores = function (text) { return text.replace(/_/g, ' '); };
export var openEmailClient = function (emailAddress) {
    var subject = 'Subject';
    var body = '--- Body ---';
    var mailtoUrl = 'mailto:' +
        encodeURIComponent(emailAddress) +
        '?subject=' +
        encodeURIComponent(subject) +
        '&body=' +
        encodeURIComponent(body);
    window.open(mailtoUrl);
};
export function useOutsideClickHandling(ref, func) {
    useEffect(function () {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                func();
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return function () {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}
// copy text to clipboard
export var copyTextToClipboard = function (id, iconClassNames) {
    if (iconClassNames === void 0) { iconClassNames = ''; }
    // Mengambil elemen berdasarkan ID
    var getContainer = document.getElementById(id);
    // Mengambil nilai teks dari elemen div
    var inputElement = getContainer
        ? getContainer.querySelector('input')
        : null;
    var textValue = inputElement
        ? inputElement.value
        : getContainer === null || getContainer === void 0 ? void 0 : getContainer.textContent;
    // Menyalin teks ke clipboard
    navigator.clipboard
        .writeText(textValue)
        .then(function () {
        // Mengubah ikon menjadi tanda centang setelah berhasil disalin ke clipboard
        var iconElement = getContainer === null || getContainer === void 0 ? void 0 : getContainer.querySelector('.ri-file-copy-line');
        console.log(iconElement);
        if (iconElement) {
            iconElement.className = "".concat(iconClassNames, " ri-check-line");
            // Setelah 1.5 detik, kembalikan ikon ke ikon semula
            setTimeout(function () {
                iconElement.className = "".concat(iconClassNames, " ri-file-copy-line cursor-pointer");
            }, 1500);
        }
    })
        .catch(function (err) {
        console.error('Failed to copy text: ', err);
    });
};
export function changeInputValueToZero(id, value) {
    // Mendapatkan referensi ke elemen input berdasarkan ID
    var myInput = document.getElementById(id);
    // Memastikan elemen ditemukan sebelum mengubah nilai
    if (myInput && value === '') {
        // Mengubah nilai elemen input
        myInput.value = '0';
    }
}
export var mapPath = function (path) {
    var _a;
    if (typeof path !== 'string')
        return [];
    var pathArray = (_a = path.split('/')) === null || _a === void 0 ? void 0 : _a.filter(function (data) { return data; });
    return pathArray;
};
export var translate = function (value, options) {
    return i18n.t(value, options);
};
export var formatDecimalNumber = function (value) {
    if (!value)
        return '0.00';
    var valueString = value.toString();
    // Periksa apakah nilai sudah memiliki titik desimal
    if (valueString.includes('.')) {
        return numberWithCommas(valueString); // Kembalikan nilai tanpa toFixed(2)
    }
    // Jika tidak ada titik desimal, gunakan toFixed(2)
    return numberWithCommas(value.toFixed(2));
};

import useDropdown from 'common/dropdown/dropdown.service'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import { useFormik } from 'formik'
import { useModal } from '@components/modal/modal.service'
import { IFetchParams } from 'common/common.interface'
import {
    dataSelector,
    fetchParamSelector,
    metaSelector,
    setData,
    setFetchParam,
} from './shipping-rate.slice'
import {
    IFShippingRate,
    useShippingRatesValidation,
} from 'form-validation/shipping-rate.validation'
import {
    deleteShippingRate,
    getShippingRate,
    submitShippingRate,
    updateShippingRate,
} from 'repository/shipping-rate.repository'
import { IShippingRate } from 'repository/interface/shipping-rate.interface'

export const useShippingRate = () => {
    const dropdownService = useDropdown()
    const dispatch = useAppDispatch()
    const modalService = useModal()

    // States
    const [isLoading, setIsLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [isNew, setIsNew] = useState(true)

    // Selector
    const data = useSelector(dataSelector)
    const meta = useSelector(metaSelector)
    const fetchParams = useSelector(fetchParamSelector)

    // Formik
    const validation = useShippingRatesValidation()
    const formik = useFormik<IFShippingRate>({
        initialValues: validation.initialValue,
        validationSchema: validation.schema,
        validateOnChange: true,
        validate(values) {
            console.log(values)
        },
        onSubmit: (values) => {
            submit(values)
        },
    })
    const { transport_mode } = formik.values

    const getData = async (params: IFetchParams) => {
        setIsLoading(true)
        const response = await getShippingRate(params)
        if (!response) {
            return
        } else {
            dispatch(setData(response))
        }

        setIsLoading(false)
    }

    const resetForm = () => {
        formik.setValues(validation.initialValue)
    }

    const getPayloadUnitValue = (value: string | undefined) => {
        if (value === undefined) return undefined
        if (value === '0') return undefined
        return { code: value }
    }

    const submit = async (value: IFShippingRate) => {
        let response: true | undefined
        if (isNew) {
            response = await submitShippingRate(value)
        } else {
            response = await updateShippingRate(value)
        }
        if (!response) {
            setSubmitLoading(false)
            return
        }

        resetForm()
        modalService.closeModalHandling()
        setSubmitLoading(false)
        getData(fetchParams)
    }

    const selectRow = (value: IShippingRate) => {
        const newValue: IFShippingRate = {
            code: value.code,
            name: value.name,
            transport_mode: value.transport_mode,
            container_mode: value.container_mode,
            origin_location: { code: value.origin_location.code },
            destination_location: { code: value.destination_location.code },
            carrier: { code: value.carrier.code },
            currency: { code: value.currency.code },
            price: value.price,
            unit: { code: value.unit.code },
            transit_time: value.transit_time,
            container_type: { code: value.container_type?.code },
        }
        formik.setValues(newValue)
        setIsNew(false)
        modalService.openModalHandling()
    }

    const deleteItem = async () => {
        const code = formik.getFieldProps('code').value
        setDeleteLoading(true)
        await deleteShippingRate(code)
        setDeleteLoading(false)
        resetForm()
        modalService.closeModalHandling()
        getData(fetchParams)
    }

    const handleSearch = (search: string) => {
        dispatch(
            setFetchParam({
                page: 1,
                search: search,
            }),
        )
    }

    useEffect(() => {
        getData(fetchParams)
    }, [fetchParams])

    useEffect(() => {
        dropdownService.getTransportModes()
        dropdownService.getUnlocos()
        dropdownService.getCurrency()
        dropdownService.getWeightUnit()
        dropdownService.getOrganisationCarrier()
        dropdownService.getContainerType()
        dropdownService.getCustomsUnit()
    }, [])

    useEffect(() => {
        if (transport_mode === '') return
        dropdownService.getContainerModes(transport_mode)
    }, [transport_mode])

    return {
        dropdownService,
        modalService,
        isLoading,
        data,
        formik,
        isNew,
        submitLoading,
        deleteLoading,
        meta,
        fetchParams,
        getPayloadUnitValue,
        deleteItem,
        setIsNew,
        resetForm,
        submit,
        selectRow,
        handleSearch,
    }
}

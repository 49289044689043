import Button from '@components/button/button.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import FormMeasurementInput from '@components/form-measurement-input/form-measurement-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import useDropdown from 'common/dropdown/dropdown.service'
import { FormikProps, FormikProvider } from 'formik'
import { discountTypes } from './item-list.static'
import { IFOrderItems } from 'form-validation/order-item.validation'
import FileItem from './component/file-item.component'
import {
    capitalizeFirstLetter,
    numberWithCommas,
    removeUnderscores,
} from '@services/common.service'
import moment from 'moment'
import { useEffect } from 'react'

const OrderItemForm = ({
    formik,
    submitLoading,
    deleteLoading,
    isNew,
    deleteHandling,
    data,
}: {
    formik: FormikProps<IFOrderItems>
    submitLoading: boolean
    deleteLoading: boolean
    isNew: boolean
    deleteHandling: () => void
    data: any
}) => {
    const dropdownService = useDropdown()
    const orderId = formik.values.order_id ?? '-'
    const orderStatus = capitalizeFirstLetter(
        removeUnderscores(formik.values.order_status || '-'),
    )
    const paymentStatus = capitalizeFirstLetter(
        removeUnderscores(formik.values.order_status || '-'),
    )
    const supplier = formik.values.supplier ?? '-'
    const orderName = formik.values.name_order ?? '-'
    const totalAmount = `${formik.values?.currency?.short_code ?? ''} ${numberWithCommas(formik.values?.total_amount_order?.toString() ?? '-')}`
    const globalDiscount =
        typeof formik.values?.discount?.qty === 'number'
            ? `${formik.values.discount.qty * 100}%`
            : '-'
    const orderDate = formik.values.date_order
        ? moment(formik.values.date_order).format('YYYY/MM/DD HH:mm')
        : '-'
    const notes = formik.values.note_order ?? '-'

    useEffect(() => {
        const { price, total_qty, discount } = formik.values

        if (price && total_qty) {
            let calculatedTotalAmount = 0
            // Handle discount based on percentage
            if (discount?.unit === 'PERCENTAGE') {
                const discountRate = discount?.qty
                    ? Number(discount.qty) / 100
                    : 0
                const calculatedDiscount = price * total_qty * discountRate
                calculatedTotalAmount = price * total_qty - calculatedDiscount
            }
            // Handle discount based on fixed amount
            else if (discount?.unit === 'AMOUNT') {
                const fixedDiscount = discount?.qty ? Number(discount.qty) : 0
                calculatedTotalAmount = price * total_qty - fixedDiscount
            }
            formik.setFieldValue('total_amount', calculatedTotalAmount)
        }
    }, [formik.values.price, formik.values.total_qty, formik.values.discount])

    return (
        <div className="flex flex-col h-full">
            <div className={`flex-col overflow-auto h-[calc(100vh-240px)]`}>
                <FormikProvider value={formik}>
                    <div className="flex flex-row gap-2  bg-gray-100 min-w-max p-4">
                        <div className="flex flex-col gap-2 text-size-XS w-full">
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28">Order ID</p>
                                <p>{orderId}</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28">
                                    Order Status
                                </p>
                                <p>{orderStatus}</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28 bg-[#F0F1F3]">
                                    Payment Status
                                </p>
                                <p>{paymentStatus}</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28">Supplier</p>
                                <p>{supplier}</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28">Order Name</p>
                                <p>{orderName}</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28">
                                    Total Amount
                                </p>
                                <p>{totalAmount}</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28">
                                    Global Discount
                                </p>
                                <p>{globalDiscount}</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28">Order Date</p>
                                <p>{orderDate}</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <p className="font-semibold w-28">Notes</p>
                                <p>{notes}</p>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 bg-white w-[90%] rounded-lg px-4 max-h-[230px] overflow-y-auto">
                            <FileItem />
                            <FileItem />
                            <FileItem />
                            <FileItem />
                            <FileItem />
                            <FileItem />
                        </div>
                    </div>
                    <div className="p-4">
                        <div>
                            <div className="text-size-M font-bold">
                                Item Information
                            </div>
                            <div className="text-size-S themes-text-gray-v4 mb-3">
                                Add general information about the items for this
                                order.
                            </div>
                        </div>
                        <div className="flex gap-2">
                            <FormInput
                                parentDivClassName="w-full"
                                name="item_id"
                                label="Item No"
                                useUppercaseLabel={true}
                                placeholder="(Auto Generate)"
                                disabled={true}
                            />
                            <button
                                onClick={() =>
                                    formik.setFieldValue(
                                        'priority',
                                        !formik.values.priority,
                                    )
                                }
                                className={`flex flex-row w-full gap-2`}
                            >
                                <i
                                    className={`text-[1rem] ${
                                        formik.values.priority
                                            ? 'ri-star-fill text-[#FFBA1B]'
                                            : 'ri-star-line text-[#8D99A5]'
                                    }`}
                                ></i>

                                <span className="text-size-M">
                                    Set as Priority
                                </span>
                            </button>
                        </div>
                        <div className="flex gap-2">
                            <FormInput
                                parentDivClassName="w-full"
                                name="name"
                                label="Order Name"
                                useUppercaseLabel={true}
                            />
                            <FormDropdown
                                parentDivClassname="w-full"
                                name="unit"
                                options={dropdownService.quantityUnitData}
                                parentDivClassName="w-full"
                                useUppercaseLabel={true}
                                label="Unit"
                            />
                        </div>
                        <div className="flex gap-2">
                            <FormInput
                                parentDivClassName="w-full"
                                name="total_qty"
                                label="Total QTY"
                                type="number"
                                useUppercaseLabel={true}
                            />
                            <FormInput
                                parentDivClassName="w-full"
                                name="ready_qty"
                                label="Ready QTY"
                                type="number"
                                useUppercaseLabel={true}
                            />
                        </div>
                        <div className="flex gap-2">
                            <FormInput
                                parentDivClassName="w-full"
                                name="price"
                                label="Price Per Unit"
                                type="number"
                                useUppercaseLabel={true}
                            />
                            <FormMeasurementInput
                                name={'discount'}
                                label="Discount (optional)"
                                useUppercaseLabel={true}
                                options={discountTypes}
                                parentDivClassName="mb-4 w-full"
                            />
                        </div>
                        <FormInput
                            parentDivClassName="w-full"
                            name="total_amount"
                            label="Total Amount"
                            type="number"
                            useUppercaseLabel={true}
                            disabled
                        />
                        <FormDropdown
                            parentDivClassname="w-full"
                            name="item_material"
                            options={dropdownService.hsCodesData} // TODO no get data
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="Item Material (Optional)"
                            isMultiSelect={true}
                            placeholder="Select Item Material"
                            isClearable={true}
                        />
                        <FormDropdown
                            parentDivClassname="w-full"
                            name="product_category"
                            options={dropdownService.productCategoriesData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="Product Category"
                        />
                        <FormDropdown
                            parentDivClassname="w-full"
                            name="hs_code"
                            options={dropdownService.hsCodesData}
                            parentDivClassName="w-full"
                            useUppercaseLabel={true}
                            label="HS Code (Optional)"
                        />
                        <div className="flex gap-2">
                            <FormMeasurementInput
                                name={'weight'}
                                label="Weight (optional)"
                                useUppercaseLabel={true}
                                options={dropdownService.weightUnitsData}
                                parentDivClassName="mb-4 w-full"
                            />
                            <FormMeasurementInput
                                name={'volume'}
                                label="Volumetric Weight (optional)"
                                useUppercaseLabel={true}
                                options={dropdownService.volumeUnitsData}
                                parentDivClassName="mb-4 w-full"
                            />
                        </div>
                        <FormInput
                            parentDivClassName="w-full"
                            name="supplier_item_number"
                            label="Supplier Item Code (Optional)"
                            useUppercaseLabel={true}
                        />
                        <FormInput
                            parentDivClassName="w-full"
                            name="customer_item_number"
                            label="Customer Item Code (Optional)"
                            useUppercaseLabel={true}
                        />
                        <FormTextarea
                            name={'note'}
                            label="Note (Optional)"
                            className="mb-3"
                            useUppercaseLabel={true}
                        />
                    </div>
                </FormikProvider>
            </div>
            <div className="p-4 flex justify-between border-t">
                <Button
                    label="DELETE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-delete-bin-line"
                    variant="red-inverse"
                    onClick={() => {
                        deleteHandling()
                    }}
                    isLoading={deleteLoading}
                    isDisabled={isNew}
                />
                <Button
                    label="SAVE"
                    className="!text-size-M min-w-[140px]"
                    icon="ri-save-line"
                    variant="brand"
                    onClick={() => {
                        formik.submitForm()
                    }}
                    isLoading={submitLoading}
                />
            </div>
        </div>
    )
}

export default OrderItemForm

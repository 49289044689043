import { FormikProvider } from 'formik'
import useDetailsService from './details.service'
import OrganisationDetailsForm from './details-form.component'

const Details = () => {
    const { isEdit, formikOrgDetails } = useDetailsService()

    return (
        <FormikProvider value={formikOrgDetails}>
            <OrganisationDetailsForm
                isEdit={isEdit}
                formik={formikOrgDetails}
            />
        </FormikProvider>
    )
}

export default Details

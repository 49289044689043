import Button from '@components/button/button.component'
import LabelData from '@components/label-data/label-data.component'
import Spinner from '@components/spinner/spinner.component'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { IShipment } from 'repository/interface/shipment.interface'

const DetailShipmentLeft = ({
    isDetailLoading,
    shipmentDetailData,
}: {
    isDetailLoading: boolean
    shipmentDetailData: IShipment | undefined
}) => {
    const nav = useNavigate()
    const headerClass = 'themes-text-gray-v4 font-normal text-size-XS'
    const dataClass = 'text-size-S'

    return (
        <div className="sm:hidden rounded-[10px] w-[250px] p-4 pb-2 sm:w-full themes-bg-white content-full-height flex flex-col gap-2">
            {isDetailLoading && (
                <div className="w-full h-full flex items-center justify-center">
                    <Spinner />
                </div>
            )}

            {/* Content */}
            <div
                className={`${isDetailLoading ? 'hidden' : ''}  w-[250px] flex gap-2 flex-col overflow-auto`}
            >
                <div className="font-bold text-size-S">Booking Information</div>
                <LabelData
                    dataClass={`${dataClass}`}
                    headerClass={`${headerClass}`}
                    label="Booking Status"
                    data={shipmentDetailData?.booking?.status ?? '-'}
                />

                <LabelData
                    dataClass={`${dataClass}`}
                    headerClass={`${headerClass}`}
                    label="Booking ID"
                    data={shipmentDetailData?.booking?.booking_id ?? '-'}
                />
                <div className="flex gap-2 w-full">
                    <LabelData
                        containerClass="w-full"
                        dataClass={`${dataClass}`}
                        headerClass={`${headerClass}`}
                        label="Created By"
                        data={shipmentDetailData?.created_by?.name ?? ''}
                    />
                    <LabelData
                        containerClass="w-full"
                        dataClass={`${dataClass}`}
                        headerClass={`${headerClass}`}
                        label="Creation Date"
                        data={
                            moment(shipmentDetailData?.created ?? null).format(
                                'DD/MM/YYYY',
                            ) ?? ''
                        }
                    />
                </div>
                <div className="border-b my-2"></div>
                <div className="font-bold text-size-S">
                    Shipment Information
                </div>
                <LabelData
                    dataClass={`${dataClass}`}
                    headerClass={`${headerClass}`}
                    label="Shipment ID"
                    data={shipmentDetailData?.shipment_id ?? ''}
                />
                <LabelData
                    dataClass={`${dataClass}`}
                    headerClass={`${headerClass}`}
                    label="Consignee"
                    data={shipmentDetailData?.consignee?.name ?? ''}
                />

                <div className="flex gap-2 w-full">
                    <LabelData
                        containerClass="w-full"
                        dataClass={`${dataClass}`}
                        headerClass={`${headerClass}`}
                        label="Transport Mode"
                        data={shipmentDetailData?.transport_mode ?? ''}
                    />
                    <LabelData
                        containerClass="w-full"
                        dataClass={`${dataClass}`}
                        headerClass={`${headerClass}`}
                        label="Container Type"
                        data={shipmentDetailData?.container_mode ?? ''}
                    />
                </div>
                <div className="flex gap-2 w-full">
                    <LabelData
                        containerClass="w-full"
                        dataClass={`${dataClass}`}
                        headerClass={`${headerClass}`}
                        label="ETD"
                        data={
                            moment(shipmentDetailData?.etd ?? null).format(
                                'DD/MM/YYYY',
                            ) ?? ''
                        }
                    />
                    <LabelData
                        containerClass="w-full"
                        dataClass={`${dataClass}`}
                        headerClass={`${headerClass}`}
                        label="ETA"
                        data={
                            moment(shipmentDetailData?.eta ?? null).format(
                                'DD/MM/YYYY',
                            ) ?? ''
                        }
                    />
                </div>
                <LabelData
                    dataClass={`${dataClass}`}
                    headerClass={`${headerClass}`}
                    label="Consolidation ID "
                    data={shipmentDetailData?.consolidation?.console_id}
                />

                <div className="border-b my-2"></div>
                <div className="font-bold text-size-S">
                    Origin & Destination
                </div>
                <div className="flex gap- w-full">
                    <div className="flex flex-col w-full">
                        <div className={headerClass}>Origin</div>
                        <div className={dataClass}>
                            {shipmentDetailData?.origin_location?.name}
                        </div>
                        <div className={'text-size-XS themes-text-gray-v4'}>
                            {shipmentDetailData?.origin_location?.country}
                        </div>
                    </div>
                    <div className="flex flex-col w-full">
                        <div className={headerClass}>Destination</div>
                        <div className={dataClass}>
                            {shipmentDetailData?.destination_location?.name}
                        </div>
                        <div className={'text-size-XS themes-text-gray-v4'}>
                            {shipmentDetailData?.destination_location?.country}
                        </div>
                    </div>
                </div>

                <div className="border-b my-2"></div>
                <div className="font-bold text-size-S">Pack List Info</div>
                <LabelData
                    dataClass={`${dataClass}`}
                    headerClass={`${headerClass}`}
                    label="Unit Qty"
                    data={'-'}
                />
                <div className="flex gap-2 w-full">
                    <LabelData
                        containerClass="w-full"
                        dataClass={`${dataClass}`}
                        headerClass={`${headerClass}`}
                        label="Weight"
                        data={`
                    ${shipmentDetailData?.weight?.toString() ?? '0'}
                    ${shipmentDetailData?.weight_unit?.label?.toString() ?? '0'}
                `}
                    />
                    <LabelData
                        containerClass="w-full"
                        dataClass={`${dataClass}`}
                        headerClass={`${headerClass}`}
                        label="Volume"
                        data={`
                    ${shipmentDetailData?.volume?.toString() ?? '0'}
                    ${shipmentDetailData?.volume_unit?.label?.toString() ?? '0'}
                `}
                    />
                </div>

                {/* <div className="border-b my-2"></div>
        <div className="text-size-XS cursor-pointer mb-2">
            VIEW UPDATE HISTORY
        </div> */}
            </div>
            <div className="w-full">
                <div className="border-t -ml-4 -mr-4 pb-2"></div>
                <Button
                    className="w-full"
                    label="BACK"
                    onClick={() => {
                        nav(-1)
                    }}
                />
            </div>
        </div>
    )
}

export default DetailShipmentLeft

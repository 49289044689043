import useDetailShipment from './detail-shipment.service'
import DetailShipmentLeftSummary from './detail-shipment-left.component'
import DetailShipmentRight from './detail-shipment-right.component'
import './detail-shipment.style.css'

const DetailShipment = () => {
    const {
        setSelectedTabItems,
        getContent,
        resetForm,
        changeShipmentStatus,
        formik,
        selectedTabItems,
        isDetailLoading,
        shipmentDetailData,
        isSubmitLoading,
        shipmentStatus,
    } = useDetailShipment()

    return (
        <div className=" flex gap-4 sm:flex-wrap w-full sm:overflow-auto">
            {/* Left Content */}
            <DetailShipmentLeftSummary
                isDetailLoading={isDetailLoading}
                shipmentDetailData={shipmentDetailData}
            />

            {/* Right Content */}
            <DetailShipmentRight
                isDetailLoading={isDetailLoading}
                shipmentDetailData={shipmentDetailData}
                selectedTabItems={selectedTabItems}
                setSelectedTabItems={(val) => setSelectedTabItems(val)}
                content={getContent()}
                isSubmitLoading={isSubmitLoading}
                submitForm={() => formik.submitForm()}
                resetForm={() => resetForm()}
                changeShipmentStatus={(value: string) => {
                    changeShipmentStatus(value)
                }}
                shipmentStatus={shipmentStatus}
            />
        </div>
    )
}

export default DetailShipment

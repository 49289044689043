import { IFetchParams, IResponseData } from 'common/common.interface'
import {
    deleteRequest,
    errorAxiosToast,
    fetch,
    post,
    put,
} from 'common/common.service'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import { IContact } from './interface/contact.interface'
import {
    IFormContactPayload,
    IUpdateStatusPayload,
} from 'form-validation/form-contact.validation'
import { Toast } from '@components/toast/toast.component'

export const getContacts = async (
    params: IFetchParams,
    showErrorToast?: boolean,
) => {
    try {
        const endpoint = `${endpoints.contacts}`
        const response = await fetch<IResponseData<IContact[]>, IFetchParams>({
            endpoint: endpoint,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Contacts Data',
            })
        }
        return undefined
    }
}

export const getContactDetail = async (code: string) => {
    try {
        const endpoint = `${endpoints.contacts}/${code}`
        const response = await fetch<IResponseData<IContact>, IFetchParams>({
            endpoint: endpoint,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Fetch',
            message: 'Failed Fetch Contact Detail',
        })
        return undefined
    }
}

export const submitContact = async (payload: IFormContactPayload) => {
    try {
        const endpoint = `${endpoints.contacts}`
        await post<IResponseData<any>, IFormContactPayload>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Submit Success',
            message: 'New Contact is Created',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Submit',
            message: 'Failed Submit Contact Data',
        })
        return undefined
    }
}

export const updateContact = async (
    contactCode: string,
    payload: IFormContactPayload,
) => {
    try {
        const endpoint = `${endpoints.contacts}/${contactCode}`
        await put<IResponseData<any>, IFormContactPayload>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Update Success',
            message: 'Contact is updated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Update',
            message: 'Failed Update Contact Data',
        })
        return undefined
    }
}

export const deleteContact = async (contactCode: string) => {
    try {
        const endpoint = `${endpoints.contacts}/${contactCode}`
        await deleteRequest<IResponseData<any>, string>({
            endpoint: endpoint,
        })

        Toast({
            header: 'Delete Success',
            message: 'Contact has been deleted',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Delete',
            message: 'Failed to delete contact',
        })
        return undefined
    }
}

export const updateStatus = async (
    contactCode: string,
    payload: IUpdateStatusPayload,
) => {
    try {
        const endpoint = `${endpoints.contacts}/${contactCode}`
        await put<IResponseData<any>, IFormContactPayload>({
            endpoint: endpoint,
            payload,
        })

        Toast({
            header: 'Update Status Success',
            message: 'Contact status is updated',
            type: 'success',
        })
        return true
    } catch (error: unknown) {
        console.error(error)
        errorAxiosToast(error, {
            header: 'Failed Update',
            message: 'Failed Update Status Data',
        })
        return undefined
    }
}

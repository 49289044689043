import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFetchParams } from 'common/common.interface'
import {
    // IUser,
    IUserActivities,
    IUserDetail,
} from 'repository/interface/user.interface'
import { RootState } from 'store'
import { formatDate } from '@services/common.service'
import { emptyUserPayload } from '../user-access-form/user-access-form.static'

interface IUserAccessDetail {
    data: IUserDetail
    activityLogs: IUserActivities[]
    isLoading: boolean
}

export const initialState: IUserAccessDetail = {
    data: emptyUserPayload,
    activityLogs: [],
    isLoading: false,
}

const userAccessDetailSlice = createSlice({
    name: 'userAccessDetail',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoading: data }
        },
        setFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, fetchParams: data }
        },
        setExportFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, setExportFetchParam: data }
        },
        setUserData(state, action: PayloadAction<IUserDetail>) {
            const data = action.payload
            return { ...state, data: data }
        },
        setActivityLogs(state, action: PayloadAction<IUserActivities[]>) {
            const data = action.payload
            const activityLogs = data?.map((item) => ({
                ...item,
                time: item.time ? formatDate(item.time) : '',
            }))
            return {
                ...state,
                activityLogs,
            }
        },
        setSelectedTabItemHelper(state, action: PayloadAction<string>) {
            const data = action.payload
            return {
                ...state,
                tabItemSelectedHelper: data,
            }
        },
    },
})

// these all the variables used for selector
export const userDetailDataSelector = (state: RootState) =>
    state.userDetail.data || {}
export const activityLogsSelector = (state: RootState) =>
    state.userDetail.activityLogs || []
export const usersDetailIsLoading = (state: RootState) =>
    state.userDetail.isLoading

// all actions
export const {
    setUserData,
    setFetchParam,
    setSelectedTabItemHelper,
    setActivityLogs,
} = userAccessDetailSlice.actions

// Reducer
export default userAccessDetailSlice.reducer

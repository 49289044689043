import * as Yup from 'yup'
import { requiredMessage } from './validation-message.static'

// Define the interface
export interface IFShippingRate {
    code: string
    name: string
    transport_mode: string
    container_mode: string
    origin_location: { code: string }
    destination_location: { code: string }
    carrier: { code: string }
    currency: { code: string }
    price: number
    unit: { code: string }
    transit_time: number
    container_type: { code: string }
}

// Validation schema
export const useShippingRatesValidation = () => {
    const schema = Yup.object<IFShippingRate>().shape({
        name: Yup.string().required(requiredMessage('Name')),
        transport_mode: Yup.string().required(
            requiredMessage('Transport Mode'),
        ),
        container_mode: Yup.string().required(
            requiredMessage('Container Mode'),
        ),
        container_type: Yup.object().shape({
            code: Yup.string(),
        }),
        origin_location: Yup.object().shape({
            code: Yup.string().required(
                requiredMessage('Origin Location Code'),
            ),
        }),
        destination_location: Yup.object().shape({
            code: Yup.string().required(
                requiredMessage('Destination Location Code'),
            ),
        }),
        carrier: Yup.object().shape({
            code: Yup.string().required(requiredMessage('Carrier Code')),
        }),
        currency: Yup.object().shape({
            code: Yup.string().required(requiredMessage('Currency Code')),
        }),
        price: Yup.number()
            .required(requiredMessage('Price'))
            .min(0, 'Price must be positive'),
        unit: Yup.object().shape({
            code: Yup.string().required(requiredMessage('Unit Code')),
        }),
        transit_time: Yup.number(),
    })

    // Initial values
    const initialValue: IFShippingRate = {
        name: '',
        transport_mode: '',
        container_mode: '',
        origin_location: { code: '' },
        destination_location: { code: '' },
        carrier: { code: '' },
        currency: { code: '' },
        price: 0,
        unit: { code: '' },
        transit_time: 0,
        code: '',
        container_type: {
            code: '',
        },
    }

    return {
        initialValue,
        schema,
    }
}

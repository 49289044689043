var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './pagination.style.css';
import Button from '@components/button/button.component';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
function Pagination(_a) {
    var _b, _c;
    var meta = _a.meta, meta2 = _a.meta2, meta3 = _a.meta3, props = __rest(_a, ["meta", "meta2", "meta3"]);
    var t = useTranslation().t;
    var _d = useState({
        disabled: true,
        indexEnd: 0,
        indexStart: 0,
        nextHandling: 0,
        previousHandling: 0,
        totalItems: 0,
    }), paginationState = _d[0], setPaginationState = _d[1];
    var classValueNumber = ((_b = props.valueClass) === null || _b === void 0 ? void 0 : _b.valueNumber) || '';
    var classValueString = ((_c = props.valueClass) === null || _c === void 0 ? void 0 : _c.valueString) || '';
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        // META HANDLING
        // There are 3 types of meta, for standalone, cedea, and other projects
        if (meta3) {
            var currentPage = (_a = meta3.page) !== null && _a !== void 0 ? _a : 1;
            var totalData = (_b = meta3.total_data) !== null && _b !== void 0 ? _b : 0;
            var isLast = (_c = meta3.is_last) !== null && _c !== void 0 ? _c : true;
            setPaginationState({
                indexStart: totalData > 0 ? meta3.start : 0,
                indexEnd: (_d = meta3.end) !== null && _d !== void 0 ? _d : 0,
                totalItems: totalData,
                disabled: isLast,
                nextHandling: currentPage + 1,
                previousHandling: currentPage - 1,
            });
        }
        else if (meta2) {
            setPaginationState({
                disabled: true,
                indexEnd: meta2.data_count,
                indexStart: meta2.total_data_count < 1 ? 0 : meta2.start,
                nextHandling: meta2.page + 1,
                previousHandling: meta2.page - 1,
                totalItems: meta2.total_data_count,
            });
        }
        else if (meta) {
            var totalItems = meta.total_items || meta.total_Items;
            setPaginationState({
                indexStart: totalItems
                    ? totalItems === 0
                        ? 0
                        : meta === null || meta === void 0 ? void 0 : meta.index_start
                    : 0,
                indexEnd: (_e = meta.index_end) !== null && _e !== void 0 ? _e : 0,
                totalItems: totalItems !== null && totalItems !== void 0 ? totalItems : 0,
                disabled: meta.current_page === meta.last_page,
                nextHandling: meta.current_page + 1,
                previousHandling: meta.current_page - 1,
            });
        }
    }, [meta, meta2, meta3]);
    return (_jsx("div", __assign({ className: "pagination-container flex-none ".concat(props.containerClassName) }, { children: _jsx("div", __assign({ className: "flex flex-row" }, { children: _jsxs("ul", __assign({ className: "pagination ".concat(props.className) }, { children: [_jsx(Button, { icon: "ri-arrow-left-s-line", variant: "default-pagination", type: "button", isDisabled: paginationState.indexStart <= 1, className: "w-btnIconWidth !border-0 ".concat(props.btnClassName), onClick: function () {
                            return props.nextHandling &&
                                props.nextHandling(paginationState.previousHandling);
                        } }), _jsxs("div", __assign({ className: "flex border-l border-r px-2 h-[80%] items-center  min-w-fit" }, { children: [_jsx("li", __assign({ className: "page-of font-[1000] ".concat(classValueNumber) }, { children: "".concat(paginationState.indexStart, " - ").concat(paginationState.indexEnd, " ") })), _jsx("li", __assign({ className: "page-of font-[10] ".concat(classValueString) }, { children: t('additional.of') })), _jsx("li", __assign({ className: "page-of font-[1000] ".concat(classValueNumber, " ") }, { children: "".concat(paginationState.totalItems) })), _jsx("li", __assign({ className: "page-of font-[10] ".concat(classValueString) }, { children: t('additional.list') }))] })), _jsx(Button, { icon: "ri-arrow-right-s-line", variant: "default-pagination", type: "button", isDisabled: paginationState.disabled, className: "w-btnIconWidth !border-0 ".concat(props.btnClassName), onClick: function () {
                            return props.nextHandling &&
                                props.nextHandling(paginationState.nextHandling);
                        } })] })) })) })));
}
export default Pagination;

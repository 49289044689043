import { IFetchParams } from 'common/common.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import { useFormik } from 'formik'
import { useEffect } from 'react'

// Hold - Still waiting API filter quotes
interface IUserFilter
    extends Pick<
        IFetchParams,
        | 'consignee'
        | 'origin'
        | 'destination'
        | 'carrier'
        | 'container_mode'
        | 'container_type'
        | 'warehouse'
        | 'created_by'
        | 'fileExportType'
        | 'etd_end'
        | 'etd_start'
    > {}

export const initialUserFilter: IUserFilter = {
    fileExportType: 'xls',
    consignee: [],
    origin: [],
    destination: [],
    carrier: [],
    container_mode: [],
    container_type: [],
    warehouse: [],
    created_by: [],
}

const useUsersFilter = (
    onFilterSubmit: (data: IFetchParams) => void,
    isGenerateReport: boolean = false,
) => {
    const dropdown = useDropdown()
    const formik = useFormik<IUserFilter>({
        initialValues: initialUserFilter,
        onSubmit: (values) => {
            generateFilter(values)
        },
    })

    useEffect(() => {
        dropdown.getJobCategories()
        dropdown.getCities()
        dropdown.getCountries()
        dropdown.getOrganisationTypes()
    }, [])

    const generateFilter = async (values: IUserFilter) => {
        let params: IFetchParams = { ...values, size: 50, page: 1 }
        if (isGenerateReport) params = { ...values, size: 2000, page: 1 }
        onFilterSubmit(params)
    }

    return { formik, dropdown }
}

export default useUsersFilter

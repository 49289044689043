import Modal from '@components/modal/modal.component'
import { IUseModal } from '@components/modal/modal.service'
import { Toast } from '@components/toast/toast.component'

const ContactFormModal = ({
    modalService,
    isCreate,
    formContacts,
}: {
    modalService: IUseModal
    isCreate: boolean
    formContacts: JSX.Element
}) => {
    return (
        <Modal
            isModalOpen={modalService.isModalOpen}
            className="md:w-1/3 mobile:min-w-0 min-w-[500px] !p-0 md:absolute  sticky !m-4 top-[70px]"
        >
            <div>
                <div className="flex flex-1 flex-col">
                    {/* Header */}
                    <div className="flex font-bold text-size-L justify-between py-2.5 px-4 items-center border-b">
                        {isCreate ? 'CREATE CONTACT' : 'CONTACT DETAILS'}
                        <div className="flex flex-row gap-2 p-2  border-gray-40">
                            <div className="flex justify-center items-center pl-4">
                                <i
                                    className="ri-close-fill cursor-pointer "
                                    onClick={() => {
                                        modalService.closeModalHandling()
                                    }}
                                ></i>
                            </div>
                        </div>
                    </div>
                    {formContacts}
                </div>
            </div>
        </Modal>
    )
}
export default ContactFormModal

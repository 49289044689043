import * as Yup from 'yup'
import { requiredMessage } from './validation-message.static'
import { IUserAuth } from 'repository/interface/user-auth.interface'
import { userDataSelector } from 'pages/login/login.slice'
import { useSelector } from 'react-redux'
import moment from 'moment'

export interface IFormNotes {
    category: string
    text: string
    created_by?: string
    source?: string
    last_updated?: string
    creation_date?: string
    code?: string
}
export interface IFormNotesPayload {
    category: string
    text: string
}

export const useNotesValidation = () => {
    const user: IUserAuth = useSelector(userDataSelector)
    const schema = Yup.object<IFormNotes>().shape({
        category: Yup.string().required(requiredMessage('Container Number')),
        text: Yup.string().required(requiredMessage('Container Type')),
    })

    const generateInitialValue = () => {
        const initialValue: IFormNotes = {
            category: '',
            text: '',
            source: 'SHIPMENT',
            created_by: user.fullName,
            creation_date: moment().format('DD/MM/YYYY'),
            last_updated: '-',
            code: '',
        }

        return initialValue
    }

    return {
        generateInitialValue,
        schema,
    }
}

import * as Yup from 'yup'
import { requiredMessage } from './validation-message.static'

export interface IFormEdocs {
    file: string
    description: string
    document_type: string
    isFileChanged: boolean
    code?: string
    fileName?: string
    fileUrl?: string
}
export interface IFormEdocsPayload {
    file: string
    description: string
    document_type: string
}

export const useEdocsValidation = () => {
    const schema = Yup.object<IFormEdocs>().shape({
        file: Yup.mixed().required(requiredMessage('File')),
        description: Yup.string().required(requiredMessage('Description')),
        document_type: Yup.string().required(requiredMessage('Document Type')),
    })

    const initialValue: IFormEdocs = {
        file: '',
        description: '',
        document_type: '',
        isFileChanged: false,
        code: '',
    }

    return {
        initialValue,
        schema,
    }
}

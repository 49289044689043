import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { formatDate } from 'date-fns'

export const headerParent: ITableColumn[] = [
    { accessor: 'name', label: 'Name', width: 10 },
    {
        accessor: 'updated',
        label: 'Updated At',
        customBuild: (data) => {
            return formatDate(data, 'dd/MM/yyyy HH:mm')
        },
    },
]

export const headerChild: ITableColumn[] = [
    { accessor: 'charge_code', label: 'Charge Code', width: 10 },
    { accessor: 'name', label: 'Name', width: 10 },
    { accessor: 'price', label: 'Price', width: 10 },
    { accessor: 'unit.label', label: 'Unit' },
]

export const tabItem: ITabItem[] = [
    { label: 'All Service Rate', value: 'all', key: 'status' },
]

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorMessage, useField } from 'formik';
import Checkbox from '@components/checkbox/checkbox.component';
var FormCheckbox = function (_a) {
    var _b = _a.readonly, readonly = _b === void 0 ? false : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.parentDivClassName, parentDivClassName = _d === void 0 ? '' : _d, _e = _a.hideError, hideError = _e === void 0 ? false : _e, _f = _a.label, label = _f === void 0 ? '' : _f, props = __rest(_a, ["readonly", "disabled", "parentDivClassName", "hideError", "label"]);
    var _g = useField(props.name), field = _g[0], meta = _g[1], helpers = _g[2];
    var isError = meta.touched && meta.error;
    var errorClassMessage = isError && !hideError ? '' : 'hidden';
    var handleChecked = function (checked) {
        helpers.setValue(checked); // Set Formik's value directly
        props.onChange && props.onChange(checked); // Call any additional onChange if provided
    };
    return (_jsxs("div", __assign({ className: "".concat(parentDivClassName, " mb-3") }, { children: [_jsxs("div", __assign({ className: "flex relative items-center gap-2" }, { children: [_jsx(Checkbox, { name: field.name, value: field.value, isChecked: field.value, onChecked: handleChecked, disabled: disabled }), label && _jsx("div", __assign({ className: "font-bold" }, { children: label }))] })), _jsx("div", __assign({ className: "ml-small-x text-size-XS themes-text-red-v3 ".concat(errorClassMessage) }, { children: _jsx(ErrorMessage, { name: props.name, component: "p" }) }))] })));
};
export default FormCheckbox;

import * as Yup from 'yup'
import { requiredMessage } from './validation-message.static'
import { IMeasurement } from '@components/measurement-input/measurement-input.interface'

// Define the interface

interface IPUnit {
    code: string
}

export interface IFItemList {
    code?: string
    order_code?: string
    order_id?: string
    order_status?: string
    supplier?: string
    name_order?: string
    discount_amount_order?: IMeasurement
    discount_order?: IMeasurement
    total_amount_order?: number
    date_order?: string
    note_order?: string
    currency?: {
        code?: string
        name?: string
        symbol?: string
        short_code?: string
    }
}

export type IFOrderItems = IFItemList & {
    item_id?: string
    priority: boolean
    name: string
    unit: string
    total_qty: number
    ready_qty: number
    price: number
    item_material?: string
    discount?: IMeasurement
    total_amount: number
    product_category?: string
    hs_code?: string
    weight: IMeasurement
    volume: IMeasurement
    supplier_item_number?: string
    customer_item_number?: string
    note?: string
}

export interface IFOrderItemsPayload {
    item_id?: string
    name: string
    priority: boolean
    total_qty: number
    ready_qty: number
    unit: string
    price: number
    total_amount: number
    discount?: number
    discount_amount?: number
    weight: number
    weight_unit: IPUnit
    volume: number
    volume_unit: IPUnit
    product_category?: IPUnit
    item_material?: IPUnit
    hs_code?: string
    supplier_item_number?: string
    customer_item_number?: string
    note?: string
}

// Validation schema
export const useOrderItemsValidation = () => {
    const schema = Yup.object<IFOrderItems>().shape({
        item_id: Yup.string().optional(),
        priority: Yup.boolean().required(requiredMessage('Priority')),
        name: Yup.string().required(requiredMessage('Name')),
        unit: Yup.string().required(requiredMessage('Unit')),
        total_qty: Yup.number()
            .min(0, 'Total quantity must be at least 0')
            .required(requiredMessage('Total Quantity')),
        ready_qty: Yup.number()
            .min(0, 'Ready quantity must be at least 0')
            .required(requiredMessage('Ready Quantity')),
        price: Yup.number()
            .min(0, 'Price must be at least 0')
            .required(requiredMessage('Price')),
        discount: Yup.object<IMeasurement>().shape({
            qty: Yup.string().nullable(),
            unit: Yup.string().nullable(),
        }),
        discount_amount: Yup.object<IMeasurement>().shape({
            qty: Yup.string().nullable(),
            unit: Yup.string().nullable(),
        }),
        total_amount: Yup.number()
            .min(0, 'Total amount must be at least 0')
            .required(requiredMessage('Total Amount')),
        product_category: Yup.string().required(
            requiredMessage('Product Category'),
        ),
        hs_code: Yup.string().required(requiredMessage('HS Code')).optional(),
        weight: Yup.object<IMeasurement>().shape({
            qty: Yup.string().nullable(),
            unit: Yup.string().nullable(),
        }),
        volume: Yup.object<IMeasurement>().shape({
            qty: Yup.string().nullable(),
            unit: Yup.string().nullable(),
        }),
        supplier_item_number: Yup.string().optional(),
        customer_item_number: Yup.string().optional(),
        note: Yup.string().optional(),
    })

    // Initial values
    const initialValue: IFOrderItems = {
        item_id: '(Auto Generated)',
        priority: false,
        name: '',
        unit: '',
        total_qty: 0,
        ready_qty: 0,
        price: 0,
        discount: undefined,
        total_amount: 0,
        product_category: '',
        hs_code: '',
        weight: { qty: '0' },
        volume: { qty: '0' },
        supplier_item_number: '',
        customer_item_number: '',
        note: '',
    }

    return {
        initialValue,
        schema,
    }
}

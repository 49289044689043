import Modal from '@components/modal/modal.component'
import { IUseModal } from '@components/modal/modal.service'
import { useTranslation } from 'react-i18next'
import { IFetchParams } from 'common/common.interface'
import ShipmentsFilter from './shipments-filter.component'

const ShipmentsExportModal = ({
    modalService,
    onSubmit,
    isLoading = false,
}: {
    modalService: IUseModal
    onSubmit: (value: IFetchParams) => void
    isLoading: boolean
}) => {
    const { t } = useTranslation()
    const component = (
        <div>
            <div className="flex font-bold text-size-M justify-between items-center p-4 ">
                {t('action.generate_report')}
                <div
                    className="cursor-pointer"
                    onClick={() => modalService.closeModalHandling()}
                >
                    <i className="ri-close-fill" />
                </div>
            </div>
            <div className="border-b border-gray-40"></div>
            <div>
                <ShipmentsFilter
                    isGenerateReport={true}
                    onFilterSubmit={(value) => {
                        onSubmit(value)
                    }}
                    isLoading={isLoading}
                />
            </div>
        </div>
    )

    return (
        <Modal
            isModalOpen={modalService.isModalOpen}
            children={component}
            className="mobile:w-[90vw] w-[500px] !p-0"
        />
    )
}

export default ShipmentsExportModal
